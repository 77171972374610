import SiteButton from "../../../components/custom/SiteButton";
import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getOfferDetails, signOut } from "../../../store/main/authSlice";
import { useHistory, useParams } from "react-router-dom";
import Loading from "../../../components/custom/Loading";
import Header from "../../../components/authLayoutComponents/Header";
import Footer from "../../../components/authLayoutComponents/Footer";
import BackLink from "../../../components/custom/BackLink";
import {
  AddOptinLog,
  getOptinLogByUserBrandOffer,
} from "../../../store/main/optinLogSlice";

const BrandOptin = () => {
  const { brandName, offerID } = useParams();
  const [isNewsLetter, setIsNewsLetter] = useState(false);

  const { user, uid, brandDetails, offerDetails, isGettingBrandDetails } =
    useSelector((state) => state.auth);

  const { loading, isOptinLog } = useSelector((state) => state.optinLog);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getOptinLogByUserBrandOffer(uid, brandDetails.id, offerDetails.id)
    );
  }, [dispatch, uid, brandDetails.id, offerDetails.id]);

  // useEffect(() => {
  //   if (
  //     !offerDetails.optinInfo ||
  //     !offerDetails.optinInfo.optinTitle ||
  //     isOptinLog
  //   ) {
  //     history.push(
  //       `/auth/login/congratulations/${brandDetails?.slug}/offerID=${offerDetails?.id}`
  //     );
  //   }
  // }, [isOptinLog, brandDetails?.slug, offerDetails?.id, history, offerDetails]);
  useEffect(() => {
    if (!offerDetails.optinInfo || !offerDetails.optinInfo.optinTitle) {
      history.push(
        `/auth/login/congratulations/${brandDetails?.slug}/offerID=${offerDetails?.id}`
      );
    }
  }, [brandDetails?.slug, offerDetails?.id, history, offerDetails]);

  useEffect(() => {
    if (brandName && offerID) {
      dispatch(
        getOfferDetails(
          brandName,
          offerID,
          history,
          () => {},
          (message) => {
            history.push("/auth/login");
            dispatch(signOut());
          }
        )
      );
    } else {
      dispatch(signOut());
      history.push("/auth/login");
    }
  }, [brandName, offerID, dispatch, history]);

  const handleSubmitBrandOptin = () => {
    // console.log("Hamza text 123", isNewsLetter);
    let payload = {
      userId: uid,
      email: user.primaryEmail,
      firstName: user.firstName,
      lastName: user.lastName,
      brandOptinStatus: isNewsLetter,
      industry: user.industrySector,
      brand: brandDetails.id,
      offerId: offerDetails.id,
    };
    dispatch(
      AddOptinLog(
        payload,
        () => {
          history.push(
            `/auth/login/congratulations/${brandDetails?.slug}/offerID=${offerDetails?.id}`
          );
        },
        (message) => {}
      )
    );
  };

  return (
    <section className="main-app shadow-md rounded-[18px] relative min-w-[327px] brand-optin-screen relative">
      {!isGettingBrandDetails && brandDetails?.backLink && (
        <div
          className="absolute left-0 top-[-28px] lg:top-[-32px]"
          onClick={() => {
            let link = brandDetails?.brandLink;
            console.log("Console", link.includes("{memberid}"));
            if (link.includes("{memberid}")) {
              link = link.replace("{memberid}", "0");
            }

            setTimeout(() => {
              window.location.href = link;
            }, 300);
          }}
        >
          <BackLink title={`Back to ${brandDetails?.brandName}`} />
        </div>
      )}

      <Header />
      <div className="congratulations-page rounded-[18px]">
        {isGettingBrandDetails && <Loading />}
        <div>
          <h2 className="global_header font-[500] text-center mb-4">
            {/* Join the Community */}
            {offerDetails?.optinInfo?.optinTitle}
          </h2>
          <p className="text-center text-[#6E7680] body_text mb-4 font-[500]">
            {/* Key workers get more at {brandDetails?.brandName} */}
            {offerDetails?.optinInfo?.optinBodyText1}
          </p>
          <p className="text-center text-[#6E7680] body_text mb-4 font-[500]">
            {/* For all the latest news and more exclusive key worker offers, sign
            up to recieve {brandDetails?.brandName} emails **Brand Privacy
            Policy** */}
            {offerDetails?.optinInfo?.optinBodyText2}{" "}
            <a
              href={offerDetails?.optinInfo?.privacyPolicyLink}
              target="_blank"
              rel="noreferrer"
              className="text-primary"
            >
              {brandDetails?.brandName} Privacy Policy
            </a>
          </p>

          <ul className="checkbox-list mb-4">
            <li>
              <input
                id="yes"
                checked={isNewsLetter}
                onChange={(e) => setIsNewsLetter(e.target.checked)}
                type="checkbox"
              />
              <label htmlFor="yes" className="opt_text">
                I would like to recieve the latest news and offers by email from{" "}
                {brandDetails?.brandName}
              </label>
            </li>
          </ul>

          <SiteButton
            onClick={handleSubmitBrandOptin}
            pill
            block
            className="!mt-[25px]
 text-white mb-5"
            disabled={offerDetails?.optinInfo?.isOptinRequired && !isNewsLetter}
          >
            {loading ? <Spinner /> : "Submit"}
          </SiteButton>
        </div>

        <Footer />
      </div>
    </section>
  );
};

export default BrandOptin;
