import SiteButton from "../../../components/custom/SiteButton";
import "../../../assets/sass/authPages/congratulations.scss";
import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getGlobalContent } from "../../../store/main/globalContentSlice";
import { getOfferDetails, signOut } from "../../../store/main/authSlice";
import { useHistory, useParams } from "react-router-dom";
import Loading from "../../../components/custom/Loading";
import { getNewCode, getUserDetails } from "../../../store/main/userSlice";
import Header from "../../../components/authLayoutComponents/Header";
import brandImg from "../../../assets/img/defaultBrandLogo.png";

const Congratulations = () => {
  let code = "XXXX-CODE-XXXX";
  const [copied, setCopied] = useState(false);
  const { brandName, offerID } = useParams();

  const { user, uid, brandDetails, offerDetails, isGettingBrandDetails } =
    useSelector((state) => state.auth);
  const { userDetails, isGettingUserDetails, isGeneratingCode } = useSelector(
    (state) => state.user
  );
  const handleCopyCode = () => {
    if (
      offerDetails?.offerType == "code" &&
      userDetails?.codeDetails?.code != "" &&
      offerDetails?.id == userDetails?.codeDetails?.offer
    ) {
      setCopied(true);
      let copyCode = userDetails?.codeDetails?.code
        ? userDetails?.codeDetails?.code
        : code;
      navigator.clipboard.writeText(copyCode);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  };
  const { codeIssueDetails } = useSelector((state) => state.globalContent);
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (Object.keys(codeIssueDetails).length == 0) {
      dispatch(getGlobalContent("codeIssue"));
    }
    if (uid) {
      dispatch(getUserDetails(uid));
    }
  }, []);

  useEffect(() => {
    if (brandName && offerID) {
      dispatch(
        getOfferDetails(
          brandName,
          offerID,
          history,
          () => {},
          (message) => {
            history.push("/auth/login");
            dispatch(signOut());
          }
        )
      );
    } else {
      dispatch(signOut());
      history.push("/auth/login");
    }
  }, [brandName, offerID]);

  console.log("Data", userDetails);

  return (
    <section className="main-app shadow-md rounded-[18px] relative min-w-[327px]">
      <Header />
      <div className="congratulations-page rounded-[18px]">
        {(isGettingUserDetails ||
          isGeneratingCode ||
          isGettingBrandDetails) && <Loading />}
        <div>
          <h2 className="global_header font-[500] text-center mb-3">
            {codeIssueDetails?.congratulationsTitle
              ? codeIssueDetails.congratulationsTitle
              : "Congratulations"}
          </h2>
          <p className="text-center text-[#6E7680] body_text mb-3 font-[500]">
            {codeIssueDetails?.congratulationsText
              ? codeIssueDetails.congratulationsText
              : "You’ve unlocked"}
          </p>
          <p className="text-center body_text text-primary mb-3 font-[700]">
            {offerDetails?.offerTitle
              ? offerDetails?.offerTitle
              : "Up to 20% off for Key Workers"}
          </p>
        </div>

        {offerDetails?.offerType == "deal" ? (
          <p className="text-center body_text text-[#6c757d] mb-3 font-[700]">
            Continue to site to redeem
          </p>
        ) : userDetails.codeDetails?.showCodeType == "qrCode" ? (
          <>
            <p className="text-center text-[#6E7680] body_text mb-2 font-[500]">
              Show this code to the cashier
            </p>
            <div className="animated-border">
              <qr-code
                id="qr1"
                contents={
                  userDetails?.codeDetails?.code
                    ? userDetails?.codeDetails?.code
                    : code
                }
                module-color="#000000"
                position-ring-color="#000000"
                position-center-color="#000000"
                style={{
                  width: 170,
                  height: 170,
                  paddingLeft: "4.5px",
                  paddingTop: "5px",
                  margin: "0.1em auto",
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                }}
              >
                <img
                  src={brandImg}
                  className="w-100 h-100 ml-0 mt-3 mb-4"
                  slot="icon"
                />
              </qr-code>
            </div>
          </>
        ) : (
          <>
            {copied ? (
              <SiteButton
                pill
                block
                className="mt-[22px]  md:mt-[27px] font-[400]  transition-all duration-700"
              >
                COPIED
              </SiteButton>
            ) : (
              <Button
                block
                className="my-btn border-[2px] font-[500] button_text lg:h-[68px] h-[56px] lg:max-w-[405px] max-w-[355px] w-full mt-[22px]  md:mt-[27px]
 "
                onClick={handleCopyCode}
              >
                {userDetails?.codeDetails?.code &&
                offerDetails?.id == userDetails?.codeDetails?.offer
                  ? userDetails.codeDetails.code
                  : code}
              </Button>
            )}
          </>
        )}
        {(userDetails.codeDetails?.showCodeType != "qrCode" ||
          offerDetails?.offerType === "deal" ||
          !userDetails.codeDetails?.showCodeType) && (
          <>
            <SiteButton
              onClick={() => {
                if (offerDetails?.offerType == "code") {
                  // window.location.href = brandDetails?.brandLink;
                  let link = offerDetails?.offerLink;
                  if (link.includes("{memberid}")) {
                    link = link.replace("{memberid}", uid);
                  }

                  console.log("Link before redirection", link);

                  setTimeout(() => {
                    window.open(link, "_blank");
                  }, 300);
                } else {
                  // window.location.href = offerDetails?.dealLink;
                  let link = offerDetails?.dealLink;
                  if (link.includes("{memberid}")) {
                    link = link.replace("{memberid}", uid);
                  }

                  console.log("Before opening deal url", link);

                  setTimeout(() => {
                    // window.location.href = link;
                    window.open(link, "_blank");
                  }, 300);
                }
              }}
              pill
              block
              className="!mt-[22px]
 text-white "
            >
              Continue to{" "}
              {brandDetails?.brandName ? brandDetails.brandName : "Jd sports"}
            </SiteButton>
          </>
        )}

        {errorMessage != "" && (
          <p className="error_text  mt-[22px]  md:mt-[27px]">{errorMessage}</p>
        )}
        {successMessage != "" && (
          <p className="success_text text-center  mt-[22px]  md:mt-[27px]">
            {successMessage}
          </p>
        )}

        {offerDetails?.offerType == "code" &&
        offerDetails?.codeType == "batch" &&
        userDetails?.codeDetails?.id &&
        offerDetails?.id == userDetails?.codeDetails?.offer ? (
          <a
            onClick={(e) => {
              e.preventDefault();
              setErrorMessage("");
              if (brandDetails?.id && uid) {
                dispatch(
                  getNewCode(
                    {
                      brandId: brandDetails?.id,
                      offerId: offerDetails?.id,
                      userId: userDetails?.id,
                    },
                    (message) => {
                      if (message) {
                        setSuccessMessage(message);
                        setTimeout(() => {
                          setSuccessMessage("");
                        }, 3000);
                      }
                    },
                    (message) => {
                      if (message) {
                        setErrorMessage(message);
                        // setTimeout(() => {
                        //   setErrorMessage("");
                        // }, 3000);
                      }
                    }
                  )
                );
              }
            }}
            className="mb-5 mt-[22px]  md:mt-[27px]
 terms_conditions fw--500 underline get-new-code cursor-pointer"
          >
            {codeIssueDetails?.getNewCodeTextlink
              ? codeIssueDetails.getNewCodeTextlink
              : "Get a new code"}
          </a>
        ) : offerDetails?.offerType == "code" &&
          offerDetails?.id != userDetails?.codeDetails?.offer ? (
          <p className="error_text  mt-[22px]  md:mt-[27px]">
            Code Limit Exceeded
          </p>
        ) : (
          <p className=" py-[12px]   max-sm:py-[22px]   md:py-[17px]  lg:py-[12px]"></p>
        )}

        <hr className="hr-row" />
        <p className="mb-4 body_text font-[500]">
          {codeIssueDetails?.accessKiiIDText
            ? codeIssueDetails.accessKiiIDText
            : "Access your kii ID anytime via my account"}
        </p>
        <SiteButton to={"/auth/account"} outline block pill className=" mb-4">
          {codeIssueDetails?.myAccountButtonText
            ? codeIssueDetails.myAccountButtonText
            : "My account"}{" "}
        </SiteButton>
      </div>
    </section>
  );
};

export default Congratulations;
