import axios from "axios";
const baseDomain = "https://us-central1-kii-admin.cloudfunctions.net/app";
// const baseURL = `${baseDomain}/api/v1`;

// let authToken = localStorage.getItem("authToken");
let axiosOptions = {
  // baseURL,
  baseURL: baseDomain,
  // headers: {
  //   ...(authToken && { Authorization: "Bearer " + authToken }),
  // },
};

export default axios.create(axiosOptions);
