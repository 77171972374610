import { useDispatch, useSelector } from "react-redux";

import { useHistory, useLocation, useParams } from "react-router-dom";
import React, { Suspense, useEffect } from "react";
import {
  getOfferDetails,
  onSignUpChange,
  setBrandDetails,
  setOfferDetails,
  signOut,
} from "../store/main/authSlice";
import { getOptinLogByUserBrandOffer } from "../store/main/optinLogSlice";

// Importing components with React.lazy
const Footer = React.lazy(() =>
  import("../components/authLayoutComponents/Footer")
);
const Header = React.lazy(() =>
  import("../components/authLayoutComponents/Header")
);
const Loading = React.lazy(() => import("../components/custom/Loading"));
const BackLink = React.lazy(() => import("../components/custom/BackLink"));

const Auth = (props) => {
  const { loading } = useSelector((state) => state.app);
  const {
    uid,

    brandDetails,
    isGettingBrandDetails,
    offerDetails,
    isLoggedInByBrand,
  } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const history = useHistory();
  const { brandName, offerID } = useParams();
  const { isGettingGlobalContent } = useSelector(
    (state) => state.globalContent
  );
  const { isOptinLog, optinLog } = useSelector((state) => state.optinLog);

  // if (uid) {
  //   if (isLoggedInByBrand) {
  //     if (!offerDetails.optinInfo || !offerDetails.optinInfo.optinTitle) {
  //       history.push(
  //         `/auth/login/congratulations/${brandDetails?.slug}/offerID=${offerDetails?.id}`
  //       );
  //     } else {
  //       if (isOptinLog) {
  //         history.push(
  //           `/auth/login/congratulations/${brandDetails?.slug}/offerID=${offerDetails?.id}`
  //         );
  //       } else {
  //         history.push(
  //           `/auth/login/brand-optin/${brandDetails?.slug}/offerID=${offerDetails?.id}`
  //         );
  //       }
  //     }
  //   } else {
  //     history.push("/auth/account");
  //   }
  // }
  if (uid) {
    if (isLoggedInByBrand) {
      if (!offerDetails.optinInfo || !offerDetails.optinInfo.optinTitle) {
        history.push(
          `/auth/login/congratulations/${brandDetails?.slug}/offerID=${offerDetails?.id}`
        );
      } else {
        history.push(
          `/auth/login/brand-optin/${brandDetails?.slug}/offerID=${offerDetails?.id}`
        );
      }
    } else {
      history.push("/auth/account");
    }
  }

  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;
    window.parent.postMessage({ pathname: pathname }, "*");

    // const initialHeight = 1200;
    // window.parent.postMessage({ height: initialHeight }, "*");

    const timeoutId = setTimeout(() => {
      const height = document.body.scrollHeight;
      window.parent.postMessage({ height: height }, "*");
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [location.pathname]);

  useEffect(() => {
    if (!uid) {
      if (brandName && offerID) {
        dispatch(
          getOfferDetails(
            brandName,
            offerID,
            history,
            () => {
              dispatch(
                onSignUpChange({
                  key: "referrerSource",
                  value: `/${brandName}/offerID=${offerID}`,
                })
              );
            },
            (message) => {
              history.push("/");
              dispatch(signOut());
            }
          )
        );
      } else {
        dispatch(setOfferDetails({}));
        dispatch(setBrandDetails({}));
      }
    }
  }, [brandName, offerID]);

  useEffect(() => {
    const setDocumentTitle = () => {
      if (offerDetails?.id && brandDetails?.id) {
        document.title = `${brandDetails.brandName} | ${offerDetails.offerTitle} at kii`;
      } else {
        document.title = `kii`;
      }
    };

    setDocumentTitle();
  }, [offerDetails?.id, brandDetails?.id]);

  return (
    <section className="main-app shadow-md rounded-[18px] relative min-w-[327px]">
      {/* {loading && <Loading />} */}

      <Suspense fallback={<div></div>}>
        {(isGettingBrandDetails || isGettingGlobalContent) && <Loading />}

        {!isGettingBrandDetails && brandDetails?.backLink && (
          <div
            className="absolute left-0 top-[-28px] lg:top-[-32px]"
            onClick={() => {
              let link = brandDetails?.brandLink;
              console.log("Console", link.includes("{memberid}"));
              if (link.includes("{memberid}")) {
                link = link.replace("{memberid}", "0");
              }

              setTimeout(() => {
                window.location.href = link;
              }, 300);
            }}
          >
            <BackLink title={`Back`} />
          </div>
        )}

        {/* {!isGettingBrandDetails && offerDetails?.offerLink && (
          <div
            className="absolute left-0 top-[-28px] lg:top-[-32px]"
            onClick={() => {
              // window.location.href = offerDetails?.offerLink;
              let link = offerDetails?.offerLink;
              if (link.includes("{memberid}")) {
                link = link.replace("{memberid}", "0");
              }

              setTimeout(() => {
                window.location.href = link;
              }, 300);
            }}
          >
            <BackLink title={`Back to ${brandDetails?.brandName}`} />
          </div>
        )} */}

        <Header />
        {props.children}
        <Footer />
      </Suspense>
    </section>
  );
};

export default Auth;
