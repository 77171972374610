import "../../assets/sass/authPages/selectAuth.scss";

import { useEffect } from "react";

import InvalidOffer from "../../components/authLayoutComponents/InvalidOffer";

const OfferInvalid = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="select-auth">
      <InvalidOffer title="Invalid Offer " />
    </div>
  );
};

export default OfferInvalid;
