import { useCallback, useEffect, useState } from "react";
import SiteInput from "../../../components/custom/SiteInput";
import SiteButton from "../../../components/custom/SiteButton";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getGlobalContent } from "../../../store/main/globalContentSlice";
import {
  signOut,
  userSignIn,
  verifyRecaptcha,
} from "../../../store/main/authSlice";
import { Spinner } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { setLoginLoader } from "../../../store/main/appSlice";
import useRecaptchaV3 from "../../../components/authLayoutComponents/useRecaptchaV3";

const Login = () => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // const { executeRecaptcha } = useGoogleReCaptcha();
  const executeRecaptcha = useRecaptchaV3(
    "6LeJYmwnAAAAAIHnWUcSMcTjx8g_KeBVEKrbgFxd"
  );

  const { offerDetails, brandDetails, isGettingBrandDetails } = useSelector(
    (state) => state.auth
  );

  const { isLogin } = useSelector((state) => state.app);
  const history = useHistory();

  const { loginDetails, defaultContent } = useSelector(
    (state) => state.globalContent
  );
  const dispatch = useDispatch();
  const { brandName, offerID } = useParams();

  useEffect(() => {
    if (Object.keys(loginDetails).length == 0) {
      dispatch(getGlobalContent("login"));
    }
    dispatch(signOut());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (email.match(emailRegex)) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
  }, [email]);

  const [recaptchaToken, setRecaptchaToken] = useState("");

  const handleRedirect = (redirect) => {
    if (redirect) {
      if (
        offerDetails &&
        brandDetails &&
        offerDetails?.id &&
        brandDetails?.id
      ) {
        history.push(
          `${redirect}/${brandDetails?.slug}/offerID=${offerDetails?.id}`
        );
      } else {
        history.push(redirect);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      setRecaptchaToken("");
      return;
    }
    dispatch(setLoginLoader(true));

    // const token = await executeRecaptcha();
    const token = await executeRecaptcha("login");
    setRecaptchaToken(token);
    dispatch(
      verifyRecaptcha(
        { token },
        () => {
          dispatch(
            userSignIn(
              { email, password },
              history,
              (redirect) => {
                if (redirect) {
                  if (
                    offerDetails &&
                    brandDetails &&
                    offerDetails?.id &&
                    brandDetails?.id
                  ) {
                    history.push(
                      `${redirect}/${brandDetails?.slug}/offerID=${offerDetails?.id}`
                    );
                  } else {
                    history.push(redirect);
                  }
                }
              },
              (message, redirect) => {
                setErrorMessage(message);
                // if (loginDetails?.accNotVerifiedText != message) {
                //   setTimeout(() => {
                //     setErrorMessage("");
                //     if (redirect) {
                //       handleRedirect(redirect);
                //     }
                //   }, 3000);
                // }
              }
            )
          );
        },
        (message) => {
          setErrorMessage(message);
        }
      )
    );
  };

  // const handleReCaptchaVerify = useCallback(
  //   async (e) => {
  //     if (!executeRecaptcha) {
  //       setRecaptchaToken("");
  //       return;
  //     }

  //     const token = await executeRecaptcha();
  //     setRecaptchaToken(token);
  //   },
  //   [executeRecaptcha]
  // );

  return (
    <div className="login_screen ">
      <div className="mb-2">
        {offerDetails && Object.keys(offerDetails).length > 0 ? (
          <h2 className="fw--700 text-center offer_title">
            {offerDetails?.offerTitle}
          </h2>
        ) : (
          <h2 className="fw--700 text-center offer_title">
            {defaultContent?.defaultOfferTitle ? (
              defaultContent?.defaultOfferTitle
            ) : (
              <>
                Up to 20% off for
                <br /> Key Workers
              </>
            )}
          </h2>
        )}

        <p
          className="text-center body_text font-[500] mt-3"
          style={{ color: "#6E7680" }}
        >
          Login to your kii account
          {/* <br /> for instant access */}
        </p>
      </div>
      <form>
        <SiteInput
          type="email"
          className="mt-[22px]  md:mt-[27px]"
          value={email}
          placeholder="email"
          onChange={(event) => {
            setEmail(event.target.value);
            if (errorMessage != "") {
              setErrorMessage("");
            }
          }}
          required
        />
        <div className="mt-[22px]  md:mt-[27px]">
          <SiteInput
            type="password"
            value={password}
            placeholder="password"
            onChange={(event) => {
              setPassword(event.target.value);
              if (errorMessage != "") {
                setErrorMessage("");
              }
            }}
            required
          />
        </div>
        {errorMessage != "" && (
          <p className="error_text mt-[22px]  md:mt-[27px]">
            {errorMessage ===
            "Incorrect login details, please try again or click here to reset your password" ? (
              <span>
                {loginDetails?.passwordMismatchText
                  ? loginDetails.passwordMismatchText
                  : "Incorrect login details"}
                , please try again or{" "}
                <a
                  className="cursor-pointer text-primary underline"
                  onClick={(e) => history.push("/auth/forgot-password")}
                >
                  click here
                </a>{" "}
                to reset your password
              </span>
            ) : errorMessage ==
                "Email not recognized, please try again or Join Free" &&
              loginDetails?.emailNotRecognisedText ? (
              loginDetails?.emailNotRecognisedText
            ) : (
              errorMessage
            )}
          </p>
        )}

        {errorMessage != "" &&
        loginDetails?.accNotVerifiedText == errorMessage ? (
          <SiteButton
            pill
            block
            // to={"/auth/signup/details"}
            onClick={() => {
              handleRedirect("/auth/signup/details");
            }}
            className="mt-[22px]  md:mt-[27px] cursor-pointer"
          >
            Verify Account
          </SiteButton>
        ) : (
          <SiteButton
            disabled={!password || !isEmailValid || isLogin}
            pill
            block
            // to={"/auth/login/congratulations"}
            onClick={handleSubmit}
            className="mt-[22px]  md:mt-[27px] cursor-pointer"
          >
            {isLogin ? (
              <Spinner />
            ) : (
              <>
                {loginDetails?.loginText
                  ? loginDetails.loginText
                  : "Login & unlock offer"}
              </>
            )}
          </SiteButton>
        )}
      </form>
      <div className="mb-4">
        <p
          className="text-center terms_conditions px-5 my-2 font-[500]"
          style={{ color: "#6E7680" }}
        >
          {loginDetails?.recaptchaText
            ? loginDetails.recaptchaText
            : "kii is protected by reCAPTCHA"}{" "}
          {/* and the Google{" "}
          <a
            href="https://www.wearekii.co.uk/privacy-policy"
            target="_blank"
            className="text-primary underline"
          >
            Privacy Policy
          </a>{" "}
          and{" "}
          <a
            href="https://www.wearekii.co.uk/terms-of-service"
            target="_blank"
            className="text-primary underline"
          >
            Terms of Service
          </a>{" "}
          apply. */}
        </p>
      </div>
      <p className="text-center font-[500] text-[17.9385px] mt-[22px] mb-3">
        {loginDetails?.joinNowText
          ? loginDetails?.joinNowText
          : "Not a Member Yet?"}
      </p>
      <SiteButton
        onClick={() => {
          if (
            offerDetails &&
            Object.keys(offerDetails).length > 0 &&
            brandDetails &&
            Object.keys(brandDetails).length > 0
          ) {
            history.push(
              `/auth/signup/${brandDetails?.slug}/offerID=${offerDetails?.id}`
            );
          } else {
            history.push(`/auth/signup`);
          }
        }}
        block
        pill
        outline
      >
        {loginDetails?.joinNowButtonText
          ? loginDetails?.joinNowButtonText
          : "Join now"}
      </SiteButton>
    </div>
  );
};

export default Login;
