import { combineReducers } from "@reduxjs/toolkit";
import app from "./main/appSlice";
import auth from "./main/authSlice";
import globalContent from "./main/globalContentSlice";
import user from "./main/userSlice";
import optinLog from "./main/optinLogSlice";

const reducer = combineReducers({
  app,
  auth,
  globalContent,
  user,
  optinLog,
});
export default reducer;
