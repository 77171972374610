import offerIcon from "../../assets/img/auth-images/offerIcon.svg";
import "../../assets/sass/authPages/selectAuth.scss";
import { useSelector } from "react-redux";

import { useEffect } from "react";

import SiteButton from "../../components/custom/SiteButton";

const InvalidOffer = ({ title }) => {
  const { brandDetails } = useSelector((state) => state.auth);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <h2 className="mt-[16px] offer_title font-[700] text-center">{title}</h2>
      <div className="offer-icon mt-3">
        <img src={offerIcon} className="w-[19px] h-[16px]" alt="" />
      </div>
      <p className="text-center offer_description lg:px-10 sm:px-0  leading-[26px] font-[400] lg:mb-[27px] mb-[22px]">
        Please return to site or refer to kii for the latest key worker offers
      </p>

      {/* <SiteButton
        onClick={() => {
          window.location.href = brandDetails?.brandLink;
        }}
        block
        pill
        className="lg:mb-[27px] mb-[22px]"
      >
        Return to {brandDetails?.brandName}
      </SiteButton> */}
      <SiteButton
        onClick={() => {
          window.open("https://www.wearekii.co.uk/offers");
        }}
        block
        pill
        outline
        className="lg:mb-[27px] mb-[22px]"
      >
        Show me the Latest Offers
      </SiteButton>
    </>
  );
};

export default InvalidOffer;
