import { useDispatch, useSelector } from "react-redux";
import { getOfferDetails, signOut } from "../store/main/authSlice";
import React, { Suspense, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

// Importing components with React.lazy
const Footer = React.lazy(() =>
  import("../components/authLayoutComponents/Footer")
);
const SelectAuthHeader = React.lazy(() =>
  import("../components/authLayoutComponents/SelectAuthHeader")
);
// const Loading = React.lazy(() => import("../components/custom/Loading"));

// const SkeletonLoader = React.lazy(() =>
//   import("../components/SkeletonLoader.js")
// );

const SelectAuth = (props) => {
  const { brandName, offerID } = useParams();
  const history = useHistory();

  const { uid, brandDetails, offerDetails } = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();
  // const { isGettingGlobalContent } = useSelector(
  //   (state) => state.globalContent
  // );

  const location = useLocation();
  useEffect(() => {
    const pathname = location.pathname;
    window.parent.postMessage({ pathname: pathname }, "*");

    // const initialHeight = 1200;
    // window.parent.postMessage({ height: initialHeight }, "*");

    const timeoutId = setTimeout(() => {
      const height = document.body.scrollHeight;
      window.parent.postMessage({ height: height }, "*");
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [location.pathname]);

  useEffect(() => {
    if (brandName && offerID) {
      dispatch(
        getOfferDetails(
          brandName,
          offerID,
          history,
          () => {},
          (message) => {
            history.push("/auth/login");
            dispatch(signOut());
          }
        )
      );
    }
  }, [brandName, offerID]);

  useEffect(() => {
    const setDocumentTitle = () => {
      if (offerDetails?.id && brandDetails?.id) {
        document.title = `${brandDetails.brandName} | ${offerDetails.offerTitle} at kii`;
      } else {
        document.title = `kii`;
      }
    };

    setDocumentTitle();
  }, [offerDetails?.id, brandDetails?.id]);

  if (uid) {
    dispatch(signOut());
  }

  return (
    <section className="main-app offer-page rounded-[35px] overflow-hidden relative">
      <Suspense fallback={<div></div>}>
        {/* {(isGettingBrandDetails || isGettingGlobalContent) && <Loading />} */}

        <SelectAuthHeader />
        {props.children}
        <Footer />
      </Suspense>
    </section>
  );
};

export default SelectAuth;
