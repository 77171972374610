import { createSlice } from "@reduxjs/toolkit";
import firebase from "../../config/firebase";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import { toast } from "react-toastify";
import { setIsVerifying, setLoginLoader, setSigningUpLoader } from "./appSlice";
import moment from "moment";
import { useSelector } from "react-redux";
import { getOptinLogByUserBrandOffer } from "./optinLogSlice";

let verification = RepositoryFactory.get("verification");

let codeCollection = firebase.firestore().collection("codes");
let usersCollection = firebase.firestore().collection("users");
let offersCollection = firebase.firestore().collection("offers");
let brandsCollection = firebase.firestore().collection("brands");

const currentDate = new Date();
const formattedDate = currentDate.toLocaleString("en-US", {
  month: "numeric",
  day: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  hour12: true,
});

export const getBrandDetails = (payload, history) => async (dispatch) => {
  dispatch(setGetSingleBrandLoading(true));

  firebase
    .firestore()
    .collection("brands")
    .where("slug", "==", payload.brandName)
    .onSnapshot(async (query) => {
      if (query?.size > 0) {
        if (query.docs[0]?.data().brandActive) {
          dispatch(
            setBrandDetails({ id: query.docs[0].id, ...query.docs[0].data() })
          );
          dispatch(setGetSingleBrandLoading(false));
        } else {
          dispatch(brandNotFound({}, {}, history, "/auth/not-found"));
        }
      } else {
        dispatch(brandNotFound({}, {}, history, "/auth/not-found"));
        dispatch(setBrandDetails({}));
        dispatch(setGetSingleBrandLoading(false));
      }
    });
};

export const getSingleOfferDetails =
  (offerID, history, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch) => {
    dispatch(setGetSingleOfferLoading(true));
    offersCollection.doc(offerID).onSnapshot(async (query) => {
      if (query.data()) {
        await dispatch(setOfferDetails({ id: query.id, ...query.data() }));
        dispatch(setGetSingleOfferLoading(false));
        onSuccess();
      } else {
        dispatch(brandNotFound({}, {}, history, "/auth/invalid-offer"));
        dispatch(setGetSingleOfferLoading(false));
      }
    });
  };

export const brandNotFound =
  (brandDetails, offerDetails, history, link) => async (dispatch) => {
    dispatch(setGettingBrandsLoading(false));
    dispatch(setOfferDetails(offerDetails));
    dispatch(setBrandDetails(brandDetails));
    dispatch(signOut());

    if (link) {
      history.push(link);
    }
  };

export const getOfferDetails =
  (brand, offerID, history, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch, getState) => {
    dispatch(setGettingBrandsLoading(true));

    offersCollection.doc(offerID).onSnapshot(async (query) => {
      if (query.data()) {
        let { brandName } = query.data();

        let brandDetails = await brandsCollection
          .where("slug", "==", brand)
          .get();

        if (brandDetails.size > 0) {
          if (brandDetails.docs[0].data().brandActive) {
            let { slug } = brandDetails.docs[0].data();

            if (slug == brand && brandName == brandDetails.docs[0].id) {
              let { endDate } = query.data();

              let {
                uid,
                brandDetails: existingBrand,
                offerDetails: existingOffer,
                isLoggedInByBrand,
              } = getState().auth;

              const currentDate = new Date(); // Get the current date
              let targetDate = "";
              if (endDate && endDate != "") {
                targetDate = new Date(endDate);
              }

              if (endDate && endDate != "" && currentDate < targetDate) {
                if (
                  uid &&
                  isLoggedInByBrand &&
                  existingBrand?.id &&
                  existingOffer?.id &&
                  (existingBrand?.id != brandDetails.docs[0].id ||
                    existingOffer?.id != query?.id)
                ) {
                  await dispatch(
                    setOfferDetails({ id: query.id, ...query.data() })
                  );
                  await dispatch(
                    setBrandDetails({
                      id: brandDetails.docs[0].id,
                      ...brandDetails.docs[0].data(),
                    })
                  );
                  setTimeout(() => {
                    dispatch(signOut(history));
                    dispatch(setGettingBrandsLoading(false));
                  }, 2000);
                } else if (uid && !isLoggedInByBrand) {
                  await dispatch(
                    setOfferDetails({ id: query.id, ...query.data() })
                  );
                  await dispatch(
                    setBrandDetails({
                      id: brandDetails.docs[0].id,
                      ...brandDetails.docs[0].data(),
                    })
                  );
                  setTimeout(() => {
                    dispatch(signOut(history));
                    dispatch(setGettingBrandsLoading(false));
                  }, 2000);
                } else {
                  dispatch(setOfferDetails({ id: query.id, ...query.data() }));
                  dispatch(
                    setBrandDetails({
                      id: brandDetails.docs[0].id,
                      ...brandDetails.docs[0].data(),
                    })
                  );
                  dispatch(setGettingBrandsLoading(false));
                  onSuccess();
                }
              } else if (endDate == "") {
                if (
                  uid &&
                  isLoggedInByBrand &&
                  existingBrand?.id &&
                  existingOffer?.id &&
                  (existingBrand?.id != brandDetails.docs[0].id ||
                    existingOffer?.id != query?.id)
                ) {
                  await dispatch(
                    setOfferDetails({ id: query.id, ...query.data() })
                  );
                  await dispatch(
                    setBrandDetails({
                      id: brandDetails.docs[0].id,
                      ...brandDetails.docs[0].data(),
                    })
                  );
                  setTimeout(() => {
                    dispatch(signOut(history));
                    dispatch(setGettingBrandsLoading(false));
                  }, 2000);
                } else if (uid && !isLoggedInByBrand) {
                  await dispatch(
                    setOfferDetails({ id: query.id, ...query.data() })
                  );
                  await dispatch(
                    setBrandDetails({
                      id: brandDetails.docs[0].id,
                      ...brandDetails.docs[0].data(),
                    })
                  );
                  setTimeout(() => {
                    dispatch(signOut(history));
                    dispatch(setGettingBrandsLoading(false));
                  }, 2000);
                } else {
                  dispatch(setOfferDetails({ id: query.id, ...query.data() }));
                  dispatch(
                    setBrandDetails({
                      id: brandDetails.docs[0].id,
                      ...brandDetails.docs[0].data(),
                    })
                  );
                  dispatch(setGettingBrandsLoading(false));
                  onSuccess();
                }
              } else {
                dispatch(
                  brandNotFound(
                    {
                      id: brandDetails?.docs[0].id,
                      ...brandDetails.docs[0].data(),
                    },
                    { id: query.id, ...query.data() },
                    history,
                    "/auth/offer-expired"
                  )
                );
              }
            } else {
              dispatch(
                brandNotFound(
                  {
                    id: brandDetails?.docs[0].id,
                    ...brandDetails.docs[0].data(),
                  },
                  {},
                  history,
                  "/auth/invalid-offer"
                )
              );
            }
          } else {
            dispatch(brandNotFound({}, {}, history, "/auth/not-found"));
          }
        } else {
          dispatch(brandNotFound({}, {}, history, "/auth/not-found"));
        }
      } else {
        let brandDetails = await brandsCollection
          .where("slug", "==", brand)
          .get();

        if (
          brandDetails?.size > 0 &&
          brandDetails.docs[0]?.data().brandActive
        ) {
          dispatch(
            brandNotFound(
              {
                id: brandDetails?.docs[0].id,
                ...brandDetails.docs[0].data(),
              },
              {},
              history,
              "/auth/invalid-offer"
            )
          );
        } else {
          dispatch(brandNotFound({}, {}, history, "/auth/not-found"));
        }
      }
    });
  };

// export const getOfferDetails =
//   (brand, offerID, history, onSuccess = () => {}, onError = () => {}) =>
//   async (dispatch, getState) => {
//     dispatch(setGettingBrandsLoading(true));

//     try {
//       const offerSnapshot = await offersCollection.doc(offerID).get();

//       if (offerSnapshot.exists) {
//         const { brandName, endDate } = offerSnapshot.data();
//         const brandDetailsSnapshot = await brandsCollection
//           .where("slug", "==", brand)
//           .get();

//         if (!brandDetailsSnapshot.empty) {
//           const brandDetailsData = brandDetailsSnapshot.docs[0].data();

//           if (brandDetailsData.brandActive) {
//             const {
//               uid,
//               brandDetails: existingBrand,
//               offerDetails: existingOffer,
//               isLoggedInByBrand,
//             } = getState().auth;
//             const currentDate = new Date();
//             const targetDate =
//               endDate && endDate !== "" ? new Date(endDate) : null;

//             if ((targetDate && currentDate < targetDate) || !targetDate) {
//               await dispatch(
//                 setOfferDetails({
//                   id: offerSnapshot.id,
//                   ...offerSnapshot.data(),
//                 })
//               );
//               await dispatch(
//                 setBrandDetails({
//                   id: brandDetailsSnapshot.docs[0].id,
//                   ...brandDetailsData,
//                 })
//               );

//               if (
//                 (uid &&
//                   isLoggedInByBrand &&
//                   existingBrand?.id &&
//                   existingOffer?.id &&
//                   (existingBrand?.id !== brandDetailsSnapshot.docs[0].id ||
//                     existingOffer?.id !== offerSnapshot.id)) ||
//                 (uid && !isLoggedInByBrand)
//               ) {
//                 setTimeout(() => {
//                   dispatch(signOut(history));
//                   dispatch(setGettingBrandsLoading(false));
//                 }, 2000);
//               } else {
//                 dispatch(setGettingBrandsLoading(false));
//               }
//             } else {
//               dispatch(
//                 brandNotFound(
//                   { id: brandDetailsSnapshot.docs[0].id, ...brandDetailsData },
//                   { id: offerSnapshot.id, ...offerSnapshot.data() },
//                   history,
//                   "/auth/offer-expired"
//                 )
//               );
//             }
//           } else {
//             dispatch(brandNotFound({}, {}, history, "/auth/not-found"));
//           }
//         } else {
//           dispatch(brandNotFound({}, {}, history, "/auth/not-found"));
//         }
//       } else {
//         const brandDetailsSnapshot = await brandsCollection
//           .where("slug", "==", brand)
//           .get();

//         if (
//           !brandDetailsSnapshot.empty &&
//           brandDetailsSnapshot.docs[0].data().brandActive
//         ) {
//           dispatch(
//             brandNotFound(
//               {
//                 id: brandDetailsSnapshot.docs[0].id,
//                 ...brandDetailsSnapshot.docs[0].data(),
//               },
//               {},
//               history,
//               "/auth/invalid-offer"
//             )
//           );
//         } else {
//           dispatch(brandNotFound({}, {}, history, "/auth/not-found"));
//         }
//       }
//     } catch (error) {
//       // Handle errors here
//       console.error("Error in getOfferDetails:", error);
//       onError(error); // You can call the onError function here with the error
//     }
//   };

export const sendVerificationCode =
  (payload, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch) => {
    try {
      let details = await firebase
        .firestore()
        .collection("users")
        .where("secondaryEmail", "==", payload.email)
        .get();
      if (details?.size > 0) {
        onError("This work email is already in used by another member");
      } else {
        let { data } = await verification.sendVerificationCode(payload);

        onSuccess();
      }

      // toast.success("Verification code send successfully!");
    } catch (error) {
      onError("oops! something went wrong");
    }
  };

export const verifyMemberLicense =
  (payload, offerID, history, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch, getState) => {
    let { auth } = getState();

    let {
      offerDetails: existingOfferDetails,
      brandDetails: existingBandDetails,
    } = auth;
    try {
      dispatch(setIsVerifying(true));
      // let { referrerSource } = auth.user;
      let { data } = await verification.verifyLicense(payload);

      let userDetails = await usersCollection.doc(payload?.userId).get();
      let { referrerSource } = userDetails.data();
      let obj = {
        verified: "yes",
        dateVerified: formattedDate,
        verificationMethod: "ID",
      };
      if (
        referrerSource &&
        referrerSource == "" &&
        existingOfferDetails?.id &&
        existingBandDetails?.id
      ) {
        let source =
          "/" +
          existingBandDetails?.slug +
          "/offerID=" +
          existingOfferDetails.id;
        obj = { ...obj, referrerSource: source };
      }

      if (
        offerID &&
        offerID != "" &&
        existingOfferDetails?.offerType == "code"
      ) {
        let unAssignedCodeDetails;
        if (
          existingOfferDetails?.codeType &&
          existingOfferDetails?.codeType == "batch"
        ) {
          unAssignedCodeDetails = await codeCollection
            .where("offer", "==", offerID)
            .where("status", "==", "unassigned")
            .get();
        } else if (
          existingOfferDetails?.codeType &&
          existingOfferDetails?.codeType == "generic"
        ) {
          unAssignedCodeDetails = await codeCollection
            .where("offer", "==", offerID)
            .get();
        }
        // let unAssignedCodeDetails = await codeCollection
        //   .where("offer", "==", offerID)
        //   .where("status", "==", "unassigned")
        //   .get();

        if (unAssignedCodeDetails.size > 0) {
          let codeId = unAssignedCodeDetails.docs[0].id;
          usersCollection
            .doc(payload?.userId)
            .update({
              ...obj,
              codeId,
            })
            .then((res) => {
              codeCollection.doc(codeId).update({
                status: "assigned",
                assignedAt: firebase.firestore.Timestamp.now(),
              });

              firebase
                .firestore()
                .collection("codeIssuanceLogs")
                .add({
                  userId: payload?.userId,
                  codeId,
                  brandId: existingBandDetails?.id,
                  offerId: existingOfferDetails?.id,
                  createdAt: firebase.firestore.Timestamp.now(),
                })
                .then((res) => {});
              let isFirstRun = true;
              usersCollection.doc(payload?.userId).onSnapshot(async (query) => {
                let { codeId, referrerSource } = query.data();
                // let offer = referrerSource.split("=")[1];
                let codeDetails = await codeCollection.doc(codeId).get();
                let offerDetails = await offersCollection.doc(offerID).get();
                let { brandName } = offerDetails.data();

                let brandDetails = await brandsCollection.doc(brandName).get();

                await dispatch(
                  setUser({
                    id: query.id,
                    ...query.data(),
                    codeDetails: {
                      id: codeDetails.id,
                      ...codeDetails.data(),
                    },
                    // offerDetails: {
                    //   id: offerDetails.id,
                    //   ...offerDetails.data(),
                    // },
                    // brandDetails: {
                    //   id: brandDetails.id,
                    //   ...brandDetails.data(),
                    // },
                  })
                );
                await dispatch(setLoggedByBrand(true));

                await dispatch(setUID(query.id));
                dispatch(setIsVerifying(false));
                if (isFirstRun) {
                  history.push(
                    `/auth/login/congratulations/${brandDetails?.slug}/offerID=${offerID}`
                  );
                  isFirstRun = false;
                }
              });
            });
        } else {
          // dispatch(setIsVerifying(false));
          // onError("Codes not available for this offer!");

          usersCollection
            .doc(payload?.userId)
            .update({
              ...obj,
              codeId: "",
            })
            .then((res) => {
              let isFirstRun = true;
              usersCollection.doc(payload?.userId).onSnapshot(async (query) => {
                let { codeId } = query.data();
                let codeDetails = {};
                if (codeId && codeId != "") {
                  let codeData = codeCollection.doc(codeId).get();
                  if (codeData.data()) {
                    codeDetails = { id: codeData.id, ...codeData.data() };
                  }
                }
                await dispatch(
                  setUser({
                    id: query.id,
                    ...query.data(),
                    codeDetails,
                    offerDetails: {},
                    brandDetails: {},
                  })
                );
                if (existingOfferDetails?.id) {
                  await dispatch(setLoggedByBrand(true));
                } else {
                  await dispatch(setLoggedByBrand(false));
                }
                await dispatch(setUID(query.id));

                dispatch(setIsVerifying(false));
                if (isFirstRun) {
                  history.push("/auth/verified");
                  isFirstRun = false;
                }
              });
            });
        }
      } else {
        usersCollection
          .doc(payload?.userId)
          .update({
            ...obj,
            codeId: "",
          })
          .then((res) => {
            let isFirstRun = true;
            usersCollection.doc(payload?.userId).onSnapshot(async (query) => {
              await dispatch(
                setUser({
                  id: query.id,
                  ...query.data(),
                  codeDetails: {},
                  offerDetails: {},
                  brandDetails: {},
                })
              );
              if (existingOfferDetails?.offerType == "code") {
                await dispatch(setLoggedByBrand(true));
              } else {
                await dispatch(setLoggedByBrand(false));
              }
              await dispatch(setUID(query.id));

              dispatch(setIsVerifying(false));
              if (isFirstRun) {
                history.push("/auth/verified");
                isFirstRun = false;
              }
            });
          });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        usersCollection
          .doc(payload.userId)
          .update({
            verified: "pending",
          })
          .then((res) => {
            setTimeout(() => {
              dispatch(setIsVerifying(false));
              let { offerDetails, brandDetails } = auth;
              if (
                offerDetails &&
                brandDetails &&
                offerDetails?.id &&
                brandDetails?.id
              ) {
                history.push(
                  `/auth/verify/manualreview/${brandDetails?.slug}/offerID=${offerDetails?.id}`
                );
              } else {
                history.push("/auth/verify/manualreview");
              }
            }, [1000]);
          })
          .catch((err) => {
            dispatch(setIsVerifying(false));
            onError(err.message);
          });
      } else {
        dispatch(setIsVerifying(false));
        onError(error?.response?.data?.error);
      }
    }
  };

export const checkVerificationCode =
  (memberId, payload, offerID, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch, getState) => {
    let {
      signupDetails,
      offerDetails: existingOfferDetails,
      brandDetails: existingBandDetails,
    } = getState().auth;
    await dispatch(setSignUpStartedAt(moment()));

    try {
      dispatch(setIsVerifying(true));
      let { data } = await verification.checkCodeIsValid(payload);

      let userDetails = await usersCollection.doc(memberId).get();
      let { referrerSource } = userDetails.data();

      let obj = {
        verified: "yes",
        dateVerified: formattedDate,
        verificationMethod: "email",
        secondaryEmail: payload?.email,
      };

      if (
        referrerSource &&
        referrerSource == "" &&
        existingOfferDetails?.id &&
        existingBandDetails?.id
      ) {
        let source =
          "/" +
          existingBandDetails?.slug +
          "/offerID=" +
          existingOfferDetails.id;
        obj = { ...obj, referrerSource: source };
      }

      if (
        offerID &&
        offerID != "" &&
        existingOfferDetails?.offerType == "code"
      ) {
        // let unAssignedCodeDetails = await codeCollection
        //   .where("offer", "==", offerID)
        //   .where("status", "==", "unassigned")
        //   .get();
        let unAssignedCodeDetails;
        if (
          existingOfferDetails?.codeType &&
          existingOfferDetails?.codeType == "batch"
        ) {
          unAssignedCodeDetails = await codeCollection
            .where("offer", "==", offerID)
            .where("status", "==", "unassigned")
            .get();
        } else if (
          existingOfferDetails?.codeType &&
          existingOfferDetails?.codeType == "generic"
        ) {
          unAssignedCodeDetails = await codeCollection
            .where("offer", "==", offerID)
            .get();
        }

        if (unAssignedCodeDetails?.size > 0) {
          let codeId = unAssignedCodeDetails.docs[0].id;
          usersCollection
            .doc(memberId)
            .update({
              ...obj,
              codeId,
            })
            .then(async (res) => {
              codeCollection.doc(codeId).update({
                status: "assigned",
                assignedAt: firebase.firestore.Timestamp.now(),
              });

              firebase
                .firestore()
                .collection("codeIssuanceLogs")
                .add({
                  userId: memberId,
                  codeId,
                  brandId: existingOfferDetails?.brandName,
                  offerId: existingOfferDetails?.id,
                  createdAt: firebase.firestore.Timestamp.now(),
                })
                .then((res) => {});
              let codeDetails = await codeCollection.doc(codeId).get();

              usersCollection.doc(memberId).onSnapshot(async (query) => {
                // let offer = referrerSource.split("=")[1];
                // let offerDetails = await offersCollection.doc(offer).get();
                // let { brandName } = offerDetails.data();

                // let brandDetails = await brandsCollection.doc(brandName).get();

                await dispatch(
                  setUser({
                    id: query.id,
                    ...query.data(),
                    codeDetails: {
                      id: codeDetails.id,
                      ...codeDetails.data(),
                    },
                    // offerDetails: {
                    //   id: offerDetails.id,
                    //   ...offerDetails.data(),
                    // },
                    // brandDetails: {
                    //   id: brandDetails.id,
                    //   ...brandDetails.data(),
                    // },
                  })
                );
                await dispatch(
                  getOptinLogByUserBrandOffer(
                    query.id,
                    existingBandDetails?.id,
                    existingOfferDetails?.id
                  )
                );
                await dispatch(setLoggedByBrand(true));

                await dispatch(setUID(query.id));
                dispatch(setIsVerifying(false));
                onSuccess();
              });
            })
            .catch((err) => {
              dispatch(setIsVerifying(false));
              onError();
            });
        } else {
          // dispatch(setIsVerifying(false));
          // onError();

          usersCollection
            .doc(memberId)
            .update({
              ...obj,
              codeId: "",
            })
            .then((res) => {
              usersCollection.doc(memberId).onSnapshot(async (query) => {
                let { codeId } = query.data();
                let codeDetails = {};
                if (codeId && codeId != "") {
                  let codeData = codeCollection.doc(codeId).get();
                  if (codeData.data()) {
                    codeDetails = { id: codeData.id, ...codeData.data() };
                  }
                }

                if (existingOfferDetails?.id) {
                  await dispatch(
                    getOptinLogByUserBrandOffer(
                      query.id,
                      existingBandDetails?.id,
                      existingOfferDetails?.id
                    )
                  );
                  await dispatch(setLoggedByBrand(true));
                } else {
                  await dispatch(setLoggedByBrand(false));
                }
                await dispatch(setUID(query.id));

                await dispatch(
                  setUser({
                    id: query.id,
                    ...query.data(),
                    codeDetails,
                    offerDetails: {},
                    brandDetails: {},
                  })
                );
                dispatch(setIsVerifying(false));
                onSuccess();
              });
            })
            .catch((err) => {
              dispatch(setIsVerifying(false));
              onError();
            });
        }
      } else {
        usersCollection
          .doc(memberId)
          .update({
            ...obj,
            codeId: "",
          })
          .then((res) => {
            usersCollection.doc(memberId).onSnapshot(async (query) => {
              if (existingOfferDetails?.id) {
                await dispatch(
                  getOptinLogByUserBrandOffer(
                    query.id,
                    existingBandDetails?.id,
                    existingOfferDetails?.id
                  )
                );
                await dispatch(setLoggedByBrand(true));
              } else {
                await dispatch(setLoggedByBrand(false));
              }
              await dispatch(setUID(query.id));

              await dispatch(
                setUser({
                  id: query.id,
                  ...query.data(),
                  codeDetails: {},
                  offerDetails: {},
                  brandDetails: {},
                })
              );
              dispatch(setIsVerifying(false));
              onSuccess();
            });
          })
          .catch((err) => {
            dispatch(setIsVerifying(false));

            onError();
          });
      }
    } catch (error) {
      dispatch(setIsVerifying(false));
      onError(error?.response?.data?.message);
    }
  };

export const userSignUP =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(setSigningUpLoader(true));

    firebase
      .auth()
      .createUserWithEmailAndPassword(payload.primaryEmail, payload.password)
      .then((data) => {
        let { password, confirmPassword, ...rest } = payload;
        let obj = {
          ...rest,
          dateRegistered: formattedDate,
          dateEmailOptUpdated: formattedDate,
        };
        firebase
          .firestore()
          .collection("users")
          .doc(data.user.uid)
          .set(obj)
          .then((res) => {
            dispatch(
              sendVerificationCode({ email: payload?.secondaryEmail }, () => {
                dispatch(setSigningUpLoader(false));
                onSuccess();
              }),
              () => {
                dispatch(setSigningUpLoader(false));
              }
            );
          })
          .catch((err) => {
            dispatch(setSigningUpLoader(false));
            toast.error(err.message);
          });
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(setSigningUpLoader(false));
      });
  };

export const updateMember =
  (memberId, payload, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch) => {
    dispatch(setSigningUpLoader(true));
    await dispatch(setSignUpStartedAt(moment()));

    usersCollection
      .doc(memberId)
      .update(payload)
      .then((res) => {
        setTimeout(() => {
          dispatch(setSigningUpLoader(false));
          onSuccess();
        }, 1500);
      })
      .catch((err) => {
        dispatch(setSigningUpLoader(false));
        // toast.error(err.message);
        onError(err.message);
      });
  };

export const codeAssigningProcess =
  (offerDetails, query, source) => async (dispatch, getState) => {
    let { brandDetails } = getState().auth;
    let unAssignedCodeDetails;
    if (offerDetails?.codeType == "batch") {
      unAssignedCodeDetails = await codeCollection
        .where("offer", "==", offerDetails.id)
        .where("status", "==", "unassigned")
        .get();
    } else if (offerDetails?.codeType == "generic") {
      unAssignedCodeDetails = await codeCollection
        .where("offer", "==", offerDetails.id)
        .get();
    }

    if (unAssignedCodeDetails?.size && unAssignedCodeDetails?.size > 0) {
      let codeId = unAssignedCodeDetails.docs[0].id;
      let codeDetails = unAssignedCodeDetails.docs[0].data();
      let obj = { codeId };
      if (source) {
        obj = { ...obj, referrerSource: source };
      }
      usersCollection
        .doc(query.id)
        .update(obj)
        .then(async (res) => {
          codeCollection.doc(codeId).update({
            status: "assigned",
            assignedAt: firebase.firestore.Timestamp.now(),
          });
          dispatch(
            setUser({
              id: query.id,
              ...query.data(),
              codeDetails: { id: codeId, ...codeDetails },
            })
          );
          await dispatch(
            getOptinLogByUserBrandOffer(
              query.id,
              brandDetails.id,
              offerDetails.id
            )
          );
          await dispatch(setLoggedByBrand(true));
          dispatch(setUID(query.id));
          setTimeout(() => {
            dispatch(setLoginLoader(false));
          }, 2000);
        });
    } else {
      dispatch(
        setUser({
          id: query.id,
          ...query.data(),
          offerDetails: {},
          brandDetails: {},
          codeDetails: {},
        })
      );
      await dispatch(
        getOptinLogByUserBrandOffer(query.id, brandDetails.id, offerDetails.id)
      );
      await dispatch(setLoggedByBrand(true));
      await dispatch(setUID(query.id));
      setTimeout(() => {
        dispatch(setLoginLoader(false));
      }, 2000);
    }
  };

export const userSignIn =
  (payload, history, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch, getState) => {
    dispatch(setLoginLoader(true));
    await dispatch(setSignUpStartedAt(moment()));

    let { user, offerDetails, brandDetails } = getState().auth;
    let { loginDetails } = getState().globalContent;

    firebase
      .auth()
      .signInWithEmailAndPassword(payload.email, payload.password)
      .then((res) => {
        let unsubscribe = null;
        let isFirstRun = true;
        unsubscribe = usersCollection
          .doc(res.user.uid)
          .onSnapshot(async (query) => {
            if (query.data()) {
              let { userType, verified, referrerSource, codeId } = query.data();
              if (userType == "member") {
                if (verified && verified == "yes") {
                  if (
                    referrerSource &&
                    referrerSource != "" &&
                    codeId &&
                    codeId != ""
                  ) {
                    let codeDetails = await codeCollection.doc(codeId).get();

                    let codeData = {};

                    if (
                      codeDetails.data() &&
                      codeDetails.data().offer != offerDetails?.id &&
                      offerDetails?.offerType == "code"
                    ) {
                      dispatch(codeAssigningProcess(offerDetails, query));
                    } else {
                      if (codeDetails.data()) {
                        codeData = {
                          id: codeDetails?.id,
                          ...codeDetails.data(),
                        };
                      }

                      dispatch(
                        setUser({
                          id: query.id,
                          ...query.data(),

                          codeDetails: codeData,
                        })
                      );
                      if (offerDetails?.id && brandDetails?.id) {
                        await dispatch(
                          getOptinLogByUserBrandOffer(
                            query.id,
                            brandDetails.id,
                            offerDetails.id
                          )
                        );
                        await dispatch(setLoggedByBrand(true));
                      }
                      await dispatch(setUID(query.id));

                      setTimeout(() => {
                        dispatch(setLoginLoader(false));
                      }, 2000);
                      if (isFirstRun) {
                        isFirstRun = false;
                      }
                    }
                  } else if (
                    offerDetails &&
                    brandDetails &&
                    Object.keys(offerDetails).length > 0 &&
                    Object.keys(brandDetails).length > 0 &&
                    offerDetails?.offerType == "code"
                  ) {
                    let source =
                      "/" + brandDetails?.slug + "/offerID=" + offerDetails.id;

                    dispatch(codeAssigningProcess(offerDetails, query, source));
                  } else {
                    if (
                      offerDetails?.id &&
                      brandDetails?.id &&
                      referrerSource &&
                      referrerSource == ""
                    ) {
                      let source =
                        "/" +
                        brandDetails?.slug +
                        "/offerID=" +
                        offerDetails.id;
                      usersCollection
                        .doc(query.id)
                        .update({ referrerSource: source })
                        .then(async (res) => {
                          await dispatch(
                            getOptinLogByUserBrandOffer(
                              query.id,
                              brandDetails.id,
                              offerDetails.id
                            )
                          );
                          await dispatch(setLoggedByBrand(true));
                          dispatch(
                            setUser({
                              id: query.id,
                              ...query.data(),
                              offerDetails: {},
                              brandDetails: {},
                              codeDetails: {},
                            })
                          );
                          dispatch(setUID(query.id));
                          setTimeout(() => {
                            dispatch(setLoginLoader(false));
                          }, 2000);
                        });
                    } else {
                      if (brandDetails?.id && offerDetails?.id) {
                        await dispatch(
                          getOptinLogByUserBrandOffer(
                            query.id,
                            brandDetails.id,
                            offerDetails.id
                          )
                        );
                        await dispatch(setLoggedByBrand(true));
                      } else {
                        await dispatch(setLoggedByBrand(false));
                      }
                      dispatch(
                        setUser({
                          id: query.id,
                          ...query.data(),
                          offerDetails: {},
                          brandDetails: {},
                          codeDetails: {},
                        })
                      );

                      dispatch(setUID(query.id));
                      setTimeout(() => {
                        dispatch(setLoginLoader(false));
                      }, 2000);
                    }

                    if (isFirstRun) {
                      isFirstRun = false;
                    }
                  }
                } else {
                  if (referrerSource && referrerSource != "") {
                    dispatch(
                      setUser({
                        id: query.id,
                        ...query.data(),

                        codeDetails: {},
                      })
                    );

                    if (isFirstRun) {
                      onError(
                        loginDetails?.accNotVerifiedText,
                        "/auth/signup/details"
                      );
                      isFirstRun = false;
                    }
                    setTimeout(() => {
                      dispatch(setLoginLoader(false));
                    }, 2000);
                  } else {
                    let source = "";

                    if (
                      brandDetails &&
                      offerDetails &&
                      brandDetails?.id &&
                      offerDetails?.id
                    ) {
                      source =
                        "/" +
                        brandDetails?.slug +
                        "/offerID=" +
                        offerDetails.id;
                      usersCollection
                        .doc(query.id)
                        .update({
                          referrerSource: source,
                        })
                        .then((res) => {
                          dispatch(
                            setUser({
                              id: query.id,
                              ...query.data(),
                              referrerSource: source,
                              offerDetails: {},
                              brandDetails: {},
                              codeDetails: {},
                            })
                          );
                          if (unsubscribe) {
                            unsubscribe();
                          }
                          onError(
                            loginDetails?.accNotVerifiedText,
                            "/auth/signup/details"
                          );
                          setTimeout(() => {
                            dispatch(setLoginLoader(false));
                          }, 1500);
                        });
                    } else {
                      dispatch(setMemberId(res.user.uid));
                      dispatch(
                        setUser({
                          id: query.id,
                          ...query.data(),
                          referrerSource: source,
                          offerDetails: {},
                          brandDetails: {},
                          codeDetails: {},
                        })
                      );
                      if (unsubscribe) {
                        unsubscribe();
                      }
                      onError(
                        loginDetails?.accNotVerifiedText,
                        "/auth/signup/details"
                      );
                      setTimeout(() => {
                        dispatch(setLoginLoader(false));
                      }, 1500);
                    }
                  }
                }
              } else {
                if (unsubscribe) {
                  unsubscribe();
                }
                onError("Email not recognized, please try again or Join Free");
                setTimeout(() => {
                  dispatch(setLoginLoader(false));
                }, 1500);
                dispatch(signOut());
              }
            } else {
              if (unsubscribe) {
                unsubscribe();
              }
              dispatch(signOut());
              setTimeout(() => {
                dispatch(setLoginLoader(false));
              }, 1500);

              onError("Email not recognized, please try again or Join Free");
            }
          });
      })
      .catch((err) => {
        if (
          err.message ==
          "There is no user record corresponding to this identifier. The user may have been deleted."
        ) {
          onError("Email not recognized, please try again or Join Free");
        } else if (
          err.message ==
          "The password is invalid or the user does not have a password."
        ) {
          onError(
            "Incorrect login details, please try again or click here to reset your password"
          );
        } else {
          onError(err.message);
        }
        setTimeout(() => {
          dispatch(setLoginLoader(false));
        }, 1500);
      });
  };

export const verifyRecaptcha =
  (payload, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch) => {
    dispatch(setLoginLoader(true));

    try {
      let { data } = await verification.verifyRecaptcha(payload);
      if (data?.data?.success) {
        onSuccess();

        dispatch(setLoginLoader(false));
      } else {
        console.log("Error of recaptcha in if", data);

        if (
          data?.data["error-codes"] &&
          data?.data["error-codes"].includes("browser-error")
        ) {
          onSuccess();
        } else {
          onError("Failed to verify recaptcha");
        }

        dispatch(setLoginLoader(false));
      }
    } catch (error) {
      console.log("Error of recaptcha", error);

      onError("Failed to verify recaptcha");

      dispatch(setLoginLoader(false));
    }
  };

export const signOut = (history) => async (dispatch, getState) => {
  let { offerDetails, brandDetails } = getState().auth;
  firebase
    .auth()
    .signOut()
    .then(async (res) => {
      if (
        offerDetails &&
        brandDetails &&
        offerDetails?.id &&
        brandDetails?.id
      ) {
        await dispatch(clearAuthStatesWithoutBrands());
        if (history) {
          history.push(`/${brandDetails?.slug}/offerID=${offerDetails?.id}`);
        }
      } else {
        await dispatch(clearAuthStatesWithoutBrands());
        if (history) {
          history.push("/");
        }
      }
    })
    .catch(async (err) => {
      if (
        offerDetails &&
        brandDetails &&
        offerDetails?.id &&
        brandDetails?.id
      ) {
        await dispatch(clearAuthStatesWithoutBrands());
        if (history) {
          history.push(
            `/auth/login/${brandDetails?.slug}/offerID=${offerDetails?.id}`
          );
        }
      } else {
        await dispatch(clearAuthStatesWithoutBrands());
        if (history) {
          history.push("/auth/login");
        }
      }
    });
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    uid: null,
    user: null,
    brandDetails: null,
    offerDetails: null,
    isGettingBrandDetails: false,
    signUpStartedAt: "",
    memberId: "",
    isLoggedInByBrand: false,
    isGettingSingleBrand: false,
    isGettingSingleOffer: false,

    signupDetails: {
      primaryEmail: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      industrySector: "",
      verified: "no",
      gender: "",
      userType: "member",
      secondaryEmail: "",
      dateVerified: "",
      verificationMethod: "",
      dateRegistered: "",
      emailOptIn: "yes",
      dateEmailOptUpdated: "",
      referrerSource: "",
    },
  },
  reducers: {
    login: (state, action) => {},
    setGettingBrandsLoading: (state, action) => {
      state.isGettingBrandDetails = action.payload;
    },
    setGetSingleBrandLoading: (state, action) => {
      state.isGettingSingleBrand = action.payload;
    },
    setGetSingleOfferLoading: (state, action) => {
      state.isGettingSingleOffer = action.payload;
    },

    setBrandDetails: (state, { type, payload }) => {
      state.brandDetails = payload;
    },
    setOfferDetails: (state, { type, payload }) => {
      state.offerDetails = payload;
    },
    setSignUpStartedAt: (state, { type, payload }) => {
      state.signUpStartedAt = payload;
    },
    setMemberId: (state, { type, payload }) => {
      state.memberId = payload;
    },
    onSignUpChange: (state, { type, payload }) => {
      state.signupDetails = {
        ...state.signupDetails,
        [payload.key]: payload.value,
      };
    },

    setUser: (state, { type, payload }) => {
      state.user = payload;
      // state.uid = payload.id;
    },

    setUID: (state, { type, payload }) => {
      state.uid = payload;
    },
    setLoggedByBrand: (state, { type, payload }) => {
      state.isLoggedInByBrand = payload;
    },

    clearAuthState: (state, { type, payload }) => {
      state.signupDetails = {
        primaryEmail: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        industrySector: "",
        verified: "no",
        gender: "",
        userType: "member",
        secondaryEmail: "",
        dateVerified: "",
        verificationMethod: "",
        dateRegistered: "",
        emailOptIn: "yes",
        dateEmailOptUpdated: "",
        referrerSource: "",
      };
      state.uid = null;
      state.user = null;
      state.brandDetails = {};
      state.offerDetails = {};
      state.isGettingBrandDetails = false;
      state.signUpStartedAt = "";
      state.memberId = "";
      state.isLoggedInByBrand = false;
    },
    clearAuthStatesWithoutBrands: (state, { type, payload }) => {
      state.signupDetails = {
        primaryEmail: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        industrySector: "",
        verified: "no",
        gender: "",
        userType: "member",
        secondaryEmail: "",
        dateVerified: "",
        verificationMethod: "",
        dateRegistered: "",
        emailOptIn: "yes",
        dateEmailOptUpdated: "",
        referrerSource: "",
      };
      state.uid = null;
      state.user = null;
      // state.brandDetails = null;
      // state.offerDetails = null;
      state.isGettingBrandDetails = false;
      state.signUpStartedAt = "";
      state.memberId = "";
      state.isLoggedInByBrand = false;
    },
  },
});

export const {
  login,
  setBrandDetails,
  setGettingBrandsLoading,
  setOfferDetails,
  onSignUpChange,
  clearAuthState,
  setSignUpStartedAt,
  setMemberId,
  setUser,
  setUID,
  clearAuthStatesWithoutBrands,
  setLoggedByBrand,
  setGetSingleBrandLoading,
  setGetSingleOfferLoading,
} = authSlice.actions;
export default authSlice.reducer;
