import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import SiteInput from "../custom/SiteInput";
import SiteButton from "../custom/SiteButton";
import { useDispatch, useSelector } from "react-redux";
import { RxCrossCircled } from "react-icons/rx";
import { updatePassword } from "../../store/main/userSlice";

const ResetPasswordModal = ({ isOpen, toggle }) => {
  const [updatePasswordDetails, setUpdatePasswordDetails] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const { myAccountDetails } = useSelector((state) => state.globalContent);

  const [errorMessage, setErrorMessage] = useState("");

  const [successMessage, setSuccessMessage] = useState("");

  const clearErrorMessage = () => {
    if (errorMessage != "") {
      setErrorMessage("");
    }
  };

  const { uid, user } = useSelector((state) => state.auth);
  const { isUpdatingPassword } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleInfoChange = (e) => {
    setUpdatePasswordDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    clearErrorMessage();
  };

  const handleToggle = () => {
    setUpdatePasswordDetails({
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    });
    clearErrorMessage();
    toggle();
  };

  const handlePasswordUpdate = (e) => {
    const regex = /^(?=.*[A-Z])(?=.*[^A-Za-z0-9]).{6,}$/;
    if (!regex.test(updatePasswordDetails?.newPassword)) {
      setErrorMessage(
        "New password must be at least 6 characters long, contain at least one capital letter, and at least one special character."
      );
      // setTimeout(() => {
      //   setErrorMessage("");
      // }, 3000);
    } else {
      dispatch(
        updatePassword(
          updatePasswordDetails,
          (message) => {
            setSuccessMessage(message);
            setTimeout(() => {
              setSuccessMessage("");
              handleToggle();
            }, 3000);
          },
          (message) => {
            setErrorMessage(message);
            // setTimeout(() => {
            //   setErrorMessage("");
            // }, 3000);
          }
        )
      );
    }
  };
  return (
    <Modal
      className="custom-modal max-w-[440px]"
      isOpen={isOpen}
      toggle={() => {
        handleToggle();
      }}
      centered
    >
      <ModalHeader>
        <div className="d-flex justify-content-between w-100">
          <h2>Reset Password</h2>
          <RxCrossCircled
            color="grey"
            size={20}
            className="pointer"
            onClick={() => {
              handleToggle();
            }}
          />
        </div>
      </ModalHeader>

      <ModalBody>
        <div className="flex flex-col ">
          <p className="input_label ">Old Password</p>
          <SiteInput
            type="password"
            value={updatePasswordDetails?.oldPassword}
            className="site_input mt-2 "
            placeholder="old password"
            name="oldPassword"
            onChange={(event) => handleInfoChange(event)}
            required
          />
        </div>
        <div className="flex flex-col mt-[16px]">
          <p className="input_label ">New Password</p>
          <SiteInput
            type="password"
            value={updatePasswordDetails?.newPassword}
            className="site_input mt-2 "
            placeholder="new password"
            name="newPassword"
            onChange={(event) => handleInfoChange(event)}
            required
          />
        </div>
        <div className="flex flex-col mt-[16px] ">
          <p className="input_label">Confirm New Password</p>
          <SiteInput
            type="password"
            value={updatePasswordDetails?.confirmNewPassword}
            className="site_input mt-2 "
            placeholder="confirm new password"
            name="confirmNewPassword"
            onChange={(event) => handleInfoChange(event)}
            required
          />
        </div>

        {errorMessage != "" && (
          <p className="error_text mt-4">{errorMessage}</p>
        )}
        {successMessage != "" && (
          <p className="text-[#25B6AB] mt-4 text-center fs--20">
            {successMessage}
          </p>
        )}

        <SiteButton
          pill
          block
          className={"mt-4"}
          disabled={
            updatePasswordDetails?.oldPassword == "" ||
            updatePasswordDetails?.newPassword == "" ||
            updatePasswordDetails?.confirmNewPassword == "" ||
            updatePasswordDetails?.newPassword !=
              updatePasswordDetails?.confirmNewPassword ||
            isUpdatingPassword
          }
          onClick={handlePasswordUpdate}
        >
          {isUpdatingPassword ? (
            <Spinner />
          ) : (
            <>
              {myAccountDetails?.updateButtonText
                ? myAccountDetails?.updateButtonText
                : "Update"}
            </>
          )}
        </SiteButton>
      </ModalBody>
    </Modal>
  );
};

export default ResetPasswordModal;
