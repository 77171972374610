import { useEffect, useState } from "react";
import SiteInput from "../../../components/custom/SiteInput";
import SiteButton from "../../../components/custom/SiteButton";
import { useHistory } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { onSignUpChange, signOut } from "../../../store/main/authSlice";
import firebase from "../../../config/firebase";
import { checkEmailAlreadyExists } from "../../../store/main/appSlice";
import { Spinner } from "reactstrap";
import { getGlobalContent } from "../../../store/main/globalContentSlice";

const SignUp = () => {
  const history = useHistory();
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const { signupDetails, brandDetails, offerDetails } = useSelector(
    (state) => state.auth
  );
  const { isCheckingEmail } = useSelector((state) => state.app);
  const { joinFree } = useSelector((state) => state.globalContent);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   email.match(emailRegex) ? setEmailValid(true) : setEmailValid(false);
  //   password === confirmPassword && password && confirmPassword
  //     ? setConfirmPasswordValid(true)
  //     : setConfirmPasswordValid(false);
  // }, [email, confirmPassword, password]);
  useEffect(() => {
    signupDetails?.primaryEmail.match(emailRegex)
      ? setEmailValid(true)
      : setEmailValid(false);
    signupDetails?.password === signupDetails?.confirmPassword &&
    signupDetails?.password &&
    signupDetails?.confirmPassword
      ? setConfirmPasswordValid(true)
      : setConfirmPasswordValid(false);
  }, [signupDetails]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const regex = /^(?=.*[A-Z])(?=.*[^A-Za-z0-9]).{6,}$/;

    let redirectInfo = {
      login: "/auth/login",
      signup: "/auth/signup",
      manualReview: "/auth/verify/manualreview",
      signupDetails: "/auth/signup/details",
    };

    if (
      offerDetails &&
      brandDetails &&
      Object.keys(offerDetails).length > 0 &&
      Object.keys(brandDetails).length > 0
    ) {
      redirectInfo = {
        login: `/auth/login/${brandDetails?.slug}/offerID=${offerDetails?.id}`,
        signup: `/auth/signup/${brandDetails?.slug}/offerID=${offerDetails?.id}`,
        manualReview: `/auth/verify/manualreview/${brandDetails?.slug}/offerID=${offerDetails?.id}`,
        signupDetails: `/auth/signup/details/${brandDetails?.slug}/offerID=${offerDetails?.id}`,
      };
    }

    if (!regex.test(signupDetails?.password)) {
      if (joinFree?.passwordMismatchErrorText) {
        setErrorMessage(joinFree.passwordMismatchErrorText);
      } else {
        setErrorMessage(
          "Password must be at least 6 characters long, contain at least one capital letter, and at least one special character."
        );
      }

      // setTimeout(() => {
      //   setErrorMessage("");
      // }, 3000);
    } else {
      dispatch(
        checkEmailAlreadyExists(
          signupDetails,
          redirectInfo,
          history,
          () => {},
          (message, redirect) => {
            setErrorMessage(message);
            // setTimeout(() => {
            //   setErrorMessage("");
            //   if (redirect) {
            //     history.push(redirect);
            //   }
            // }, 3000);
          }
        )
      );
    }
  };

  const clearErrorMessage = () => {
    if (errorMessage != "") {
      setErrorMessage("");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (Object.keys(joinFree).length == 0) {
      dispatch(getGlobalContent("joinFree"));
    }
    dispatch(signOut());
  }, []);

  return (
    <div className="signup">
      <div>
        <h2 className="signup__title global_header">
          {joinFree?.joinTitle ? joinFree.joinTitle : "Join Free"}{" "}
        </h2>
        <p className="signup__subtitle body_text">
          {joinFree?.joinText
            ? joinFree.joinText
            : "The membership platform on a mission to source and share the best deals for key workers."}
        </p>
      </div>
      <form className="signup__signupForm">
        <SiteInput
          className="mt-[22px]  md:mt-[27px] px-3  font-[400] "
          value={signupDetails?.primaryEmail}
          placeholder="email"
          name="primaryEmail"
          onChange={(event) => {
            clearErrorMessage();

            dispatch(
              onSignUpChange({
                key: event.target.name,
                value: event.target.value,
              })
            );
          }}
          required
        />
        <div className="mt-[22px]  md:mt-[27px]">
          <SiteInput
            className=" px-3  font-[400] "
            type="password"
            value={signupDetails?.password}
            name="password"
            placeholder="password"
            onChange={(event) => {
              clearErrorMessage();

              dispatch(
                onSignUpChange({
                  key: event.target.name,
                  value: event.target.value,
                })
              );
            }}
            required
          />
        </div>
        <div className="mt-[22px]  md:mt-[27px]">
          <SiteInput
            className=" px-3  font-[400] "
            type="password"
            value={signupDetails?.confirmPassword}
            name="confirmPassword"
            placeholder="confirm password"
            onChange={(event) => {
              clearErrorMessage();

              dispatch(
                onSignUpChange({
                  key: event.target.name,
                  value: event.target.value,
                })
              );
            }}
            required
          />
        </div>
        {errorMessage != "" && (
          <p className="error_text mt-[22px]  md:mt-[27px]">{errorMessage}</p>
        )}

        <SiteButton
          disabled={!emailValid || !confirmPasswordValid || isCheckingEmail}
          pill
          block
          className="mt-[22px]  md:mt-[27px] cursor-pointer "
          onClick={handleSubmit}
        >
          {isCheckingEmail ? (
            <Spinner />
          ) : (
            <>
              {" "}
              {joinFree?.submitButtonText
                ? joinFree.submitButtonText
                : "Submit"}
            </>
          )}
        </SiteButton>
      </form>
      <div className="signup__links mt-[22px]  md:mt-[27px]">
        <p className="signup__links__privacylinks terms_conditions">
          {joinFree?.termsConditionsText
            ? joinFree?.termsConditionsText
            : "By clicking Submit you agree to the website"}
          <a
            href={`${
              joinFree?.privacyPolicyLink
                ? joinFree?.privacyPolicyLink
                : "https://www.wearekii.co.uk/privacy-policy"
            } `}
            target="_blank"
          >
            Privacy Policy
          </a>
          ,
          <a
            href={`${
              joinFree?.termsOfServiceLink
                ? joinFree?.termsOfServiceLink
                : "https://www.wearekii.co.uk/terms-of-service"
            } `}
            target="_blank"
          >
            {/* {joinFree?.termsOfServiceLink
              ? joinFree.termsOfServiceLink
              : "Terms of Service"} */}
            Terms of Service
          </a>
          and
          <a
            href={`${
              joinFree?.cookiePolicyLink
                ? joinFree?.cookiePolicyLink
                : "https://www.wearekii.co.uk/cookie-policy"
            } `}
            target="_blank"
          >
            {/* {joinFree?.cookiePolicyLink
              ? joinFree.cookiePolicyLink
              : "Cookie Policy"} */}
            Cookie Policy
          </a>
          .
        </p>

        <p className=" mt-[22px]  md:mt-[27px] terms_conditions text-center signup__links__privacylinks ">
          Already have an account?{" "}
          <span
            className="text-primary underline cursor-pointer"
            onClick={() => {
              if (brandDetails?.id && offerDetails?.id) {
                history.push(
                  `/auth/login/${brandDetails?.slug}/offerID=${offerDetails?.id}`
                );
              } else {
                history.push("/auth/login");
              }
            }}
          >
            Log in
          </span>
        </p>
      </div>
    </div>
  );
};

export default SignUp;
