import React from "react";
import logo from "../../assets/img/auth-images/lgLogo.svg";
import "../../assets/sass/authLayoutComp/footer.scss";

const Footer = () => {
  return (
    <footer className="footer rounded-[18px] rounded-t-[0px] ">
      <div className="d-flex flex-column items-center">
        <img
          src={logo}
          alt="logo "
          onClick={() => {
            window.open("https://www.wearekii.co.uk/");
          }}
          className="cursor-pointer"
        />
        <button className="support-btn">
          <a
            className="text-decoration-none"
            href="https://www.wearekii.co.uk/support"
            target="_blank"
          >
            Support
          </a>
        </button>
      </div>
    </footer>
  );
};

export default Footer;
