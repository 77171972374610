import Repository from "./Repository";

export default {
  sendVerificationCode(payload) {
    return Repository.post(`/api/v1/user/send-code`, payload);
  },
  checkCodeIsValid(payload) {
    return Repository.post(`/api/v1/user/verify-code`, payload);
  },
  verifyLicense(payload) {
    let formData = new FormData();
    formData.append("userId", payload.userId);
    if (payload.type) {
      formData.append("baseFile", payload.file);
    } else {
      formData.append("File", payload.file);
    }

    if (payload?.referrerSource) {
      formData.append("referrerSource", payload.referrerSource);
    }
    return Repository.post(`/verify-license`, formData);
  },

  updateUser(payload) {
    return Repository.put(`/api/v1/user`, payload);
  },
  verifyRecaptcha(payload) {
    return Repository.post(`/api/v1/user/verify-recaptcha`, payload);
  },
};
