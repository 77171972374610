// login
import SelectAuth from "../views/auth/login/SelectAuth";
import Login from "../views/auth/login/Login";
import Congratulations from "../views/auth/login/Congratulations";

// join
import SignUp from "../views/auth/register/SignUp";
import Details from "../views/auth/register/Details";

// verify
import Verify from "../views/auth/verify/Verify";
import VerifyWorkId from "../views/auth/verify/VerifyWorkId";
import VerifyReview from "../views/auth/register/VerifyReview";
import Verified from "../views/auth/verify/Verified";
import VerifyCode from "../views/auth/verify/VerifyCode";

// myAccount
import MyAccount from "../views/auth/MyAccount";
import VerifiedSince from "../views/auth/VerifiedSince";
import MyDetails from "../views/auth/MyDetails";

import ManualReview from "../views/auth/verify/ManualReview";
import ResetPassword from "../views/auth/login/ResetPassword";
import OfferExpires from "../views/auth/OfferExpires";
import PageNotFound from "../views/auth/PageNotFound";
import OfferInvalid from "../views/auth/OfferInvalid";
import BrandOptin from "../views/auth/login/BrandOptin";

let routes = [
  {
    path: "/",
    component: SelectAuth,
    layout: "selectAuth",
  },
  {
    // path: "/kii-main/*",
    path: "/:brandName/offerID=:offerID",
    component: SelectAuth,
    layout: "selectAuth",
  },
  {
    // path: "/kii-main/*",
    path: "/auth/offer-expired",
    component: OfferExpires,
    layout: "selectAuth",
  },
  {
    path: "/auth/invalid-offer",
    component: OfferInvalid,
    layout: "selectAuth",
  },
  {
    // path: "/kii-main/*",
    path: "/auth/not-found",
    component: PageNotFound,
    layout: "selectAuth",
  },

  {
    path: "/auth/login",
    component: Login,
    layout: "auth",
  },

  {
    path: "/auth/login/:brandName/offerID=:offerID",
    component: Login,
    layout: "auth",
  },

  {
    path: "/auth/forgot-password",
    component: ResetPassword,
    layout: "auth",
  },
  {
    path: "/auth/forgot-password/:brandName/offerID=:offerID",
    component: ResetPassword,
    layout: "auth",
  },
  {
    path: "/auth/login/congratulations/:brandName/offerID=:offerID",
    component: Congratulations,
    layout: "main",
  },
  {
    path: "/auth/login/brand-optin/:brandName/offerID=:offerID",
    component: BrandOptin,
    layout: "main",
  },

  {
    path: "/auth/signup",
    component: SignUp,
    layout: "auth",
  },
  {
    path: "/auth/signup/:brandName/offerID=:offerID",
    component: SignUp,
    layout: "auth",
  },
  {
    path: "/auth/signup/details",
    component: Details,
    layout: "auth",
  },
  {
    path: "/auth/signup/details/:brandName/offerID=:offerID",
    component: Details,
    layout: "auth",
  },
  {
    path: "/auth/verify",
    component: Verify,
    layout: "auth",
  },
  {
    path: "/auth/verify/:brandName/offerID=:offerID",
    component: Verify,
    layout: "auth",
  },

  {
    path: "/auth/verify/code",
    component: VerifyCode,
    layout: "auth",
  },

  {
    path: "/auth/verify/code/:brandName/offerID=:offerID",
    component: VerifyCode,
    layout: "auth",
  },

  {
    path: "/auth/verify/workid",
    component: VerifyWorkId,
    layout: "auth",
  },
  {
    path: "/auth/verify/workid/:brandName/offerID=:offerID",
    component: VerifyWorkId,
    layout: "auth",
  },

  {
    path: "/auth/verify/manualreview",
    component: ManualReview,
    layout: "auth",
  },
  {
    path: "/auth/verify/manualreview/:brandName/offerID=:offerID",
    component: ManualReview,
    layout: "auth",
  },
  {
    path: "/auth/verify/review",
    component: VerifyReview,
    layout: "auth",
  },
  {
    path: "/auth/verified",
    component: Verified,
    // layout: "auth",
    layout: "main",
  },
  {
    path: "/auth/account",
    component: MyAccount,
    layout: "main",
  },
  {
    path: "/auth/account/verified",
    component: VerifiedSince,
    layout: "main",
  },
  {
    path: "/auth/account/update",
    component: MyDetails,
    layout: "main",
  },
];
export default routes;
