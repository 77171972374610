import { createSlice } from "@reduxjs/toolkit";
import firebase from "../../config/firebase";
let globalContentCollection = firebase.firestore().collection("globalContent");

export const getGlobalContent = (page) => async (dispatch) => {
  dispatch(setGetGlobalContentLoading(true));
  globalContentCollection
    .where("pageName", "==", page)
    .onSnapshot(async (query) => {
      if (query.size > 0) {
        switch (page) {
          case "codeIssue":
            dispatch(
              setCodeIssueDetails({
                id: query.docs[0].id,
                ...query.docs[0].data(),
              })
            );
            break;
          case "verifiedPage":
            dispatch(
              setVerifiedDetails({
                id: query.docs[0].id,
                ...query.docs[0].data(),
              })
            );
            break;

          case "yourDetails":
            dispatch(
              setYourDetails({ id: query.docs[0].id, ...query.docs[0].data() })
            );
            break;
          case "offer":
            dispatch(
              setOffers({ id: query.docs[0].id, ...query.docs[0].data() })
            );
            break;
          case "joinFree":
            dispatch(
              setJoinFree({ id: query.docs[0].id, ...query.docs[0].data() })
            );
            break;
          case "myAccountDetails":
            dispatch(
              setMyAccountDetails({
                id: query.docs[0].id,
                ...query.docs[0].data(),
              })
            );
            break;
          case "login":
            dispatch(
              setLoginDetails({ id: query.docs[0].id, ...query.docs[0].data() })
            );
            break;
          case "emailVerify":
            dispatch(
              setEmailVerifyDetails({
                id: query.docs[0].id,
                ...query.docs[0].data(),
              })
            );
            break;
          case "imageVerify":
            dispatch(
              setImageVerifyDetails({
                id: query.docs[0].id,
                ...query.docs[0].data(),
              })
            );
            break;

          case "manualReview":
            dispatch(
              setManualReviewDetails({
                id: query.docs[0].id,
                ...query.docs[0].data(),
              })
            );
            break;
          case "otp":
            dispatch(
              setOtpDetails({
                id: query.docs[0].id,
                ...query.docs[0].data(),
              })
            );
            break;
          case "myAccountMenu":
            dispatch(
              setMyAccountMenuDetails({
                id: query.docs[0].id,
                ...query.docs[0].data(),
              })
            );
            break;
          case "defaultContent": {
            dispatch(
              setDefaultContent({
                id: query.docs[0].id,
                ...query.docs[0].data(),
              })
            );
          }

          default:
            break;
        }
      }
      setTimeout(() => {
        dispatch(setGetGlobalContentLoading(false));
      }, 500);
    });
};

const globalContentSlice = createSlice({
  name: "globalContent",
  initialState: {
    offers: {},
    joinFree: {},
    yourDetails: {},
    loginDetails: {},
    verifiedDetails: {},
    codeIssueDetails: {},
    myAccountDetails: {},
    emailVerifyDetails: {},
    imageVerifyDetails: {},
    manualReviewDetails: {},
    otpDetails: {},
    myAccountMenuDetails: {},
    defaultContent: {},
    isGettingGlobalContent: false,
  },
  reducers: {
    setOffers(state, { type, payload }) {
      state.offers = payload;
    },
    setJoinFree(state, { type, payload }) {
      state.joinFree = payload;
    },
    setYourDetails(state, { type, payload }) {
      state.yourDetails = payload;
    },
    setLoginDetails(state, { type, payload }) {
      state.loginDetails = payload;
    },
    setVerifiedDetails(state, { type, payload }) {
      state.verifiedDetails = payload;
    },
    setCodeIssueDetails(state, { type, payload }) {
      state.codeIssueDetails = payload;
    },
    setMyAccountDetails(state, { type, payload }) {
      state.myAccountDetails = payload;
    },
    setEmailVerifyDetails(state, { type, payload }) {
      state.emailVerifyDetails = payload;
    },
    setImageVerifyDetails(state, { type, payload }) {
      state.imageVerifyDetails = payload;
    },
    setManualReviewDetails(state, { type, payload }) {
      state.manualReviewDetails = payload;
    },
    setOtpDetails(state, { type, payload }) {
      state.otpDetails = payload;
    },
    setMyAccountMenuDetails(state, { type, payload }) {
      state.myAccountMenuDetails = payload;
    },
    setDefaultContent(state, { type, payload }) {
      state.defaultContent = payload;
    },
    setGetGlobalContentLoading(state, { type, payload }) {
      state.isGettingGlobalContent = payload;
    },
  },
});
export const {
  setOffers,
  setJoinFree,
  setYourDetails,
  setLoginDetails,
  setVerifiedDetails,
  setCodeIssueDetails,
  setMyAccountDetails,
  setEmailVerifyDetails,
  setImageVerifyDetails,
  setManualReviewDetails,
  setOtpDetails,
  setMyAccountMenuDetails,
  setDefaultContent,
  setGetGlobalContentLoading,
} = globalContentSlice.actions;
export default globalContentSlice.reducer;
