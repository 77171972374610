import { createSlice } from "@reduxjs/toolkit";
import firebase from "../../config/firebase";
import moment from "moment";
let optinLogsCollection = firebase.firestore().collection("optinLogs");

export const AddOptinLog =
  (payload, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      console.log("Hamza test 123 optin logs action", payload);
      const res = await optinLogsCollection.add({
        ...payload,
        createdAt: firebase.firestore.Timestamp.now(),
      });
      if (res) {
        onSuccess();
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      if (error?.response?.data?.message) {
        onError(error?.response?.data?.message);
      } else {
        onError(error?.response?.data?.error);
      }
    }
  };

export const getOptinLogs = () => (dispatch) => {
  optinLogsCollection.onSnapshot(async (query) => {
    let temp = [];
    for (let doc of query.docs) {
      temp.push({ id: doc.id, ...doc.data() });
    }
    dispatch(setOptinLogs(temp));
  });
};

export const getOptinLogByUserBrandOffer =
  (userId, brandId, offerId) => async (dispatch) => {
    try {
      dispatch(setIsGettingOptinLog(true));

      const querySnapshot = await optinLogsCollection
        .where("userId", "==", userId)
        .where("brand", "==", brandId)
        .where("offerId", "==", offerId)
        .get();

      if (!querySnapshot.empty) {
        const optinLog = querySnapshot.docs[0].data();
        // console.log("Optin log found:", optinLog);
        dispatch(setOptinLog(optinLog));
        dispatch(setIsOptinLog(true));
      } else {
        // console.log("No optin log found for the given user, brand, and offer.");
        dispatch(setOptinLog(null));
        dispatch(setIsOptinLog(false));
      }

      dispatch(setIsGettingOptinLog(false));
    } catch (error) {
      console.error("Error fetching optin log:", error);
      dispatch(setIsGettingOptinLog(false));
    }
  };

const optinLogSlice = createSlice({
  name: "optinLog",
  initialState: {
    loading: false,
    isGettingOptinLog: false,
    optinLogs: [],
    optinLog: {},
    isOptinLog: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setOptinLogs: (state, action) => {
      state.optinLogs = action.payload;
    },
    setIsGettingOptinLog: (state, action) => {
      state.isGettingOptinLog = action.payload;
    },
    setOptinLog: (state, action) => {
      state.optinLog = action.payload;
    },
    setIsOptinLog: (state, action) => {
      state.isOptinLog = action.payload;
    },
  },
});

export const {
  setLoading,
  setOptinLogs,
  setOptinLog,
  setIsOptinLog,
  setIsGettingOptinLog,
} = optinLogSlice.actions;
export default optinLogSlice.reducer;
