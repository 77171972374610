import "../../assets/sass/authPages/selectAuth.scss";
import { useDispatch } from "react-redux";

import { useEffect } from "react";

import InvalidOffer from "../../components/authLayoutComponents/InvalidOffer";

const OfferExpires = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="select-auth">
      <InvalidOffer title="Offer Expired" />
    </div>
  );
};

export default OfferExpires;
