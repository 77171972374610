import { useEffect, useState } from "react";
import Footer from "../../components/authLayoutComponents/Footer";
import leftArrow from "../../assets/img/icons/leftArrow.png";
import logo from "../../assets/img/auth-images/lgLogo.svg";
import SiteInput from "../../components/custom/SiteInput";
import SiteButton from "../../components/custom/SiteButton";
import SiteSelect from "../../components/custom/SiteSelect";

import Loading from "../../components/custom/Loading";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getIndustrySectorDropdown,
  getPronounDropdown,
} from "../../store/main/appSlice";
import { getGlobalContent } from "../../store/main/globalContentSlice";
import {
  getUserDetails,
  resetPassword,
  updateUserDetails,
} from "../../store/main/userSlice";
import moment from "moment";
import { Spinner } from "reactstrap";
import ResetPasswordModal from "../../components/Modals/ResetPasswordModal";

const MyDetails = () => {
  const { user, uid } = useSelector((state) => state.auth);
  const history = useHistory();

  const [industry, setIndustry] = useState("");
  const [Pronoun, setPronoun] = useState("");
  const { pronounDropdown, industrySectorDropdown } = useSelector(
    (state) => state.app
  );

  const [errorMessage, setErrorMessage] = useState("");
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
  });
  const [successMessage, setSuccessMessage] = useState("");
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const [resetModal, setResetModal] = useState(false);
  const resetToggle = () => {
    setResetModal(!resetModal);
  };

  const { userDetails, isGettingUserDetails, isUpdatingDetails } = useSelector(
    (state) => state.user
  );

  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    emailOptIn: false,
    primaryEmail: "",
  });

  const { myAccountDetails } = useSelector((state) => state.globalContent);
  const dispatch = useDispatch();
  const handleInfoChange = (e) => {
    setUserInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    clearErrorMessages();
  };

  const clearErrorMessages = () => {
    if (errorMessage != "") {
      setErrorMessage("");
    }
    if (errors?.firstName || errors?.lastName) {
      setErrors({
        firstName: false,
        lastName: false,
      });
    }
  };

  const industrySelect = (data) => {
    setIndustry(data);
  };

  const pronounSelect = (data) => {
    setPronoun(data);
    clearErrorMessages();
  };

  const handleback = () => {
    history.push("/auth/account");
  };

  useEffect(() => {
    if (pronounDropdown?.length == 0) {
      dispatch(getPronounDropdown());
    }
    if (industrySectorDropdown?.length == 0) {
      dispatch(getIndustrySectorDropdown());
    }

    if (Object.keys(myAccountDetails).length == 0) {
      dispatch(getGlobalContent("myAccountDetails"));
    }

    if (uid) {
      dispatch(getUserDetails(uid));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(userDetails).length > 0) {
      setUserInfo((prev) => ({
        ...prev,
        emailOptIn: userDetails?.emailOptIn == "no" ? false : true,
        firstName: userDetails?.firstName,
        lastName: userDetails?.lastName,
        primaryEmail: userDetails?.primaryEmail,
      }));
      setPronoun(userDetails?.gender);
      setIndustry(userDetails?.industrySector);
    } else {
      setUserInfo({
        firstName: "",
        lastName: "",
        emailOptIn: false,
        primaryEmail: "",
      });
    }
  }, [userDetails]);

  return (
    <div className="flex w-full h-full  border-primary justify-center items-center ">
      <div className="relative mt-10   max-w-[440px]  ">
        <div className="absolute -top-[45px] md:-top-[60px] left-0">
          <img
            src={leftArrow}
            className="back-arrow cursor-pointer h-[38px] w-[38px]"
            onClick={handleback}
            alt=""
          />
        </div>
        <div className="main-app my_details_screen  shadow-lg rounded-[18px]">
          {isGettingUserDetails && <Loading />}
          <div className="header">
            <img src={logo} alt="logo" className="h-[58px] w-[71px]" />
          </div>
          <h2 className="title global_header">
            {myAccountDetails?.myAccountDetailsTitle
              ? myAccountDetails?.myAccountDetailsTitle
              : "My Details"}
          </h2>
          <p className="sub_title body_text">
            {myAccountDetails?.myAccountDetailsText
              ? myAccountDetails?.myAccountDetailsText
              : " To change your account details simply edit and click update"}
          </p>
          <div className="flex flex-col mt-[22px]">
            <p className="input_label body_text">Email</p>
            <SiteInput
              type="email"
              value={userInfo?.primaryEmail}
              className="site_input mt-2 "
              placeholder="Enter your email"
              name="primaryEmail"
              onChange={(event) => handleInfoChange(event)}
              required
            />
          </div>
          <SiteButton
            pill
            block
            outline
            className="mt-[22px]  md:mt-[27px]"
            // onClick={() => dispatch(resetPassword(userDetails?.primaryEmail))}
            onClick={() => {
              resetToggle();
              clearErrorMessages();
            }}
          >
            Reset password
          </SiteButton>
          <div className="flex flex-col  mt-[22px]">
            <p className="input_label body_text">First Name</p>
            <SiteInput
              type="text"
              name="firstName"
              className="site_input mt-2 mb-3"
              value={userInfo?.firstName}
              placeholder="Enter your first name"
              onChange={(e) => handleInfoChange(e)}
              required
            />
            {errors?.firstName && (
              <p className="pl-2 error_text text-start ">
                at least one character entered
              </p>
            )}
          </div>
          <div className="flex flex-col mt-[22px]">
            <p className="input_label body_text">Last Name</p>
            <SiteInput
              type="text"
              name="lastName"
              className="site_input mt-2 mb-3"
              value={userInfo?.lastName}
              placeholder="Enter your last name"
              onChange={(e) => handleInfoChange(e)}
              required
            />{" "}
            {errors?.lastName && (
              <p className="pl-2 error_text text-start ">
                at least one character entered
              </p>
            )}
          </div>
          <div className="flex flex-col mt-[22px]">
            <p className="input_label body_text">Industry Sector</p>

            <SiteSelect
              placeholder="Industry sector"
              // options={industryValues}
              options={industrySectorDropdown}
              onOptionSelect={industrySelect}
              selectedOption=""
              className="mt-[5px]  md:mt-[8px] !bg-[#d3d3d366] "
              type="industry"
              update={true}
              value={industry}
            />
          </div>
          <div className="flex flex-col mt-[22px]">
            <p className="input_label body_text mt-3">Pronouns</p>

            <SiteSelect
              placeholder="Pronouns"
              options={pronounDropdown}
              onOptionSelect={pronounSelect}
              selectedOption=""
              type="pronoun"
              className="mt-[5px]  md:mt-[8px]"
              update={true}
              value={Pronoun}
            />
          </div>
          <p className="sub_title text-left body_text mt-10 px-2">
            {myAccountDetails?.optInText
              ? myAccountDetails.optInText
              : "Receive the latest deals and offers from kii straight to your inbox"}
          </p>
          <ul className="checkbox-list">
            <li>
              <input
                id="yes"
                type="checkbox"
                checked={userInfo?.emailOptIn == true}
                onClick={(e) => {
                  setUserInfo((prev) => ({ ...prev, emailOptIn: true }));
                  clearErrorMessages();
                }}
              />
              <label htmlFor="yes" className="opt_text">
                {myAccountDetails?.optInTextCheckboxYesOptions
                  ? myAccountDetails.optInTextCheckboxYesOptions
                  : "Yes Please"}
              </label>
            </li>
            <li>
              <input
                id="yes"
                type="checkbox"
                checked={userInfo?.emailOptIn == false}
                onClick={(e) => {
                  setUserInfo((prev) => ({ ...prev, emailOptIn: false }));
                  clearErrorMessages();
                }}
              />
              <label htmlFor="yes" className="opt_text">
                {myAccountDetails?.optInTextCheckboxNoOptions
                  ? myAccountDetails.optInTextCheckboxNoOptions
                  : " No Thanks"}
              </label>
            </li>
          </ul>
          {errorMessage != "" && (
            <p className="error_text mt-2">{errorMessage}</p>
          )}

          {successMessage != "" && (
            <p className="text-[#25B6AB] mt-4 text-center fs--20">
              {successMessage}
            </p>
          )}

          <SiteButton
            pill
            block
            className={"mt-2"}
            disabled={
              isUpdatingDetails ||
              userInfo.firstName == "" ||
              userInfo.lastName == "" ||
              userInfo.firstName.trim() === "" ||
              userInfo.lastName.trim() === "" ||
              userInfo?.primaryEmail == "" ||
              !userInfo?.primaryEmail.match(emailRegex)
            }
            onClick={() => {
              // let nameRegex = /^(?=.*[a-zA-Z])(?!.*[ \-]$)[a-zA-Z\s\-]+$/;
              let nameRegex = /^(?=.*[a-zA-Z])[\s\S]*[a-zA-Z\s-]+[\s\S]*$/;

              if (
                nameRegex.test(userInfo?.firstName) &&
                nameRegex.test(userInfo?.lastName)
              ) {
                let obj = {
                  ...userInfo,
                  emailOptIn: userInfo?.emailOptIn == true ? "yes" : "no",
                  userId: uid,
                  gender: Pronoun,
                };
                dispatch(
                  updateUserDetails(
                    uid,
                    obj,
                    () => {
                      setSuccessMessage("Details updated successfully!");
                      setTimeout(() => {
                        setSuccessMessage("");
                      }, 3000);
                    },
                    (message) => {
                      if (message) {
                        setErrorMessage(message);
                        setTimeout(() => {
                          setErrorMessage("");
                        }, 3000);
                      }
                    }
                  )
                );
              } else {
                if (!nameRegex.test(userInfo?.firstName)) {
                  setErrors((prev) => ({ ...prev, firstName: true }));
                }
                if (!nameRegex.test(userInfo?.lastName)) {
                  setErrors((prev) => ({ ...prev, lastName: true }));
                }
              }
            }}
          >
            {isUpdatingDetails ? (
              <Spinner />
            ) : (
              <>
                {myAccountDetails?.updateButtonText
                  ? myAccountDetails?.updateButtonText
                  : "Update"}
              </>
            )}
          </SiteButton>
          <p className="sub_title mb-0 body_text mt-[22px] md:mt-[27px]">
            Verified Member since:
          </p>
          <p className="text-center font-[300] fs--20">
            {userDetails?.dateVerified
              ? moment(user?.dateVerified, "M/D/YYYY, h:mm:ss A").format(
                  "DD/MM/YYYY"
                )
              : "dd/mm/yy"}
          </p>
          <p className="text-center font-[300] mt-3 fs--20"> Member ID:</p>
          <div>
            <h5 className="text-[#545B7D] text-center mt-[22px] md:mt-[27px] fs--20 fw--500 mb-4">
              {userDetails?.id}
            </h5>
          </div>
          <Footer />
        </div>
      </div>

      <ResetPasswordModal isOpen={resetModal} toggle={resetToggle} />
    </div>
  );
};

export default MyDetails;
