const timeCheck = (currentDateTime, previousDateTime) => {
  // Perform some logic with the param
  const diffInMinutes = currentDateTime.diff(previousDateTime, "minutes");

  if (diffInMinutes > 30) {
    // Perform your actions here when the difference is greater than 30 minutes
    return false;
  } else {
    return true;
  }
};

export default timeCheck;
