import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getOfferDetails, signOut } from "../store/main/authSlice";

const Main = (props) => {
  const { uid, offerDetails, brandDetails } = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();

  const { brandName, offerID } = useParams();

  const location = useLocation();
  useEffect(() => {
    const pathname = location.pathname;
    window.parent.postMessage({ pathname: pathname }, "*");

    // const initialHeight = 1200;
    // window.parent.postMessage({ height: initialHeight }, "*");

    const timeoutId = setTimeout(() => {
      const height = document.body.scrollHeight;
      window.parent.postMessage({ height: height }, "*");
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [location.pathname]);

  useEffect(() => {
    if (brandName && offerID) {
      dispatch(
        getOfferDetails(
          brandName,
          offerID,
          history,
          () => {},
          (message) => {
            history.push("/auth/login");
            dispatch(signOut());
          }
        )
      );
    }
  }, [brandName, offerID]);

  useEffect(() => {
    const setDocumentTitle = () => {
      if (offerDetails?.id && brandDetails?.id) {
        document.title = `${brandDetails.brandName} | ${offerDetails.offerTitle} at kii`;
      } else {
        document.title = `kii`;
      }
    };

    setDocumentTitle();
  }, [offerDetails?.id, brandDetails?.id]);

  const history = useHistory();

  if (!uid) {
    if (offerDetails?.id && brandDetails?.id) {
      history.push(`/${brandDetails?.slug}/offerID=${offerDetails?.id}`);
    } else {
      history.push("/");
    }
  }
  return <>{props.children}</>;
};

export default Main;
