import SiteButton from "../../../components/custom/SiteButton";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  checkVerificationCode,
  clearAuthStatesWithoutBrands,
  sendVerificationCode,
  signOut,
} from "../../../store/main/authSlice";
import { Spinner } from "reactstrap";
import OtpInput from "react-otp-input";
import { getGlobalContent } from "../../../store/main/globalContentSlice";

const VerifyCode = () => {
  const history = useHistory();

  const { signupDetails, memberId, user, brandDetails, offerDetails } =
    useSelector((state) => state.auth);
  const { isOptinLog } = useSelector((state) => state.optinLog);
  const { isVerifying } = useSelector((state) => state.app);
  const { otpDetails } = useSelector((state) => state.globalContent);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const dispatch = useDispatch();

  const clearMessages = () => {
    setErrorMessage("");
    setisError(false);
    setSuccessMessage("");
  };

  const [inputStyle, setInputStyle] = useState({
    width: "47px",
    height: "41px",
    padding: 0,
    textAlign: "center",
    fontSize: "20px",
    border: "1.63077px solid #c9c9c9",
    borderRadius: "3.58974px",
    outline: "none",
  });

  const [otp, setOtp] = useState("");
  const [isError, setisError] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    let offerID = "";

    let payload = {
      code: Number(otp),
      email: signupDetails?.secondaryEmail,
      primaryEmail: user?.primaryEmail,
    };

    if (brandDetails?.id && offerDetails?.id) {
      payload = {
        ...payload,
        referrerSource: `/${brandDetails?.slug}/offerID=${offerDetails?.id}`,
      };
    }

    if (offerDetails && offerDetails.id) {
      offerID = offerDetails?.id;
    }

    // dispatch(
    //   checkVerificationCode(
    //     user?.id,
    //     payload,
    //     offerID,
    //     () => {
    //       if (
    //         offerDetails &&
    //         offerDetails?.id &&
    //         brandDetails &&
    //         brandDetails?.id
    //       ) {
    //         if (!offerDetails.optinInfo || !offerDetails.optinInfo.optinTitle) {
    //           history.push(
    //             `/auth/login/congratulations/${brandDetails?.slug}/offerID=${offerDetails?.id}`
    //           );
    //         } else {
    //           if (isOptinLog) {
    //             history.push(
    //               `/auth/login/congratulations/${brandDetails?.slug}/offerID=${offerDetails?.id}`
    //             );
    //           } else {
    //             history.push(
    //               `/auth/login/brand-optin/${brandDetails?.slug}/offerID=${offerDetails?.id}`
    //             );
    //           }
    //         }
    //       } else {
    //         history.push("/auth/verified");
    //       }
    //     },
    //     () => {
    //       setisError(true);
    //       // setTimeout(() => {
    //       //   setisError(false);
    //       // }, 3000);
    //     }
    //   )
    // );
    dispatch(
      checkVerificationCode(
        user?.id,
        payload,
        offerID,
        () => {
          if (
            offerDetails &&
            offerDetails?.id &&
            brandDetails &&
            brandDetails?.id
          ) {
            if (!offerDetails.optinInfo || !offerDetails.optinInfo.optinTitle) {
              history.push(
                `/auth/login/congratulations/${brandDetails?.slug}/offerID=${offerDetails?.id}`
              );
            } else {
              history.push(
                `/auth/login/brand-optin/${brandDetails?.slug}/offerID=${offerDetails?.id}`
              );
            }
          } else {
            history.push("/auth/verified");
          }
        },
        () => {
          setisError(true);
          // setTimeout(() => {
          //   setisError(false);
          // }, 3000);
        }
      )
    );
  };
  const handleOTPComplete = (val) => {
    setOtp(val);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleVerifyById = () => {
    if (
      offerDetails &&
      brandDetails &&
      Object.keys(offerDetails).length > 0 &&
      Object.keys(brandDetails).length > 0
    ) {
      history.push(
        `/auth/verify/workid/${brandDetails?.slug}/offerID=${offerDetails?.id}`
      );
    } else {
      history.push("/auth/verify/workid");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const changeBorder = () => {
    if (otp?.length >= 4) {
      setInputStyle((prev) => ({
        ...prev,
        border: "2px solid #25b6ab",
        background: "#25b6aa0d",
      }));
    } else {
      setInputStyle((prev) => ({
        ...prev,
        border: "1.63077px solid #c9c9c9",
        background: "",
      }));
    }
  };
  useEffect(() => {
    changeBorder();
  }, [otp]);

  useEffect(() => {
    if (isError) {
      setInputStyle((prev) => ({
        ...prev,
        border: "1.79487px solid red",
        background: "#fef3f2",
      }));
    } else {
      changeBorder();
    }
  }, [isError]);

  const isDetailsValid = () => {
    if (signupDetails?.workEmail == "" || !user?.id || user?.id == "") {
      if (
        brandDetails &&
        offerDetails &&
        brandDetails?.id &&
        offerDetails?.id
      ) {
        dispatch(clearAuthStatesWithoutBrands());
        history.push(
          `/auth/signup/${brandDetails?.slug}/offerID=${offerDetails?.id}`
        );
      } else {
        dispatch(signOut());
        history.push("/auth/signup");
      }
    }
  };
  const requestNewCode = (e) => {
    let payload = {
      email: signupDetails?.secondaryEmail,
    };
    setOtp("");
    clearMessages();
    if (brandDetails?.id && offerDetails?.id) {
      payload = {
        ...payload,
        referrerSource: `/${brandDetails?.slug}/offerID=${offerDetails?.id}`,
      };
    }

    dispatch(
      sendVerificationCode(
        payload,
        () => {
          setSuccessMessage("Verification code sent successfully!");
          setTimeout(() => {
            setSuccessMessage("");
          }, 3000);
        },
        (message) => {
          if (message) {
            setErrorMessage(message);
            // setTimeout(() => {
            //   setErrorMessage("");
            // }, 3000);
          }
        }
      )
    );
  };

  useEffect(() => {
    isDetailsValid();

    if (Object.keys(otpDetails).length == 0) {
      dispatch(getGlobalContent("otp"));
    }
  }, []);

  return (
    <div className="verifyCode">
      <div>
        <h2 className=" verifyCode__title global_header">
          {" "}
          {otpDetails?.verifyTitle ? otpDetails.verifyTitle : "Verify"}
        </h2>

        {otpDetails?.verifyText ? (
          <p className="verifyCode__subtitle body_text">
            {otpDetails?.verifyText}
          </p>
        ) : (
          <p className="verifyCode__subtitle body_text">
            We’ve sent a verification code to{" "}
            {signupDetails?.secondaryEmail
              ? signupDetails.secondaryEmail
              : "katy.smith@nhs.net"}
            . It should be with you within 5 minutes, remember to check your
            junk folder.
          </p>
        )}
      </div>
      <form className="verifyCode__form ">
        <div className="otp-input-container custom-otp">
          <OtpInput
            value={otp}
            onChange={(val) => {
              setOtp(val);
              clearMessages();
            }}
            numInputs={4}
            renderSeparator={<span></span>}
            renderInput={(props) => <input {...props} />}
            inputStyle={inputStyle}
            inputType={"tel"}
          />
        </div>

        {isError && (
          <>
            <p className="error_text mt-3">
              {otpDetails?.codeMismatchErrorText
                ? otpDetails.codeMismatchErrorText
                : "Code does not match"}
            </p>
            <p
              className="text-center cursor-pointer body_text text-[#25B6AB] underline mb-3"
              onClick={requestNewCode}
            >
              {otpDetails?.requestNewCodeTextlink
                ? otpDetails.requestNewCodeTextlink
                : "Request new code"}
            </p>
            <p className="text-secondary font-[500] body_text text-center my-4">
              {otpDetails?.verifyWithIDErrorText
                ? otpDetails.verifyWithIDErrorText
                : "Verify with work ID instead"}
            </p>
          </>
        )}

        {errorMessage != "" && (
          <p className="error_text mt-3">{errorMessage}</p>
        )}

        {successMessage != "" && (
          <p className="text-[#25B6AB] mt-3 text-center  body_text">
            {successMessage}
          </p>
        )}

        {!isError && (
          <>
            <SiteButton
              pill
              block
              className="my-2  cursor-pointer !mt-[22px] md:!mt-[27px] "
              onClick={handleSubmit}
              disabled={otp?.length < 4 || otp?.length < 4}
            >
              {isVerifying ? (
                <Spinner />
              ) : (
                <>
                  {otpDetails?.verifyButtonText
                    ? otpDetails?.verifyButtonText
                    : "Verify"}
                </>
              )}
            </SiteButton>

            <p className="error_text text-[#212529] mt-3">
              Didn’t receive a code?
            </p>
            <p
              className="text-center cursor-pointer body_text text-[#25B6AB] underline "
              onClick={requestNewCode}
            >
              {otpDetails?.requestNewCodeTextlink
                ? otpDetails.requestNewCodeTextlink
                : "Request new code"}
            </p>
          </>
        )}
      </form>
      {!isError && (
        <div className="verifyCode__access  ">
          <p className="verifyCode__access__data terms_conditions">
            {otpDetails?.verifyWithIDText
              ? otpDetails.verifyWithIDText
              : "Don’t have access to a work email on an accepted domain? No problem, you can verify by uploading a valid form of ID."}

            <a
              href={
                otpDetails?.acceptedFormsOfIDLink
                  ? otpDetails.acceptedFormsOfIDLink
                  : "https://www.wearekii.co.uk/verifyid"
              }
              target="_blank"
            >
              See &nbsp;
              <span>
                {otpDetails?.acceptedFormsOfIDTextlink
                  ? otpDetails.acceptedFormsOfIDTextlink
                  : "accepted forms of ID."}
              </span>
            </a>
          </p>
        </div>
      )}
      <SiteButton
        pill
        block
        outline
        onClick={handleVerifyById}
        className="my-2  cursor-pointer !mt-[22px] md:!mt-[27px] "
      >
        {otpDetails?.verifyWithIDButtonText
          ? otpDetails.verifyWithIDButtonText
          : "Verify with id"}
      </SiteButton>
    </div>
  );
};

export default VerifyCode;
