import React, { useEffect } from "react";
import SiteButton from "../../../components/custom/SiteButton";
import { useDispatch, useSelector } from "react-redux";
import { getGlobalContent } from "../../../store/main/globalContentSlice";
import {
  clearAuthStatesWithoutBrands,
  signOut,
} from "../../../store/main/authSlice";
import { Link, useHistory } from "react-router-dom";

const ManualReview = () => {
  const dispatch = useDispatch();

  const { manualReviewDetails } = useSelector((state) => state.globalContent);

  const { user, brandDetails, offerDetails } = useSelector(
    (state) => state.auth
  );

  const history = useHistory();

  const isDetailsValid = () => {
    if (user?.id != "" && user?.verified == "pending") {
    } else {
      if (
        brandDetails &&
        offerDetails &&
        brandDetails?.id &&
        offerDetails?.id
      ) {
        dispatch(clearAuthStatesWithoutBrands());
        history.push(
          `/auth/login/${brandDetails?.slug}/offerID=${offerDetails?.id}`
        );
      } else {
        dispatch(signOut());
        history.push("/auth/login");
      }
    }
  };
  const getPathDetails = () => {
    if (brandDetails && offerDetails && brandDetails?.id && offerDetails?.id) {
      return `/auth/verify/${brandDetails?.slug}/offerID=${offerDetails?.id}`;
    } else {
      return "/auth/verify";
    }
  };

  useEffect(() => {
    if (Object.keys(manualReviewDetails).length == 0) {
      dispatch(getGlobalContent("manualReview"));
    }
    isDetailsValid();
  }, []);

  const getPath = () => {
    if (brandDetails && offerDetails && brandDetails?.id && offerDetails?.id) {
      history.push(
        `/auth/verify/${brandDetails?.slug}/offerID=${offerDetails?.id}`
      );
    } else {
      history.push("/auth/verify");
    }
  };

  return (
    <div className="bg-[#fff] h-full p-4">
      <h2 className="global_header font-[500] text-center mb-4">
        {manualReviewDetails?.reviewTitle
          ? manualReviewDetails.reviewTitle
          : "Manual Review"}
      </h2>

      <p className="body_text font-[500] text-[#6E7680] text-center mb-4">
        {manualReviewDetails?.reviewText
          ? manualReviewDetails.reviewText
          : " Instant image verification has not been possible for the ID you submitted. Your ID has been submitted for manual review. Our team will contact you via email to let you know if your application is successful. All images are deleted within 48 hours."}
      </p>
      <p className="terms_conditions font-[500] text-[#6E7680] text-center mb-4">
        See &nbsp;
        <a
          href={
            manualReviewDetails?.acceptedFormsIDLink
              ? manualReviewDetails.acceptedFormsIDLink
              : "https://www.wearekii.co.uk/verifyid"
          }
          target="_blank"
          className="text-primary underline"
        >
          {manualReviewDetails?.acceptedFormsIDTextlink
            ? manualReviewDetails.acceptedFormsIDTextlink
            : "accepted forms of ID."}
        </a>
      </p>
      <SiteButton
        pill
        block
        className="mb-4"
        onClick={() => {
          if (brandDetails?.id && offerDetails?.id) {
            dispatch(clearAuthStatesWithoutBrands());
            history.push(`/${brandDetails?.slug}/offerID=${offerDetails?.id}`);
          } else {
            dispatch(clearAuthStatesWithoutBrands());
            history.push("/");
          }
        }}
      >
        {manualReviewDetails?.returnToSiteButtonText
          ? manualReviewDetails.returnToSiteButtonText
          : "Return"}{" "}
        {brandDetails?.brandName ? brandDetails?.brandName : "kii site"}
      </SiteButton>
      <hr className="mb-8" />
      <p className="terms_conditions font-[500] text-[#6E7680] text-center mb-4">
        {manualReviewDetails?.backToEmailVerifyText
          ? manualReviewDetails.backToEmailVerifyText
          : "Alternatively you may prefer to"}
        <br />
        <Link
          to={getPathDetails()}
          className="text-primary cursor-pointer underline"
        >
          {manualReviewDetails?.backToEmailVerifyButtonText
            ? manualReviewDetails.backToEmailVerifyButtonText
            : "Verify using your work email"}
        </Link>
      </p>
    </div>
  );
};

export default ManualReview;
