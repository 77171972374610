import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Import CSS here
// import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/style.scss";
import "./assets/sass/custom.scss";
import "./assets/sass/gradientBorder.scss";
import "./helpers/style.css";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

ReactDOM.render(
  <React.StrictMode>
    {/* <GoogleReCaptchaProvider reCaptchaKey="6LeJYmwnAAAAAIHnWUcSMcTjx8g_KeBVEKrbgFxd"> */}
    <ToastContainer />

    <App />
    {/* </GoogleReCaptchaProvider> */}
  </React.StrictMode>,
  document.getElementById("root")
);
