import offerIcon from "../../assets/img/auth-images/offerIcon.svg";
import "../../assets/sass/authPages/selectAuth.scss";

import { useEffect } from "react";

import SiteButton from "../../components/custom/SiteButton";

const PageNotFound = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="select-auth">
      <h2 className="page-title">Page Not Found </h2>
      <div className="offer-icon mt-3">
        <img
          src={offerIcon}
          className="w-[19px] h-[16px]"
          alt=""
          loading="lazy"
        />
      </div>
      <p className="page-not-found-description">
        Please refer to kii for the latest key worker offers{" "}
      </p>

      <SiteButton
        onClick={() => {
          window.open("https://www.wearekii.co.uk/");
        }}
        block
        pill
        className="lg:mb-[27px] mb-[22px]"
      >
        www.wearekii.co.uk
      </SiteButton>
    </div>
  );
};

export default PageNotFound;
