import React from "react";

import verifiedChip from "../../assets/img/auth-images/verifiedchip.svg";
import "../../assets/sass/authLayoutComp/header.scss";
import { useSelector } from "react-redux";
import defaultBrandLogo from "../../assets/img/defaultBrandLogo.png";
import defaultBrandImage from "../../assets/img/defaultBrandImage.png";
import forgotBg from "../../assets/img/forgot-bg.png";
import { getGlobalContent } from "../../store/main/globalContentSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const Header = () => {
  const { brandDetails } = useSelector((state) => state.auth);
  const pathname = window.location.pathname;
  const { defaultContent } = useSelector((state) => state.globalContent);

  const dispatch = useDispatch();

  useEffect(() => {
    if (defaultContent && Object.keys(defaultContent).length == 0) {
      dispatch(getGlobalContent("defaultContent"));
    }
  }, []);

  return (
    <header className="main-header rounded-t-[18px]">
      <div
        className="header"
        style={{
          backgroundImage: `url(${
            pathname.includes("forgot-password")
              ? forgotBg
              : brandDetails?.brandImage
              ? brandDetails?.brandImage
              : defaultContent?.defaultHeaderBackground
              ? defaultContent.defaultHeaderBackground
              : defaultBrandImage
          })`,
        }}
      >
        <div className="image-container shadow-sm">
          <img
            src={
              brandDetails?.brandLogo
                ? brandDetails?.brandLogo
                : defaultContent?.defaultKiiLogo
                ? defaultContent?.defaultKiiLogo
                : defaultBrandLogo
            }
            alt="JD"
            className=" h-[64px] w-[64px] object-contain"
          />
        </div>

        <img
          src={verifiedChip}
          alt="kii Logo"
          className="absolute bottom-[14px] md:bottom-[16px] right-[16px] w-[105px] md:w-[130.22px] "
        />
      </div>
    </header>
  );
};

export default Header;
