import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyBxxZBIweXniLz2VvHlA2wwqLazWfmKEjk",
  authDomain: "kii-admin.firebaseapp.com",
  projectId: "kii-admin",
  storageBucket: "kii-admin.appspot.com",
  messagingSenderId: "810936588366",
  appId: "1:810936588366:web:75d3f0ffe520fc23a18b1f",
  measurementId: "G-T8R5DYR1TK",
};
firebase.initializeApp(firebaseConfig);
export default firebase;
