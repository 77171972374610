import React from "react";
import leftArrow from "../../assets/img/icons/backArrow.svg";

const BackLink = ({ title }) => {
  // const handleGoBack = () => {
  //   window.location.href = "https://www.jdsports.co.uk";
  // };

  return (
    <div
      // onClick={handleGoBack}
      className="flex gap-2 items-center back_link_text font-[500] cursor-pointer text-primary"
    >
      <img src={leftArrow} alt="Back" /> {title}
    </div>
  );
};

export default BackLink;
