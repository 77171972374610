import React, { useEffect, useState, useRef } from "react";
import defaultImageIndustrySector from "../../assets/img/defaultImageIndustrySector.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation } from "react-router-dom";

const SiteSelect = (props) => {
  const {
    options,
    className,
    onOptionSelect,
    placeholder,
    selectedOption,

    type,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [Selected, setSelectedOption] = useState("");
  const [selectedIcon, setSelectedIcon] = useState("");
  const dropdownRef = useRef();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;
    window.parent.postMessage({ pathname: pathname }, "*");

    // const initialHeight = 1200;
    // window.parent.postMessage({ height: initialHeight }, "*");

    const timeoutId = setTimeout(() => {
      const height = document.body.scrollHeight;
      window.parent.postMessage({ height: height }, "*");
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [location.pathname, isOpen]);

  const handleOptionSelect = (option) => {
    if (type == "industry") {
      setSelectedOption(option?.option);
      onOptionSelect(option?.id);
      setSelectedIcon(option?.icon);
    } else if (type == "pronoun") {
      setSelectedOption(option?.pronounOption);
      onOptionSelect(option?.id);
      // setSelectedIcon(option.icon);
    } else {
      onOptionSelect(option.value);
      setSelectedIcon(option.icon);
    }
    setIsOpen(false);

    // Invoke the callback function with the selected option
  };

  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const getDetails = (id) => {
    let selectedDetails = options.find((opt) => opt.id == id);
    return selectedDetails;
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div className={`siteDropdown relative `} ref={dropdownRef}>
      <button
        disabled={
          props.type && props.type == "industry" && props.update ? true : false
        }
        onClick={(e) => {
          e.preventDefault();
          toggleDropdown();
        }}
        className={`px-3 bg-white focus:outline-primary lg:h-[68px] h-[56px] lg:max-w-[405px] max-w-[355px] w-full mx-auto flex ${className} justify-between text-secondary border-[2px] border-[#C9C9C9] ${
          isOpen && " border-[#C9C9C9] border-b-0"
        }  items-center	 ${
          isOpen
            ? "rounded-t-[35px] rounded-b-none border-[1.63px] border-primary"
            : "rounded-[80px]"
        }`}
      >
        {props.update ? (
          <>
            <span className="flex items-center  input_text">
              {/* {type == "industry" && getDetails(props.value)?.icon && (
                <img src={getDetails(props.value)?.icon} />
              )} */}
              {/* <span
                className={`d-flex align-items-center text-black  input_text  ${
                  type == "industry" ? "ml-4 " : ""
                }`}
              ></span> */}
              <span
                className={`d-flex align-items-center text-black  input_text }`}
              >
                {type == "industry"
                  ? getDetails(props.value)
                    ? getDetails(props.value).option
                    : placeholder
                    ? placeholder
                    : "Select an option"
                  : getDetails(props.value)
                  ? getDetails(props.value).pronounOption
                  : placeholder
                  ? placeholder
                  : "Select an option"}
              </span>
            </span>
          </>
        ) : (
          <>
            {selectedIcon && (
              <span className="flex items-center text-black  input_text">
                <img src={selectedIcon} />
                <span
                  className={` ${selectedIcon === "" ? "p-0" : "text_align "}`}
                >
                  {Selected}
                </span>
              </span>
            )}

            {type == "pronoun" ? (
              <span
                className={` input_text  ${Selected ? " text-black " : ""}`}
              >
                {Selected
                  ? Selected
                  : placeholder
                  ? placeholder
                  : "Select an option"}
              </span>
            ) : (
              !selectedIcon && (
                <span
                  className={`input_text  ${
                    selectedOption ? "text_align " : ""
                  }`}
                >
                  {selectedOption
                    ? selectedOption
                    : placeholder
                    ? placeholder
                    : "Select an option"}
                </span>
              )
            )}
          </>
        )}

        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 "
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path fillRule="evenodd" d="M10 12l-6-6h12l-6 6z" />
        </svg>
      </button>
      {isOpen && (
        <ul
          className={`lg:max-w-[405px]  max-w-[355px] w-full mx-auto z-10 top-full left-0 py-0 overflow-hidden bg-white border rounded-md shadow-md ${
            isOpen
              ? "border-primary !border-[2px]  border-solid border-t-none rounded-t-[0px] rounded-b-[30px]"
              : ""
          }`}
        >
          {options?.map((data, index) => {
            return (
              <li
                key={index}
                onClick={() => handleOptionSelect(data)}
                className={`p-[14px] h-[72px] md:h-[90px]  border input_text flex gap-4 items-center font-[300] py-3 hover:bg-gray-200 cursor-pointer ${
                  index === options.length - 1 && "rounded-b-[30px]"
                }`}
              >
                {type == "industry" && data?.icon !== "" && (
                  <LazyLoadImage
                    alt=""
                    height={30}
                    src={data.icon} // use normal <img> attributes as props
                    width={30}
                    placeholderSrc={defaultImageIndustrySector}
                  />
                  // <div className="h-[30px] w-[30px]">
                  //   <img src={data.icon} />
                  // </div>
                )}
                <h3 className="text-[17px] text-secondary font-[400]">
                  {" "}
                  {type && type == "industry"
                    ? data.option
                    : type && type == "pronoun"
                    ? data.pronounOption
                    : data.value}
                </h3>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default SiteSelect;
