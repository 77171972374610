import { useDispatch, useSelector } from "react-redux";
import SiteButton from "../../../components/custom/SiteButton";
import { useEffect } from "react";
import { getGlobalContent } from "../../../store/main/globalContentSlice";
import {
  clearAuthStatesWithoutBrands,
  signOut,
} from "../../../store/main/authSlice";
import { useHistory } from "react-router-dom";
import Header from "../../../components/authLayoutComponents/Header";

const Verified = () => {
  const { verifiedDetails } = useSelector((state) => state.globalContent);

  const { user, offerDetails, brandDetails } = useSelector(
    (state) => state.auth
  );

  const history = useHistory();

  const dispatch = useDispatch();

  const isDetailsValid = () => {
    if (user?.id != "" && user?.verified == "yes") {
    } else {
      if (
        brandDetails &&
        offerDetails &&
        brandDetails?.id &&
        offerDetails?.id
      ) {
        dispatch(clearAuthStatesWithoutBrands());
        history.push(
          `/auth/login/${brandDetails?.slug}/offerID=${offerDetails?.id}`
        );
      } else {
        dispatch(signOut());
        history.push("/auth/login");
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (Object.keys(verifiedDetails).length == 0) {
      dispatch(getGlobalContent("verifiedPage"));
    }
    isDetailsValid();
  }, []);
  return (
    <section className="main-app shadow-md rounded-[18px] relative min-w-[327px]">
      <Header />
      <div className="bg-[#fff] h-full p-4">
        <h2 className="global_header font-[500] text-center mb-4">
          {verifiedDetails?.congratulationsTitle
            ? verifiedDetails.congratulationsTitle
            : "Congratulations"}
        </h2>
        <p className="body_text font-[500] text-[#6E7680] text-center mb-4">
          {verifiedDetails?.congratulationsText
            ? verifiedDetails.congratulationsText
            : "Your account has been verified."}
        </p>
        <p className="body_text font-[500] text-[#6E7680] text-center mb-4">
          Your account has been verified. Check out some of the exclusive offers
          you can now access as a verified key worker on our Offers Page{" "}
        </p>
        <SiteButton
          pill
          block
          className="mb-4"
          onClick={(e) => {
            window.open("https://www.wearekii.co.uk/offers", "_blank");
          }}
        >
          {verifiedDetails?.offersButtonText
            ? verifiedDetails.offersButtonText
            : "Offers"}
        </SiteButton>
        <p className="body_text font-[500] text-[#6E7680] text-center mb-4">
          {verifiedDetails?.accessKiiIDText
            ? verifiedDetails.accessKiiIDText
            : "Access your kii ID anytime via my account"}
        </p>
        <SiteButton
          pill
          block
          outline
          onClick={(e) => history.push("/auth/account")}
        >
          {verifiedDetails?.myAccountButtonText
            ? verifiedDetails.myAccountButtonText
            : "My account"}
        </SiteButton>
      </div>
    </section>
  );
};

export default Verified;
