import { Provider } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Auth from "./layout/Auth";
import Main from "./layout/Main";
import routes from "./routes";
import store from "./store";
import SelectAuth from "./layout/SelectAuth";
import { useState } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

let persistor = persistStore(store);

function App() {
  return (
    <section>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Switch>
              {routes.map((route, index) => {
                switch (route.layout) {
                  case "main":
                    return (
                      <Route key={index} exact path={route.path}>
                        <Main>
                          <route.component />
                        </Main>
                      </Route>
                    );
                  case "auth":
                    return (
                      <Route key={index} exact path={route.path}>
                        <Auth>
                          <route.component />
                        </Auth>
                      </Route>
                    );
                  case "selectAuth":
                    return (
                      <Route key={index} exact path={route.path}>
                        <SelectAuth>
                          <route.component />
                        </SelectAuth>
                      </Route>
                    );

                  default:
                    return null;
                }
              })}
              <Redirect to="/" />
            </Switch>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </section>
  );
}

export default App;
