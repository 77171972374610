import React, { useEffect, useState } from "react";
import UploadFile from "../../../components/custom/UploadFile";
import SiteButton from "../../../components/custom/SiteButton";
import { RxCrossCircled } from "react-icons/rx";
import Webcam from "react-webcam";
import { useDispatch, useSelector } from "react-redux";
import { getGlobalContent } from "../../../store/main/globalContentSlice";
import {
  clearAuthStatesWithoutBrands,
  signOut,
  verifyMemberLicense,
} from "../../../store/main/authSlice";
import timeCheck from "../../../global.js/timeCheck";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";

const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: { exact: "environment" },
};

const VerifyWorkId = () => {
  const [file, setFile] = useState("");
  const [picture, setPicture] = useState(null);
  const webcamRef = React.useRef(null);
  const [takepicture, setTakepicture] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();

  const { imageVerifyDetails } = useSelector((state) => state.globalContent);
  const { user, signUpStartedAt, offerDetails, brandDetails } = useSelector(
    (state) => state.auth
  );
  const { isVerifying } = useSelector((state) => state.app);
  const history = useHistory();

  const capture = React.useCallback(() => {
    setPicture("");
    const pictureSrc = webcamRef?.current?.getScreenshot();

    setPicture(pictureSrc);
  });

  const handleRetake = () => {
    setPicture("");
  };

  const openCamera = () => {
    if (!isVerifying) {
      setPicture("");
      setFile(null);
      setTakepicture(true);
    }
  };
  const closeCamera = () => {
    setTakepicture(false);
  };

  const onResetFile = () => {
    setFile(null);
    setPicture("");
  };

  const closeimgFunc = () => {
    setTakepicture(false);
    setPicture("");
  };

  const handleRedirect = () => {
    if (brandDetails && offerDetails && brandDetails?.id && offerDetails?.id) {
      dispatch(clearAuthStatesWithoutBrands());
      history.push(
        `/auth/signup/${brandDetails?.slug}/offerID=${offerDetails?.id}`
      );
    } else {
      dispatch(signOut());
      history.push("/auth/signup");
    }
  };

  const isDetailsValid = () => {
    if (user?.id != "" && user?.industrySector) {
      if (timeCheck(moment(), signUpStartedAt)) {
      } else {
        handleRedirect();
      }
    } else {
      handleRedirect();
    }
  };

  useEffect(() => {
    if (Object.keys(imageVerifyDetails).length == 0) {
      dispatch(getGlobalContent("imageVerify"));
    }
    isDetailsValid();
  }, []);

  return (
    <div className="bg-[#fff] h-full p-4 !pb-[10px] md:!pb-[15px]">
      <h2 className="global_header font-[500] text-center mb-4">
        {imageVerifyDetails?.verifyTitle
          ? imageVerifyDetails?.verifyTitle
          : "Verify"}
      </h2>
      <p className="body_text px-3 font-[500] text-[#6E7680] text-center mb-4">
        {imageVerifyDetails?.verifyText
          ? imageVerifyDetails?.verifyText
          : "Upload your work ID, please ensure your name and your employer’s name are clearly visible. Uploads are used for verification purposes only and deleted within 48 hours."}
      </p>
      <p className="terms_conditions font-[500] text-[#6E7680] text-center mb-4">
        See &nbsp;
        <a
          href={
            imageVerifyDetails?.acceptedFormsIDLink
              ? imageVerifyDetails.acceptedFormsIDLink
              : "https://www.wearekii.co.uk/verifyid"
          }
          target="_blank"
          className="text-primary underline"
        >
          {imageVerifyDetails?.acceptedFormsIDTextlink
            ? imageVerifyDetails?.acceptedFormsIDTextlink
            : "accepted forms of ID."}
        </a>
      </p>
      <div className="mb-10">
        <UploadFile
          onChange={(e) => setFile(e)}
          onReset={onResetFile}
          openCamera={openCamera}
          closeCamera={closeCamera}
          takepicture={takepicture}
          setPicture={setPicture}
          picture={picture}
          setErrorMessage={setErrorMessage}
        />
      </div>

      <div>
        {takepicture && (
          <>
            <div className="relative mt-md-0 mt-5">
              {picture == "" && picture !== null ? (
                <div>
                  <div className="absolute -right-[12px] -top-[25px] cursor-pointer">
                    {/* <RxCrossCircled
                      color="grey"
                      size={20}
                      onClick={closeimgFunc}
                    /> */}
                    <RxCrossCircled
                      color="grey"
                      size={25}
                      onClick={closeimgFunc}
                    />
                  </div>
                  <Webcam
                    audio={false}
                    height={400}
                    ref={webcamRef}
                    width={400}
                    className="rounded-md"
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    // mirrored={true}
                  />
                </div>
              ) : (
                <div>
                  <div className="absolute -right-[12px] -top-[18px] cursor-pointer">
                    <RxCrossCircled
                      color="grey"
                      size={20}
                      onClick={closeimgFunc}
                    />
                  </div>
                  <img className="rounded-md" src={picture} />
                </div>
              )}
            </div>
            <div className="flex py-3 justify-between">
              <div>
                <button
                  className="btn btn-primary text-[12px] px-5"
                  onClick={() => {
                    if (picture != "") {
                      setFile(picture);
                    }
                    setTakepicture(false);
                  }}
                >
                  save
                </button>
              </div>

              <div>
                {picture != "" ? (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleRetake();
                    }}
                    className="btn btn-primary text-[12px] px-5"
                  >
                    Retake
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      capture();
                    }}
                    className="btn btn-danger text-[12px] px-5"
                  >
                    Capture
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      {errorMessage != "" && <p className="error_text mb-10">{errorMessage}</p>}

      {file ? (
        <SiteButton
          disabled={isVerifying}
          pill
          block
          onClick={async (e) => {
            const base64Regex = /^data:[\w/]+;base64,([A-Za-z0-9+/]+={0,2})$/;

            let offerID = "";
            setTakepicture(false);

            if (
              offerDetails &&
              brandDetails &&
              offerDetails?.id &&
              brandDetails?.id
            ) {
              offerID = offerDetails?.id;
            }

            let payload = { userId: user?.id, file };

            if (brandDetails?.id && offerDetails?.id) {
              payload = {
                ...payload,
                referrerSource: `/${brandDetails?.slug}/offerID=${offerDetails?.id}`,
              };
            }

            if (base64Regex.test(file)) {
              dispatch(
                verifyMemberLicense(
                  { ...payload, type: "baseFile" },
                  offerID,
                  history,
                  () => {},
                  (message) => {
                    if (message) {
                      setErrorMessage(message);
                      // setTimeout(() => {
                      //   setErrorMessage("");
                      // }, 3000);
                    }
                  }
                )
              );
            } else {
              dispatch(
                verifyMemberLicense(
                  payload,
                  offerID,
                  history,
                  () => {},
                  (message) => {
                    if (message) {
                      setErrorMessage(message);
                      // setTimeout(() => {
                      //   setErrorMessage("");
                      // }, 3000);
                    }
                  }
                )
              );
            }
          }}
        >
          {isVerifying ? (
            <Spinner />
          ) : (
            <>
              {" "}
              {imageVerifyDetails?.submitButtonText
                ? imageVerifyDetails?.submitButtonText
                : "Submit"}
            </>
          )}
        </SiteButton>
      ) : (
        <>
          <hr className="mb-8" />
          <p className="text-[#6E7680] text-center terms_conditions mb-8">
            {imageVerifyDetails?.backToEmailVerifyText
              ? imageVerifyDetails?.backToEmailVerifyText
              : "Go back to verify with work email"}
          </p>
          <SiteButton
            pill
            block
            outline
            onClick={() => {
              if (
                offerDetails &&
                brandDetails &&
                offerDetails?.id &&
                brandDetails?.id
              ) {
                history.push(
                  `/auth/verify/${brandDetails?.slug}/offerID=${offerDetails?.id}`
                );
              } else {
                history.push("/auth/verify");
              }
            }}
          >
            {imageVerifyDetails?.backToEmailVerifyButtonText
              ? imageVerifyDetails?.backToEmailVerifyButtonText
              : "Back"}
          </SiteButton>
        </>
      )}
    </div>
  );
};

export default VerifyWorkId;
