const currentDate = new Date();
const formattedDate = currentDate.toLocaleString("en-US", {
  month: "numeric",
  day: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  hour12: true,
});

const checkOfferBrandValidity = async (
  brandDetails,
  offerDetails,
  onSuccess = () => {},
  onError = () => {}
) => {
  if (brandDetails?.id == offerDetails?.brandName) {
    let { endDate, offerType } = offerDetails;
    const currentDate = new Date();
    let targetDate = "";
    if (endDate && endDate != "") {
      targetDate = new Date(endDate);
    }

    // if (offerType == "deal") {
    //   onSuccess();

    // } else
    if (endDate && endDate != "" && currentDate < targetDate) {
      onSuccess();
    } else if (endDate == "") {
      onSuccess();
    } else {
      onError("offerExpire");
    }
  } else {
    onError("invalidOffer");
  }
};

export default checkOfferBrandValidity;
