import { createSlice } from "@reduxjs/toolkit";
import firebase from "../../config/firebase";
import { setSignUpStartedAt, setUser } from "./authSlice";
import moment from "moment";
let offersCollection = firebase.firestore().collection("offers");
let brandsCollection = firebase.firestore().collection("brands");

export const getIndustrySectorDropdown = () => (dispatch) => {
  firebase
    .firestore()
    .collection("industrySectorDropdown")
    .onSnapshot(async (query) => {
      let temp = [];
      for (let doc of query.docs) {
        temp.push({ id: doc.id, ...doc.data() });
      }
      dispatch(setIndustrySectorDropdown(temp));
    });
};
export const getPronounDropdown = () => (dispatch) => {
  firebase
    .firestore()
    .collection("pronounDropdown")
    .onSnapshot(async (query) => {
      let temp = [];
      for (let doc of query.docs) {
        temp.push({ id: doc.id, ...doc.data() });
      }
      dispatch(setPronouns(temp));
    });
};

export const getDomains = () => async (dispatch) => {
  firebase
    .firestore()
    .collection("domains")
    .where("domainType", "==", "special")
    .onSnapshot(async (query) => {
      let tempData = [];
      for (let doc of query.docs) {
        tempData.push({ id: doc.id, ...doc.data() });
      }
      dispatch(setDomains(tempData));
    });
};

export const checkDomain =
  (payload, industrySector, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch) => {
    let domainDetails = await firebase
      .firestore()
      .collection("domains")
      .where("domainType", "==", "default")
      .where("domainName", "==", payload)
      .where("industrySector", "==", industrySector)
      .get();
    if (domainDetails?.size > 0) {
      onSuccess();
    } else {
      onError();
    }
  };

export const checkEmailAlreadyExists =
  (payload, redirectInfo, history, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch, getState) => {
    dispatch(setIsCheckingEmail(true));
    await dispatch(setSignUpStartedAt(moment()));

    let { primaryEmail } = payload;

    const { joinFree } = getState().globalContent;

    firebase
      .auth()
      .fetchSignInMethodsForEmail(primaryEmail)
      .then(async (res) => {
        if (res.length > 0) {
          onError(joinFree?.emailAlreadyExistsText, redirectInfo?.login);
          setTimeout(() => {
            dispatch(setIsCheckingEmail(false));
          }, 500);
          // let userDetails = await firebase
          //   .firestore()
          //   .collection("users")
          //   .where("primaryEmail", "==", primaryEmail)
          //   .get();
          // if (userDetails?.docs[0]?.data()?.verified == "yes") {
          //   onError(joinFree?.emailAlreadyExistsText, redirectInfo?.login);
          //   setTimeout(() => {
          //     dispatch(setIsCheckingEmail(false));
          //   }, 1000);
          // } else if (userDetails?.docs[0]?.data()?.verified == "pending") {
          //   onError(joinFree?.emailAlreadyExistsText, redirectInfo?.login);
          //   setTimeout(() => {
          //     dispatch(setIsCheckingEmail(false));
          //   }, 1000);
          // } else {
          //   onError(joinFree?.emailAlreadyExistsText, redirectInfo?.login);
          //   setTimeout(() => {
          //     dispatch(setIsCheckingEmail(false));
          //   }, 1000);
          // }
        } else {
          const currentDate = new Date();
          const formattedDate = currentDate.toLocaleString("en-US", {
            month: "numeric",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true,
          });
          firebase
            .auth()
            .createUserWithEmailAndPassword(
              payload.primaryEmail,
              payload.password
            )
            .then((data) => {
              let { password, referrerSource, confirmPassword, ...rest } =
                payload;

              firebase
                .auth()
                .signInWithEmailAndPassword(primaryEmail, password)
                .then((singInRes) => {
                  firebase
                    .firestore()
                    .collection("users")
                    .doc(data.user.uid)
                    .set(obj)
                    .then((res) => {
                      dispatch(
                        getUserData(data.user.uid, redirectInfo, history)
                      );
                    })
                    .catch((err) => {
                      onError(err.message);
                      setTimeout(() => {
                        dispatch(setIsCheckingEmail(false));
                      }, 500);
                    });
                })
                .catch((err) => {
                  onError(err.message);
                  setTimeout(() => {
                    dispatch(setIsCheckingEmail(false));
                  }, 500);
                });
              let obj = {
                primaryEmail,
                dateRegistered: formattedDate,
                userType: "member",
                referrerSource,
                secondaryEmail: "",
                dateVerified: "",
                verificationMethod: "",
                firstName: "",
                lastName: "",
                industrySector: "",
                verified: "no",
                gender: "",
                emailOptIn: "",
                dateEmailOptUpdated: "",
              };
            })
            .catch((err) => {
              onError(err.message);
              setTimeout(() => {
                dispatch(setIsCheckingEmail(false));
              }, 500);
            });
        }
      })
      .catch((err) => {
        console.log("Fetching emails erro");
      });
  };

export const getUserData = (id, redirectInfo, history) => async (dispatch) => {
  let isFirstRun = true;
  firebase
    .firestore()
    .collection("users")
    .doc(id)
    .onSnapshot(async (userData) => {
      if (userData.data()) {
        let { referrerSource, verified } = userData.data();

        if (referrerSource && referrerSource != "") {
          let offer = referrerSource.split("=")[1];
          let offerDetails = await offersCollection.doc(offer).get();
          if (offerDetails.data()) {
            let { brandName } = offerDetails.data();

            let brandDetails = await brandsCollection.doc(brandName).get();
            if (brandDetails.data()) {
              await dispatch(
                setUser({
                  id: userData.id,
                  ...userData.data(),
                  offerDetails: {
                    id: offerDetails.id,
                    ...offerDetails.data(),
                  },
                  brandDetails: { id: brandDetails.id, ...brandDetails.data() },
                })
              );
            } else {
              await dispatch(
                setUser({
                  id: userData.id,
                  ...userData.data(),
                  offerDetails: {},
                  brandDetails: {},
                  codeDetails: {},
                })
              );
            }
          } else {
            await dispatch(
              setUser({
                id: userData.id,
                ...userData.data(),
                offerDetails: {},
                brandDetails: {},
                codeDetails: {},
              })
            );
          }
        } else {
          await dispatch(
            setUser({
              id: userData.id,
              ...userData.data(),
              offerDetails: {},
              brandDetails: {},
              codeDetails: {},
            })
          );
        }

        if (verified && verified == "pending") {
          if (isFirstRun) {
            history.push(redirectInfo?.manualReview);
            isFirstRun = false;
          }
          dispatch(setIsCheckingEmail(false));
        } else {
          if (isFirstRun) {
            history.push(redirectInfo?.signupDetails);
            isFirstRun = false;
          }
          dispatch(setIsCheckingEmail(false));
        }
      }
    });
};

export const addDomainError = (payload) => async (dispatch) => {
  firebase
    .firestore()
    .collection("errorLogs")
    .add({ ...payload, createdAt: firebase.firestore.Timestamp.now() });
};

const appSlice = createSlice({
  name: "app",
  initialState: {
    loading: false,
    toast: false,
    toastmassage: "",
    pronounDropdown: [],
    industrySectorDropdown: [],
    isSigningUp: false,
    isVerifying: false,
    isCheckingEmail: false,
    isLogin: false,
    domains: [],
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setPronouns: (state, action) => {
      state.pronounDropdown = action.payload;
    },
    setIndustrySectorDropdown: (state, action) => {
      state.industrySectorDropdown = action.payload;
    },
    setSigningUpLoader: (state, { type, payload }) => {
      state.isSigningUp = payload;
    },
    setIsVerifying: (state, { type, payload }) => {
      state.isVerifying = payload;
    },
    setIsCheckingEmail: (state, { type, payload }) => {
      state.isCheckingEmail = payload;
    },
    setDomains: (state, { type, payload }) => {
      state.domains = payload;
    },
    setLoginLoader: (state, { type, payload }) => {
      state.isLogin = payload;
    },
  },
});

export const {
  setLoading,
  setIndustrySectorDropdown,
  setPronouns,
  setSigningUpLoader,
  setIsVerifying,
  setIsCheckingEmail,
  setDomains,
  setLoginLoader,
} = appSlice.actions;
export default appSlice.reducer;
