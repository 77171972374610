import logo from "../../assets/img/auth-images/lgLogo.svg";
import userIcon from "../../assets/img/user.png";
import kiiId from "../../assets/img/kiiId.png";
import logout from "../../assets/img/logout.png";
import rightArrow from "../../assets/img/rightArrow.png";
import { Link, useHistory } from "react-router-dom";
import Loading from "../../components/custom/Loading";
import { useDispatch, useSelector } from "react-redux";
import BackLink from "../../components/custom/BackLink";
import { useEffect } from "react";
import { getGlobalContent } from "../../store/main/globalContentSlice";
import { signOut } from "../../store/main/authSlice";

const MyAccount = () => {
  const { brandDetails, offerDetails, isGettingBrandDetails, uid } =
    useSelector((state) => state.auth);

  const { myAccountMenuDetails } = useSelector((state) => state.globalContent);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (Object.keys(myAccountMenuDetails).length == 0) {
      dispatch(getGlobalContent("myAccountMenu"));
    }
  }, []);

  return (
    <div className="main-app my_account_screen mt-[20px] shadow-md rounded-[20px] relative">
      {isGettingBrandDetails && <Loading />}

      {!isGettingBrandDetails && offerDetails?.offerLink && (
        <div
          className="absolute left-0 top-[-28px] lg:top-[-32px]"
          onClick={() => {
            let link = offerDetails?.offerLink;
            if (link.includes("{memberid}")) {
              link = link.replace("{memberid}", uid);
            }

            setTimeout(() => {
              window.location.href = link;
            }, 300);
          }}
        >
          <BackLink title={`Back`} />
          {/* <BackLink title={`Back to ${brandDetails?.brandName}`} /> */}
        </div>
      )}
      <div className="header">
        <img src={logo} className="h-[45px] w-[57px]" alt="logo" />
      </div>
      <h2 className="title global_header">
        {myAccountMenuDetails?.myAccountTitle
          ? myAccountMenuDetails.myAccountTitle
          : "My Account"}
      </h2>
      <p className="sub_title body_text">
        {myAccountMenuDetails?.myAccountText
          ? myAccountMenuDetails.myAccountText
          : "Welcome to your kii account."}
      </p>
      <ul>
        <Link to="/auth/account/update" className="link">
          <li className="list_item border-t">
            <img src={userIcon} alt="details" />
            <p>
              {" "}
              {myAccountMenuDetails?.myDetailsMenuField
                ? myAccountMenuDetails.myDetailsMenuField
                : "My Details"}{" "}
            </p>
            <img src={rightArrow} alt="details" />
          </li>
        </Link>
        <Link to="/auth/account/verified" className="link">
          <li className="list_item border-t">
            <img src={kiiId} alt="kii-id" />
            <p>
              {myAccountMenuDetails?.kiiIDMenuField
                ? myAccountMenuDetails.kiiIDMenuField
                : "kii ID"}
            </p>
            <img src={rightArrow} alt="details" />
          </li>
        </Link>

        <li
          className="list_item border-t"
          onClick={(e) => {
            dispatch(signOut(history));
          }}
        >
          <img src={logout} alt="logout" />
          <p>Logout</p>
          <img src={rightArrow} alt="details" />
        </li>
      </ul>
    </div>
  );
};

export default MyAccount;
