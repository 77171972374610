import React, { useEffect, useState } from "react";
import SiteInput from "../../../components/custom/SiteInput";
import SiteButton from "../../../components/custom/SiteButton";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../../store/main/userSlice";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const history = useHistory();
  const dispatch = useDispatch();

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="login_screen ">
      <div className="mb-2">
        {" "}
        <h2 className="fw--700 text-center offer_title">
          Forgot Password
        </h2>{" "}
        <p className="text-center text-[#6E7680] body_text font-[500] mt-3">
          Enter your account email and we will send a reset link
        </p>
      </div>
      <form>
        <SiteInput
          type="email"
          className="mt-[22px]  md:mt-[27px]"
          value={email}
          placeholder="email"
          onChange={(event) => {
            setEmail(event.target.value);
            if (errorMessage != "") {
              setErrorMessage("");
            }
          }}
          required
        />

        {errorMessage != "" && (
          <p className="error_text mt-[22px]  md:mt-[27px]">{errorMessage}</p>
        )}

        {successMessage != "" && (
          <p className="text-[#25B6AB] mt-[22px]  md:mt-[27px] text-center">
            {successMessage}
          </p>
        )}

        <SiteButton
          pill
          block
          onClick={(e) => {
            e.preventDefault();
            dispatch(
              resetPassword(
                email,
                (message) => {
                  setSuccessMessage(message);
                  setTimeout(() => {
                    setSuccessMessage("");
                    history.push("/auth/login");
                  }, 3000);
                },
                (message) => {
                  setErrorMessage(message);
                  // setTimeout(() => {
                  //   setErrorMessage("");
                  // }, 3000);
                }
              )
            );
          }}
          disabled={email == "" || !email.match(emailRegex)}
          className="mt-[22px]  md:mt-[27px] cursor-pointer"
        >
          Send
        </SiteButton>
      </form>
    </div>
  );
};

export default ResetPassword;
