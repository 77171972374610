import React, { useState } from "react";
import { InputGroup, Input } from "reactstrap";

const SiteInput = ({ type = "text", className = "", ...restProps }) => {
  const [value, setValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const inputClassName = `rounded-pill input_text    lg:h-[68px] h-[56px] lg:max-w-[405px] max-w-[355px] w-full mx-auto focus:ring-[2px]  focus:ring-[#25b6ab] placeholder-secondary text-black focus:text-black ${className}`;

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <InputGroup>
        <Input
          style={{
            border: isFocused ? "2px solid white" : "2px solid #C9C9C9",
          }}
          type={showPassword ? "text" : type}
          className={inputClassName}
          value={value}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={(event) => setValue(event.target.value)}
          // autocomplete="new-password"
          autoComplete="new-password"
          {...restProps}
        />

        {type === "password" && (
          <i
            className={`fa-regular  ${
              showPassword ? "fa-eye" : "fa-eye-slash"
            }`}
            style={{
              cursor: "pointer",
              position: "absolute",
              top: "50%",
              right: "20px",
              transform: "translateY(-50%)",
              zIndex: "3",
              color: "#909090",
              fontSize: "18px",
            }}
            onClick={togglePasswordVisibility}
          />
        )}
      </InputGroup>
      {restProps.error && (
        <div className="pl-4">
          <p className="text-[10px] text-[#F20000]">{restProps.error}</p>
        </div>
      )}
    </div>
  );
};

export default SiteInput;
