import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

const SiteButton = ({
  to,
  href,
  color = "primary",
  pill,
  size = "md",
  block,
  outline,
  onClick,
  type,
  className,
  children,
  isLoading = false,
  disabled = false,
  brandButton,
}) => {
  const { brandDetails, offerDetails } = useSelector((state) => state.auth);
  const classes = [
    "lg:h-[68px] border-[2px] h-[56px] lg:max-w-[405px] max-w-[355px] w-full mx-auto rounded button_text font-[500]",
    className,
  ];

  const resetButtonStyles = (btnElement) => {
    btnElement.style.backgroundColor = "";
    btnElement.style.borderColor = "";
    btnElement.style.color = "";
  };

  useEffect(() => {
    if (brandButton) {
      if (brandDetails?.id) {
        let brandColor = brandDetails?.brandButtonColor
          ? brandDetails?.brandButtonColor
          : color;
        if (outline) {
          // classes.push(`btn-outline-${brandColor}`);
          const btnPrimaryElement = document.querySelector(
            ".btn-outline-primary"
          );
          btnPrimaryElement.style.backgroundColor = "white";
          btnPrimaryElement.style.borderColor = brandColor;

          btnPrimaryElement.addEventListener("mouseenter", () => {
            btnPrimaryElement.style.backgroundColor = brandColor;
          });

          btnPrimaryElement.addEventListener("mouseleave", () => {
            btnPrimaryElement.style.backgroundColor = "white";
          });
        } else {
          // classes.push(`btn-${brandColor}`);
          const btnPrimaryElement = document.querySelector(".btn-primary");
          btnPrimaryElement.style.backgroundColor = brandColor;
          btnPrimaryElement.style.borderColor = brandColor;
        }
      } else {
        const btnPrimaryElement = document.querySelector(".btn-primary");
        resetButtonStyles(btnPrimaryElement);

        const btnOutlinePrimaryElement = document.querySelector(
          ".btn-outline-primary"
        );
        resetButtonStyles(btnOutlinePrimaryElement);

        if (outline) {
          btnOutlinePrimaryElement.addEventListener("mouseenter", () => {
            btnOutlinePrimaryElement.style.backgroundColor = "#25B6AB";
          });

          btnOutlinePrimaryElement.addEventListener("mouseleave", () => {
            btnOutlinePrimaryElement.style.backgroundColor = "white";
          });
        }
      }
    }
  }, [brandDetails, offerDetails]);

  if (pill) {
    classes.push("rounded-pill");
  }

  if (outline) {
    classes.push(` btn-outline-${color}`);
  } else {
    classes.push(`btn-${color}`);
  }

  const buttonContent = isLoading ? <span className="loader" /> : children;

  if (to) {
    // Render as Link
    return (
      <Link
        to={to}
        className={`btn ${classes.join(" ")} ${
          block ? "flex items-center justify-center" : ""
        } ${size ? `btn-${size}` : ""} ${disabled ? "disabled" : ""}`}
        disabled={disabled}
        style={disabled ? { pointerEvents: "none" } : null}
      >
        {buttonContent}
      </Link>
    );
  }

  if (href) {
    // Render as anchor tag
    return (
      <a
        href={href}
        className={`btn   flex text-[#25B6AB]  ${classes.join(" ")} ${
          block ? "flex" : ""
        } ${size ? `btn-${size} ` : ""} ${disabled ? "disabled" : ""}`}
        disabled={disabled}
        style={disabled ? { pointerEvents: "none" } : null}
      >
        {buttonContent}
      </a>
    );
  }

  const uniqueNumber = Math.floor(Math.random() * 1000000);

  const buttonId = `myButton_${uniqueNumber}`;

  // Render as Button
  return (
    <Button
      color={color}
      className={classes.join(" ")}
      size={size}
      block={block}
      outline={outline}
      onClick={onClick}
      type={type}
      disabled={disabled || isLoading}
      id={buttonId}
    >
      {buttonContent}
    </Button>
  );
};

export default SiteButton;
