import { useEffect, useState } from "react";
import SiteInput from "../../../components/custom/SiteInput";
import SiteButton from "../../../components/custom/SiteButton";
import SiteSelect from "../../../components/custom/SiteSelect";

import "react-toastify/dist/ReactToastify.css";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAuthState,
  clearAuthStatesWithoutBrands,
  onSignUpChange,
  signOut,
  updateMember,
} from "../../../store/main/authSlice";
import {
  getIndustrySectorDropdown,
  getPronounDropdown,
} from "../../../store/main/appSlice";
import timeCheck from "../../../global.js/timeCheck";
import moment from "moment";
import { getGlobalContent } from "../../../store/main/globalContentSlice";
import { Spinner } from "reactstrap";

const Details = () => {
  const [industry, setIndustry] = useState("");
  const [Pronoun, setPronoun] = useState("");
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState("");
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
  });
  const { yourDetails } = useSelector((state) => state.globalContent);

  const { signupDetails, signUpStartedAt, user, brandDetails, offerDetails } =
    useSelector((state) => state.auth);

  const { pronounDropdown, industrySectorDropdown, isSigningUp } = useSelector(
    (state) => state.app
  );

  const [emailOpt, setEmailOpt] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (Object.keys(yourDetails).length == 0) {
      dispatch(getGlobalContent("yourDetails"));
    }
  }, []);

  const industrySelect = (data) => {
    clearErrorMessage();
    setIndustry(data);
  };
  const pronounSelect = (data) => {
    clearErrorMessage();
    setPronoun(data);
  };

  const clearErrorMessage = () => {
    if (errorMessage != "") {
      setErrorMessage("");
    }
    if (errors?.firstName || errors?.lastName) {
      setErrors({
        firstName: false,
        lastName: false,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // let nameRegex = /^(?=.*[a-zA-Z])(?!.*[ \-]$)[a-zA-Z\s\-]+$/;
    let nameRegex = /^(?=.*[a-zA-Z])[\s\S]*[a-zA-Z\s-]+[\s\S]*$/;

    if (
      nameRegex.test(signupDetails?.firstName) &&
      nameRegex.test(signupDetails?.lastName)
    ) {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleString("en-US", {
        month: "numeric",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
      });
      const payload = {
        firstName: signupDetails?.firstName,
        lastName: signupDetails?.lastName,
        industrySector: industry,
        gender: Pronoun,
        emailOptIn: emailOpt ? "no" : "yes",
        dateEmailOptUpdated: formattedDate,
      };

      // use this payload for dispatch

      dispatch(onSignUpChange({ key: "industrySector", value: industry }));
      dispatch(onSignUpChange({ key: "gender", value: Pronoun }));

      if (emailOpt) {
        dispatch(onSignUpChange({ key: "emailOptIn", value: "no" }));
      }
      dispatch(
        updateMember(
          user?.id,
          payload,
          () => {
            if (
              brandDetails &&
              offerDetails &&
              Object.keys(brandDetails).length > 0 &&
              Object.keys(offerDetails).length > 0
            ) {
              history.push(
                `/auth/verify/${brandDetails?.slug}/offerID=${offerDetails?.id}`
              );
            } else {
              history.push("/auth/verify");
            }
          },
          (message) => {
            setErrorMessage(message);
            // setTimeout(() => {
            //   setErrorMessage("");
            // }, 3000);
          }
        )
      );
    } else {
      if (!nameRegex.test(signupDetails?.firstName)) {
        setErrors((prev) => ({ ...prev, firstName: true }));
      }
      if (!nameRegex.test(signupDetails?.lastName)) {
        setErrors((prev) => ({ ...prev, lastName: true }));
      }
    }
  };

  const handleRedirect = () => {
    if (brandDetails && offerDetails && brandDetails?.id && offerDetails?.id) {
      dispatch(clearAuthStatesWithoutBrands());
      history.push(
        `/auth/signup/${brandDetails?.slug}/offerID=${offerDetails?.id}`
      );
    } else {
      dispatch(signOut());
      history.push("/auth/signup");
    }
  };

  const isDetailsValid = async () => {
    if (
      // signupDetails?.primaryEmail != "" &&
      // signupDetails?.password != "" &&
      // signUpStartedAt != ""
      user?.id &&
      user?.id != ""
    ) {
      if (timeCheck(moment(), signUpStartedAt)) {
      } else {
        handleRedirect();
      }
    } else {
      handleRedirect();
    }
  };
  const clearOldStates = () => {
    dispatch(
      onSignUpChange({
        key: "firstName",
        value: "",
      })
    );
    dispatch(
      onSignUpChange({
        key: "lastName",
        value: "",
      })
    );
    dispatch(
      onSignUpChange({
        key: "industrySector",
        value: "",
      })
    );
    dispatch(
      onSignUpChange({
        key: "gender",
        value: "",
      })
    );
  };

  useEffect(() => {
    isDetailsValid();
    clearOldStates();

    if (pronounDropdown?.length == 0) {
      dispatch(getPronounDropdown());
    }
    if (industrySectorDropdown?.length == 0) {
      dispatch(getIndustrySectorDropdown());
    }
  }, []);

  return (
    <div className="signupDetails">
      <div>
        <h2 className="signupDetails__title global_header">
          {yourDetails?.yourDetailsTitle
            ? yourDetails?.yourDetailsTitle
            : "Your Details"}
        </h2>
      </div>
      <form className="signupDetails__form mt-0 ">
        <SiteInput
          className="px-3 font-[400] mt-[22px]  md:mt-[27px] "
          value={signupDetails?.firstName}
          name="firstName"
          placeholder="First name"
          onChange={(event) => {
            clearErrorMessage();
            dispatch(
              onSignUpChange({
                key: event.target.name,
                value: event.target.value,
              })
            );
          }}
          required
        />
        {errors?.firstName && (
          <p className="pl-2 error_text text-start mt-[10px]  md:mt-[12px]">
            at least one character entered
          </p>
        )}

        <SiteInput
          className="px-3 font-[400] mt-[22px]  md:mt-[27px] "
          type="text"
          name="lastName"
          value={signupDetails?.lastName}
          placeholder="Last name"
          onChange={(event) => {
            clearErrorMessage();

            dispatch(
              onSignUpChange({
                key: event.target.name,
                value: event.target.value,
              })
            );
          }}
          required
        />
        {errors?.lastName && (
          <p className="pl-2 error_text text-start mt-[10px]  md:mt-[12px]">
            at least one character entered
          </p>
        )}

        <SiteSelect
          placeholder="Industry sector"
          // options={industryValues}
          options={industrySectorDropdown}
          onOptionSelect={industrySelect}
          selectedOption=""
          className="mt-[22px]  md:mt-[27px]"
          type="industry"
        />
        <SiteSelect
          placeholder="Pronouns"
          options={pronounDropdown}
          onOptionSelect={pronounSelect}
          selectedOption=""
          type="pronoun"
          className="mt-[22px]  md:mt-[27px]"
        />
        <div className="signupDetails__tickbox  py-2   mt-[22px]  md:mt-[27px] ">
          <div className="pt-[4px] md:pt-0">
            <input
              type="checkbox"
              className="h-6 w-6  md:h-5 md:w-5  rounded-sm [border:1px_solid_#25B6AB] "
              style={{ "--checkbox-color": "#25B6AB", boxShadow: "none" }}
              checked={emailOpt}
              onChange={(e) => setEmailOpt(!emailOpt)}
            />
          </div>
          <div className="signupDetails__tickbox__checkbox opt_text pl-2">
            {yourDetails?.optInText ? (
              yourDetails.optInText
            ) : (
              <>
                Please tick the box if you
                <span className="font-medium px-1 ">do not</span>want us to send
                you the latest news and offers by email
              </>
            )}
          </div>
        </div>
        {errorMessage != "" && (
          <p className="error_text mt-[22px]  md:mt-[27px]">{errorMessage}</p>
        )}
        <SiteButton
          disabled={
            !signupDetails?.firstName ||
            !signupDetails?.firstName.trim() ||
            !signupDetails?.lastName ||
            !signupDetails?.lastName.trim() ||
            industry == "" ||
            Pronoun == "" ||
            isSigningUp
          }
          pill
          block
          className=" cursor-pointer mt-[22px]  md:mt-[27px]"
          onClick={handleSubmit}
        >
          {isSigningUp ? (
            <Spinner />
          ) : (
            <>
              {yourDetails?.submitButtonText
                ? yourDetails.submitButtonText
                : "Submit"}
            </>
          )}
        </SiteButton>
      </form>
      {/* <div className="signup__links">
        <p className="signup__links__privacylinks">
          By clicking Submit you agree to the website
          <a href="#">Privacy Policy</a>,<a href="#">Terms of Service</a>
          and
          <a href="#">Cookie Policy</a>.
        </p>
      </div> */}
    </div>
  );
};

export default Details;
