import leftArrow from "../../assets/img/icons/leftArrow.png";
import whiteKiiLogo from "../../assets/img/icons/kiiWhiteLogo.png";
import addUser from "../../assets/img/addUser.png";
import Barcode from "react-barcode";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useRef, useState } from "react";
import {
  getUserDetails,
  updateProfilePicture,
} from "../../store/main/userSlice";
import { useEffect } from "react";
import Loading from "../../components/custom/Loading";

const VerifiedSince = () => {
  const history = useHistory();

  const { user, uid } = useSelector((state) => state.auth);
  const { userDetails, isGettingUserDetails, isUpdatingDetails } = useSelector(
    (state) => state.user
  );

  const [errorMessage, setErrorMessage] = useState("");

  const imgRef = useRef(null);
  const dispatch = useDispatch();

  const handleback = (e) => {
    e.preventDefault();
    history.push("/auth/account");
  };

  const clearErrorMessage = () => {
    if (errorMessage != "") {
      setErrorMessage("");
    }
  };

  const uploadProfilePicture = (e) => {
    clearErrorMessage();
    const file = e.target.files[0];
    const maxSize = 1024 * 1024; // 1 MB in bytes

    const fileName = file.name;
    const fileExtension = fileName.split(".").pop().toLowerCase();
    if (
      (fileExtension == "jpeg" ||
        fileExtension == "png" ||
        fileExtension == "jpg") &&
      file.size <= maxSize &&
      uid
    ) {
      dispatch(updateProfilePicture(uid, file));
    } else {
      imgRef.current.value = null;

      if (file.size > maxSize) {
        setErrorMessage("Image should be less than or equal to 1MB");
      } else {
        setErrorMessage("Please select only image file");
      }
      // setTimeout(() => {
      //   setErrorMessage("");
      // }, 3000);
    }
  };
  useEffect(() => {
    if (uid) {
      dispatch(getUserDetails(uid));
    }
  }, []);

  return (
    <div className="verirfiedSince !max-w-[350px] !bg-[#EAEEF4] verifiedSince-main ">
      <div className="-mt-[20px]">
        <div className="">
          <img
            src={leftArrow}
            className="h-[38px] w-[38px] cursor-pointer"
            onClick={handleback}
            alt=""
          />
        </div>
        <div className="verirfiedSince__parent ">
          <div className="verirfiedSince__child1 absolute h-[450px] top-[70px] w-[280px]">
            {(isGettingUserDetails || isUpdatingDetails) && <Loading />}

            <div className=" verirfiedSince__child2  ">
              <div className="relative">
                <div className=" verirfiedSince__child3 ">
                  <img
                    src={whiteKiiLogo}
                    className="h-[33.61px] w-[42px] cursor-pointer"
                    alt=""
                  />
                </div>
                <div className="verirfiedSince__child4  ">
                  <img
                    src={userDetails?.image ? userDetails?.image : addUser}
                    onClick={(e) => imgRef.current.click()}
                    className="h-full w-full cursor-pointer kii-avatar "
                    alt=""
                  />
                  <input
                    type="file"
                    name="profilePictureURL"
                    ref={imgRef}
                    accept="image/*"
                    hidden
                    onChange={uploadProfilePicture}
                  />
                </div>
              </div>

              <div className="verirfiedSince__name ">
                {errorMessage != "" && (
                  <p className="error_text text-[12px]">{errorMessage}</p>
                )}

                <h3>
                  {userDetails?.firstName} {userDetails?.lastName}
                </h3>
                <p className="text-[12px]">Verified Member since:</p>
                <p className="text-[12px]">
                  {userDetails?.dateVerified
                    ? moment(
                        userDetails?.dateVerified,
                        "M/D/YYYY, h:mm:ss A"
                      ).format("DD/MM/YYYY")
                    : "dd/mm/yy"}{" "}
                </p>
              </div>

              <div className="mt-10">
                <Barcode
                  value={userDetails?.id}
                  height={25}
                  width={0.7}
                  textAlign={"center"}
                  fontSize={16}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifiedSince;
