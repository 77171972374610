import { useEffect, useState } from "react";
import SiteInput from "../../../components/custom/SiteInput";
import SiteButton from "../../../components/custom/SiteButton";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import {
  clearAuthStatesWithoutBrands,
  onSignUpChange,
  sendVerificationCode,
  signOut,
} from "../../../store/main/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import {
  addDomainError,
  checkDomain,
  getDomains,
  setSigningUpLoader,
} from "../../../store/main/appSlice";
import timeCheck from "../../../global.js/timeCheck";
import moment from "moment";
import { getGlobalContent } from "../../../store/main/globalContentSlice";

const Verify = () => {
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");

  const {
    signupDetails,
    signUpStartedAt,
    memberId,
    user,
    offerDetails,
    brandDetails,
  } = useSelector((state) => state.auth);
  const { isSigningUp, domains } = useSelector((state) => state.app);

  const { emailVerifyDetails } = useSelector((state) => state.globalContent);

  useEffect(() => {
    signupDetails?.secondaryEmail.match(emailRegex)
      ? setEmailValid(true)
      : setEmailValid(false);
  }, [signupDetails]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (domains.length == 0) {
      dispatch(getDomains());
    }

    if (Object.keys(emailVerifyDetails).length == 0) {
      dispatch(getGlobalContent("emailVerify"));
    }
  }, []);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.*-]+\.[a-zA-Z]{2,}$/;

  const checkDomainInDb = (domain, payload) => {
    dispatch(setSigningUpLoader(true));

    dispatch(
      checkDomain(
        domain,
        user?.industrySector,
        () => {
          // dispatch(
          //   userSignUP(signupDetails, () => {
          //     history.push("/auth/verify/code");
          //   })
          // );
          dispatch(
            sendVerificationCode(
              payload,
              () => {
                dispatch(setSigningUpLoader(false));
                if (
                  offerDetails &&
                  brandDetails &&
                  Object.keys(offerDetails).length > 0 &&
                  Object.keys(brandDetails).length > 0
                ) {
                  history.push(
                    `/auth/verify/code/${brandDetails?.slug}/offerID=${offerDetails?.id}`
                  );
                } else {
                  history.push("/auth/verify/code");
                }
              },
              (message) => {
                dispatch(setSigningUpLoader(false));
                if (message) {
                  setErrorMessage(message);
                  // setTimeout(() => {
                  //   setErrorMessage("");
                  // }, 3000);
                }
              }
            )
          );
        },
        () => {
          let errorResponse =
            "The email you have provided doest not match one of the key worker employers on our database, please verify with you work ID or raise a query wit our Support team";
          if (emailVerifyDetails?.emailDoesNotMatchText) {
            setErrorMessage(emailVerifyDetails.emailDoesNotMatchText);
          } else {
            setErrorMessage(errorResponse);
          }

          dispatch(setSigningUpLoader(false));
          dispatch(
            addDomainError({
              memberId: user?.id || "",
              email: signupDetails?.secondaryEmail || "",
              error: emailVerifyDetails?.emailDoesNotMatchText
                ? emailVerifyDetails?.emailDoesNotMatchText +
                  "(Simple Domain Check)"
                : errorResponse + "(Simple Domain Check)",
            })
          );
        }
      )
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const domain = signupDetails?.secondaryEmail.split("@")[1];

    let payload = {
      email: signupDetails?.secondaryEmail,
    };

    if (brandDetails?.id && offerDetails?.id) {
      payload = {
        ...payload,
        referrerSource: `/${brandDetails?.slug}/offerID=${offerDetails?.id}`,
      };
    }

    let domSplit = domain.split(".");
    let specialCheck = "";
    if (domSplit.length > 2) {
      specialCheck = `*.${domSplit[domSplit.length - 2]}.${
        domSplit[domSplit.length - 1]
      }`;
    } else {
      specialCheck = `*.${domSplit.slice(1).join(".")}`;
    }

    // const specialCheck = `*.${domSplit.slice(1).join(".")}`;
    console.log("Special check", specialCheck);

    if (domains?.length > 0) {
      let isDomainValid = domains.filter(
        (doma) => doma.domainName.toLowerCase() == specialCheck.toLowerCase()
      );

      if (isDomainValid?.length > 0) {
        let isIndustryValid = isDomainValid.find(
          (dom) => dom.industrySector == user?.industrySector
        );

        if (isIndustryValid?.id) {
          dispatch(setSigningUpLoader(true));
          dispatch(
            sendVerificationCode(
              payload,
              () => {
                dispatch(setSigningUpLoader(false));
                if (
                  offerDetails &&
                  brandDetails &&
                  Object.keys(offerDetails).length > 0 &&
                  Object.keys(brandDetails).length > 0
                ) {
                  history.push(
                    `/auth/verify/code/${brandDetails?.slug}/offerID=${offerDetails?.id}`
                  );
                } else {
                  history.push("/auth/verify/code");
                }
              },
              (message) => {
                dispatch(setSigningUpLoader(false));
                if (message) {
                  setErrorMessage(message);
                }
              }
            )
          );
        } else {
          let errorResponse =
            "The email you have provided doest not match one of the key worker employers on our database, please verify with you work ID or raise a query wit our Support team";
          if (emailVerifyDetails?.emailDoesNotMatchText) {
            setErrorMessage(emailVerifyDetails.emailDoesNotMatchText);
          } else {
            setErrorMessage(errorResponse);
          }
          dispatch(setSigningUpLoader(false));
          dispatch(
            addDomainError({
              memberId: user?.id || "",
              email: signupDetails?.secondaryEmail || "",
              error: emailVerifyDetails?.emailDoesNotMatchText
                ? emailVerifyDetails?.emailDoesNotMatchText +
                  "(Special Domain Check)"
                : errorResponse + "(Special Domain Check)",
            })
          );
        }
      } else {
        checkDomainInDb(domain, payload);
      }
    } else {
      checkDomainInDb(domain, payload);
    }
  };

  const handleVerifyById = () => {
    if (
      offerDetails &&
      brandDetails &&
      Object.keys(offerDetails).length > 0 &&
      Object.keys(brandDetails).length > 0
    ) {
      history.push(
        `/auth/verify/workid/${brandDetails?.slug}/offerID=${offerDetails?.id}`
      );
    } else {
      history.push("/auth/verify/workid");
    }
  };

  const handleRedirect = () => {
    if (brandDetails && offerDetails && brandDetails?.id && offerDetails?.id) {
      dispatch(clearAuthStatesWithoutBrands());
      history.push(
        `/auth/signup/${brandDetails?.slug}/offerID=${offerDetails?.id}`
      );
    } else {
      dispatch(signOut());
      history.push("/auth/signup");
    }
  };

  const isDetailsValid = () => {
    if (user?.id != "" && user?.industrySector) {
      if (timeCheck(moment(), signUpStartedAt)) {
      } else {
        handleRedirect();
      }
    } else {
      handleRedirect();
    }
  };

  useEffect(() => {
    isDetailsValid();
    dispatch(
      onSignUpChange({
        key: "secondaryEmail",
        value: "",
      })
    );
  }, []);
  return (
    <div className="Verify">
      {/* <ToastContainer position="top-right" className="text-xs" /> */}
      <div>
        <h2 className="Verify__title global_header">
          {emailVerifyDetails?.verifyTitle
            ? emailVerifyDetails.verifyTitle
            : "Verify"}
        </h2>
        <p className=" Verify__subtitle body_text">
          {emailVerifyDetails?.verifyText
            ? emailVerifyDetails.verifyText
            : "We’ll send a one time passcode to your work email to confirm you work for one of the qualifying services."}
        </p>
        <a
          href={
            emailVerifyDetails?.acceptedDomainsLink
              ? emailVerifyDetails.acceptedDomainsLink
              : "https://www.wearekii.co.uk/verifyemail"
          }
          target="_blank"
          className="no-underline hover:no-underline"
        >
          <p className=" Verify__para  body_text">
            See{" "}
            <span>
              {emailVerifyDetails?.acceptedDomainsTextlink
                ? emailVerifyDetails?.acceptedDomainsTextlink
                : "accepted email domains."}{" "}
            </span>
          </p>
        </a>
      </div>
      <form className="">
        <SiteInput
          className="mt-[22px]  md:mt-[27px] px-3  font-[400]"
          value={signupDetails?.secondaryEmail}
          placeholder="Work email"
          name="secondaryEmail"
          // onChange={(event) => setEmail(event.target.value)}
          onChange={(event) => {
            if (errorMessage != "") {
              setErrorMessage("");
            }
            dispatch(
              onSignUpChange({
                key: event.target.name,
                value: event.target.value,
              })
            );
          }}
          required
        />

        {errorMessage != "" && (
          <p className="mt-[22px]  md:mt-[27px] error_text">{errorMessage}</p>
        )}

        <SiteButton
          pill
          block
          className="mt-[22px]  md:mt-[27px] cursor-pointer "
          onClick={handleSubmit}
          disabled={!emailValid || isSigningUp}
        >
          {isSigningUp ? (
            <Spinner />
          ) : (
            <>
              {emailVerifyDetails?.sendCodeButtonText
                ? emailVerifyDetails.sendCodeButtonText
                : "Send code"}
            </>
          )}
        </SiteButton>
      </form>

      <div className="Verify__access">
        <p className="Verify__access__para terms_conditions">
          {emailVerifyDetails?.verifyWithIDText
            ? emailVerifyDetails.verifyWithIDText
            : "Don’t have access to a work email on an accepted domain? No problem, you can verify by uploading a valid form of ID."}{" "}
          See
          <a
            href={
              emailVerifyDetails?.acceptedFormsOfIDLink
                ? emailVerifyDetails.acceptedFormsOfIDLink
                : "https://www.wearekii.co.uk/verifyid"
            }
            target="_blank"
            className="no-underline hover:no-underline"
          >
            <span>
              {emailVerifyDetails?.acceptedFormsOfIDTextlink
                ? emailVerifyDetails.acceptedFormsOfIDTextlink
                : " accepted forms of ID"}
            </span>
            .
          </a>
        </p>
      </div>
      {/* these are the same classes use in details page and code page as well  */}

      <SiteButton
        pill
        block
        outline
        onClick={handleVerifyById}
        className="mt-[22px]  md:mt-[27px] cursor-pointer"
      >
        {emailVerifyDetails?.verifyWithIDButtonText
          ? emailVerifyDetails?.verifyWithIDButtonText
          : "Verify with id"}
      </SiteButton>
    </div>
  );
};

export default Verify;
