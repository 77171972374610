import { createSlice } from "@reduxjs/toolkit";
import firebase from "../../config/firebase";
import { v4 as uuidv4 } from "uuid";
import { RepositoryFactory } from "../../repository/RepositoryFactory";

let verification = RepositoryFactory.get("verification");

let usersCollection = firebase.firestore().collection("users");
let offersCollection = firebase.firestore().collection("offers");
let brandsCollection = firebase.firestore().collection("brands");
let codeCollection = firebase.firestore().collection("codes");
let codeIssuanceLogsCollection = firebase
  .firestore()
  .collection("codeIssuanceLogs");

export const getUserDetails = (id) => async (dispatch) => {
  dispatch(setGetUserDetailsLoader(true));
  firebase
    .firestore()
    .collection("users")
    .doc(id)
    .onSnapshot(async (query) => {
      if (query.data()) {
        let { referrerSource, codeId } = query.data();

        if (referrerSource && referrerSource != "" && codeId) {
          let codeDetails = await codeCollection.doc(codeId).get();

          if (codeDetails.data()) {
            let { offer, brandName } = codeDetails.data();

            let offerDetails = await offersCollection.doc(offer).get();

            let brandDetails = await brandsCollection.doc(brandName).get();

            //   let offer = referrerSource.split("=")[1];
            // let offerDetails = await offersCollection.doc(offer).get();
            // let { brandName } = offerDetails.data();

            // let brandDetails = await brandsCollection.doc(brandName).get();

            await dispatch(
              setUserDetails({
                id: query.id,
                ...query.data(),
                offerDetails: {
                  id: offerDetails.id,
                  ...offerDetails.data(),
                },
                brandDetails: {
                  id: brandDetails.id,
                  ...brandDetails.data(),
                },
                codeDetails: { id: codeId, ...codeDetails.data() },
              })
            );
            dispatch(setGetUserDetailsLoader(false));
          } else {
            await dispatch(
              setUserDetails({
                id: query.id,
                ...query.data(),
                offerDetails: {},
                brandDetails: {},
                codeDetails: {},
              })
            );
            dispatch(setGetUserDetailsLoader(false));
          }
        } else {
          await dispatch(
            setUserDetails({
              id: query.id,
              ...query.data(),
              offerDetails: {},
              brandDetails: {},
              codeDetails: {},
            })
          );
          dispatch(setGetUserDetailsLoader(false));
        }
      } else {
        dispatch(setUserDetails({}));

        dispatch(setGetUserDetailsLoader(false));
      }
    });
};

export const updateUserDetails =
  (id, payload, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(setUpdateLoader(true));
      let { data } = await verification.updateUser(payload);
      dispatch(setUpdateLoader(false));

      onSuccess();
    } catch (error) {
      dispatch(setUpdateLoader(false));
      if (error?.response?.data?.message) {
        onError(error?.response?.data?.message);
      } else {
        onError(error?.response?.data?.error);
      }
    }
  };

export const updateProfilePicture = (id, image) => async (dispatch) => {
  dispatch(setUpdateLoader(true));
  let userDetails = await usersCollection.doc(id).get();
  try {
    if (userDetails.data()?.image && userDetails.data()?.image != "") {
      await firebase.storage().refFromURL(userDetails.data()?.image).delete();
    }

    const fileName = image.name;
    const fileExtension = fileName.slice(fileName.lastIndexOf("."));
    const fileNameWithExtension = uuidv4() + fileExtension.toLowerCase();
    const storageRef = await firebase
      .storage()
      .ref("member images/" + fileNameWithExtension);

    const uploadTaskSnapshot = await storageRef.put(image);

    const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();

    usersCollection
      .doc(id)
      .update({ image: downloadURL })
      .then((res) => {
        dispatch(setUpdateLoader(false));
        dispatch(
          setUserDetails({ id, ...userDetails.data(), image: downloadURL })
        );
      })
      .catch((err) => {
        dispatch(setUpdateLoader(false));
      });
  } catch (error) {
    dispatch(setUpdateLoader(false));
  }
};

export const resetPassword =
  (email, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch) => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then((res) => {
        onSuccess("Password reset email sent successfully!");
      })
      .catch((err) => {
        onError(err.message);
      });
  };

export const updatePassword =
  (payload, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch, getState) => {
    let { user: member } = getState().auth;
    dispatch(setPasswordUpdateLoader(true));

    const user = await firebase.auth().currentUser;

    // Reauthenticate the user with their old password

    let email = member.primaryEmail;
    let oldPassword = payload.oldPassword;
    let newPassword = payload?.newPassword;

    const credentials = firebase.auth.EmailAuthProvider.credential(
      email,
      oldPassword
    );

    user
      .reauthenticateWithCredential(credentials)
      .then(() => {
        user
          .updatePassword(newPassword)
          .then(() => {
            dispatch(setPasswordUpdateLoader(false));
            onSuccess("Password updated successfully!");
          })
          .catch((error) => {
            dispatch(setPasswordUpdateLoader(false));

            onError(error?.message);
          });
      })
      .catch((error) => {
        dispatch(setPasswordUpdateLoader(false));
        onError(error?.message);
      });
  };

export const getNewCode =
  (payload, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch) => {
    dispatch(setGeneratingCodeLoader(true));

    const today = new Date(); // Get the current date
    today.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0

    const startOfToday = firebase.firestore.Timestamp.fromDate(today);

    let todayCodeIssue = await codeIssuanceLogsCollection
      .where("userId", "==", payload.userId)
      .where("brandId", "==", payload.brandId)
      // .where("offerId", "==", payload.offerId)
      .where("createdAt", ">=", startOfToday)
      .get();

    if (todayCodeIssue?.size < 5) {
      let unAssignedCodeDetails = await codeCollection
        .where("offer", "==", payload.offerId)
        .where("status", "==", "unassigned")
        .get();
      if (unAssignedCodeDetails.size > 0) {
        let codeId = unAssignedCodeDetails.docs[0].id;
        usersCollection
          .doc(payload?.userId)
          .update({
            codeId,
          })
          .then((res) => {
            codeIssuanceLogsCollection.add({
              ...payload,
              createdAt: firebase.firestore.Timestamp.now(),
              codeId,
            });
            codeCollection.doc(codeId).update({
              status: "assigned",
              assignedAt: firebase.firestore.Timestamp.now(),
            });
            dispatch(setGeneratingCodeLoader(false));
            onSuccess("Code generated successfully!");
          })
          .catch((err) => {
            dispatch(setGeneratingCodeLoader(false));
            onError(err.message);
          });
      } else {
        dispatch(setGeneratingCodeLoader(false));
        onError("Code Limit Exceeded");
      }
    } else {
      dispatch(setGeneratingCodeLoader(false));
      onError("Max code requests exceeded");
    }
  };

const userSlice = createSlice({
  name: "user",
  initialState: {
    userDetails: {},
    isGettingUserDetails: false,
    isUpdatingDetails: false,
    isUpdatingPassword: false,
    isGeneratingCode: false,
  },
  reducers: {
    setUserDetails(state, { type, payload }) {
      state.userDetails = payload;
    },
    setGetUserDetailsLoader(state, { type, payload }) {
      state.isGettingUserDetails = payload;
    },
    setUpdateLoader(state, { type, payload }) {
      state.isUpdatingDetails = payload;
    },
    setPasswordUpdateLoader(state, { type, payload }) {
      state.isUpdatingPassword = payload;
    },
    setGeneratingCodeLoader(state, { type, payload }) {
      state.isGeneratingCode = payload;
    },
  },
});
export const {
  setUserDetails,
  setGetUserDetailsLoader,
  setUpdateLoader,
  setPasswordUpdateLoader,
  setGeneratingCodeLoader,
} = userSlice.actions;
export default userSlice.reducer;
