//make sure to use onChange and onResetFunctions as a props
import React, { useEffect, useState } from "react";
import Uploadicon from "../../assets/img/uploadIcon.png";
import CameraIcon from "../../assets/img/cameraIcon.svg";
import { RxCrossCircled } from "react-icons/rx";
import "../../assets/sass/uploadFile.scss";
import { Progress } from "reactstrap";
import Delete from "../../assets/img/icons/Delete.png";
import { useSelector } from "react-redux";

const FileSelector = ({
  onChange,
  className,
  onReset,
  openCamera,
  takepicture,
  picture,
  setPicture,
  setErrorMessage,
  closeCamera,
  ...restProps
}) => {
  const [progress, setProgress] = useState(0);
  const [value, setValue] = useState(null);
  const { isVerifying } = useSelector((state) => state.app);

  useEffect(() => {
    picture !== null &&
      picture != "" &&
      setTimeout(() => {
        setProgress(100);
      }, 500);
  }, [takepicture]);

  const handleFileChange = (e) => {
    setErrorMessage("");
    const file = e.target.files[0];

    const fileName = file.name;
    const fileExtension = fileName.split(".").pop().toLowerCase();
    if (
      fileExtension == "jpeg" ||
      fileExtension == "png" ||
      fileExtension == "jpg"
    ) {
      setProgress(0);
      setPicture("");
      setValue(file);
      onChange(e.target.files[0] || null);
      setTimeout(() => {
        setProgress(100);
      }, 500);
    } else {
      setErrorMessage("Please select only supported file!");
      // setTimeout(() => {
      //   setErrorMessage("");
      // }, 3000);
    }
  };

  const handleFileLabelClick = () => {
    if (!isVerifying) {
      closeCamera();
      const fileInput = document.getElementById("photo-lib");
      fileInput.click();
    }
  };

  const cameraView = () => {
    setValue(null);
    const fileInput = document.getElementById("photo-lib");
    if (fileInput) {
      fileInput.value = ""; // Reset the input value to clear the file selection
    }
  };
  const resetFile = () => {
    if (!isVerifying) {
      setErrorMessage("");
      const fileInput = document.getElementById("photo-lib");
      if (fileInput) {
        fileInput.value = "";
      }
      setValue(null);
      setProgress(0);
      onReset();
    }
  };

  return (
    <div className={`cursor-pointer ${className}`}>
      <div className="file-selector mb-3">
        <div className="file-selector-box">
          <h3 className="file-selector-heading text-[16.0462px] mt-4 font-[600] ">
            Upload Work ID
          </h3>
          <p className="text-[12px]">
            Upload an image of your work ID. Please ensure your name is visible.
            {/* Upload an image from your device or camera. <br /> Supported
            formats: JPEG, PNG, PDF */}
          </p>
          <div className="flex w-full pb-0  mt-3 justify-center pt-3 gap-20 md:gap-16">
            <label
              // htmlFor="photo-lib"
              className="cursor-pointer"
              onClick={handleFileLabelClick}
            >
              <div className="flex flex-col items-center ">
                <img
                  src={Uploadicon}
                  alt="File"
                  htmlFor=""
                  className="file-selector-image mb-4 h-[50px] w-[60px]  md:h-[56px] md:w-[66px]"
                />
                <div className="text-[#676767] -mt-[11px] -ml-[10px] md:-ml-[7px]  md:-mt-[19px]  min-w-fit text-[11px] font-[400] ">
                  Upload Image
                </div>
              </div>
            </label>
            <div className="hidden">
              <input
                type="file"
                // accept="image/jpeg, image/jpg, image/png"
                accept=".jpeg, .jpg,  .png"
                onChange={handleFileChange}
                // value={value}
                {...restProps}
                id="photo-lib"
              />
            </div>

            {/* <div onClick={openCamera}>
              <div className="flex flex-col ">
                <img
                  onClick={cameraView}
                  src={CameraIcon}
                  alt="File"
                  className="file-selector-image mb-4  h-[50px] w-[60px]  md:h-[56px] md:w-[66px]"
                />

                <div className="text-[#676767] -ml-[0px] -mt-[11px] md:ml-[4px]  md:-mt-[19px]  text-[11px] font-[400] ">
                  Take Photo
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {value && (
        <div className="selected-file-details-container relative">
          <p className="number-of-files text-[11.4154px]">
            {progress !== 100 ? "Uploading - 1/1 files" : "Uploaded"}
          </p>
          <div
            className={
              progress !== 100
                ? "selected-file-details"
                : "selected-file-details-success"
            }
          >
            <p className="mb-0 text-[9.78462px] py-[5px]">
              {value?.name.length > 30
                ? `${value?.name.substring(0, 28)}...`
                : value?.name}
            </p>
            <div className="" onClick={resetFile}>
              {progress !== 100 ? (
                <RxCrossCircled color="grey" size={20} />
              ) : (
                // <RiDeleteBin6Line color="red" />
                <img
                  src={Delete}
                  className="h-10 w-10  sm:bottom-4 md:bottom-2 right-2 "
                />
              )}
            </div>
          </div>
          <Progress className="progress" value={progress} />
        </div>
      )}

      <div>
        {picture !== null && picture != "" && takepicture == false && (
          <div className="selected-file-details-container relative">
            <p className="number-of-files text-[11.4154px]">
              {progress !== 100 ? "Uploading - 1/1 files" : "Uploaded"}
            </p>
            <div
              className={
                progress !== 100
                  ? "selected-file-details"
                  : "selected-file-details-success"
              }
            >
              <p className="mb-0 text-[9.78462px] py-[5px]">
                {value?.name.length > 30
                  ? `${value?.name.substring(0, 28)}...`
                  : value?.name || "capture.png"}
              </p>
              <div className="" onClick={resetFile}>
                {progress !== 100 ? (
                  <RxCrossCircled color="grey" size={20} />
                ) : (
                  // <RiDeleteBin6Line color="red" />
                  <img
                    src={Delete}
                    className="h-10 w-10 absolute sm:bottom-4 md:bottom-2 right-2 "
                  />
                )}
              </div>
            </div>
            <Progress className="progress" value={progress} />
          </div>
        )}
      </div>
    </div>
  );
};

export default FileSelector;
