import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import "../../../assets/sass/authPages/selectAuth.scss";
import {
  brandNotFound,
  clearAuthState,
  getBrandDetails,
  getSingleOfferDetails,
  onSignUpChange,
  setBrandDetails,
  setOfferDetails,
} from "../../../store/main/authSlice";
import { getGlobalContent } from "../../../store/main/globalContentSlice";

import offerIcon from "../../../assets/img/auth-images/offerIcon.svg";
import checkOfferBrandValidity from "../../../global.js/checkOfferBrandValidity";
const SkeletonLoader = React.lazy(() =>
  import("../../../components/SkeletonLoader.js")
);

const SiteButton = React.lazy(() =>
  import("../../../components/custom/SiteButton")
);

const OfferTermsModal = React.lazy(() =>
  import("../../../components/Modals/OfferTermsModal")
);

const SelectAuth = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    offerDetails,
    brandDetails,
    isGettingBrandDetails,
    isGettingSingleBrand,
    isGettingSingleOffer,
  } = useSelector((state) => state.auth);

  const { brandName, offerID } = useParams();

  const { offers, defaultContent, isGettingGlobalContent } = useSelector(
    (state) => state.globalContent
  );
  const [errorMessage, setErrorMessage] = useState("");

  const [offerTermsModal, setOfferTermsModal] = useState(false);
  const offerTermsToggle = () => {
    setOfferTermsModal(!offerTermsModal);
  };

  useEffect(() => {
    document.body.style.padding = "0"; // Remove padding on this specific page

    // Clean up the styles when the component unmounts
    return () => {
      document.body.style.padding = ""; // Revert the padding to its original state
    };
  }, []);

  const handleLogin = () => {
    if (
      offerDetails &&
      Object.keys(offerDetails).length > 0 &&
      brandDetails &&
      Object.keys(brandDetails).length > 0
    ) {
      history.push(
        `/auth/login/${brandDetails?.slug}/offerID=${offerDetails?.id}`
      );
    } else {
      history.push(`/auth/login`);
    }
  };

  const handleSignup = () => {
    if (
      offerDetails &&
      Object.keys(offerDetails).length > 0 &&
      brandDetails &&
      Object.keys(brandDetails).length > 0
    ) {
      history.push(
        `/auth/signup/${brandDetails?.slug}/offerID=${offerDetails?.id}`
      );
    } else {
      history.push(`/auth/signup`);
    }
  };

  useEffect(() => {
    if (brandName && offerID) {
      // dispatch(
      //   getOfferDetails(
      //     brandName,
      //     offerID,
      //     history,
      //     () => {
      //       dispatch(
      //         onSignUpChange({
      //           key: "referrerSource",
      //           value: `/${brandName}/offerID=${offerID}`,
      //         })
      //       );
      //     },
      //     (message) => {
      //       setErrorMessage(message);
      //       dispatch(
      //         onSignUpChange({
      //           key: "referrerSource",
      //           value: "",
      //         })
      //       );
      //       setTimeout(() => {
      //         setErrorMessage("");
      //         history.push("/auth/login");
      //       }, 3000);
      //     }
      //   )
      // );

      dispatch(clearAuthState());
      setTimeout(() => {
        dispatch(getBrandDetails({ brandName }, history));

        dispatch(
          getSingleOfferDetails(
            offerID,
            history,
            () => {
              dispatch(
                onSignUpChange({
                  key: "referrerSource",
                  value: `/${brandName}/offerID=${offerID}`,
                })
              );
            },
            (message) => {
              setErrorMessage(message);
              dispatch(
                onSignUpChange({
                  key: "referrerSource",
                  value: "",
                })
              );
              setTimeout(() => {
                setErrorMessage("");
                history.push("/auth/login");
              }, 3000);
            }
          )
        );
      }, 500);
    } else {
      dispatch(
        onSignUpChange({
          key: "referrerSource",
          value: "",
        })
      );
      dispatch(setOfferDetails({}));
      dispatch(setBrandDetails({}));
    }
  }, [brandName, offerID]);

  useEffect(() => {
    if (Object.keys(offers).length == 0) {
      dispatch(getGlobalContent("offer"));
    }
  }, []);

  useEffect(() => {
    if (offerDetails && brandDetails && offerDetails?.id && brandDetails?.id) {
      checkOfferBrandValidity(
        brandDetails,
        offerDetails,
        () => {},
        (type) => {
          if (type == "invalidOffer") {
            dispatch(brandNotFound({}, {}, history, "/auth/invalid-offer"));
          } else {
            dispatch(brandNotFound({}, {}, history, "/auth/offer-expired"));
          }
        }
      );
    }
  }, [offerDetails, brandDetails]);

  return (
    <div className="select-auth">
      {isGettingSingleOffer || isGettingGlobalContent ? (
        <>
          <h2 className="mt-[16px] offer_title font-[700] text-center">
            <SkeletonLoader height="" />
          </h2>
          <SkeletonLoader />
          <SkeletonLoader rowCount={3} />
        </>
      ) : offerDetails && Object.keys(offerDetails).length > 0 ? (
        <>
          <h2 className="mt-[16px] offer_title font-[700] text-center">
            {offerDetails?.offerTitle ? offerDetails?.offerTitle : <></>}{" "}
          </h2>
          <div className="offer-icon mt-3">
            {offerDetails?.limitedTime && (
              <img className="w-[19px] h-[16px]" src={offerIcon} alt="" />
            )}

            <p className="text-center terms_conditions">
              {offerDetails?.limitedTime && (
                <span className="text-primary font-[600]">
                  {offerDetails?.limitedTimeText}
                </span>
              )}{" "}
              {offerDetails?.endDate && (
                <>
                  Offer ends{" "}
                  {moment(offerDetails?.endDate).format("DD/MM/YYYY")}
                </>
              )}
            </p>
          </div>
          <p className="text-center offer_description mt-[22px] lg:px-10 sm:px-0  leading-[26px] font-[400] lg:mb-[27px] mb-[22px]">
            {offerDetails?.offerDescription ? (
              offerDetails?.offerDescription
            ) : (
              <>
                For instant access to this discount simply register and verify
                your Key Worker status with kii.{" "}
                <span className="fw--700">It’s free!</span>
              </>
            )}
          </p>
        </>
      ) : (
        <>
          <h2 className="mt-[16px] offer_title font-[700] text-center">
            {defaultContent?.defaultOfferTitle
              ? defaultContent.defaultOfferTitle
              : "Exclusive Offers forKey Workers"}
          </h2>

          <div className="offer-icon mt-3">
            <img className="w-[19px] h-[16px]" src={offerIcon} alt="" />
            <p className="text-center terms_conditions">
              <span className="text-primary font-[600]">
                {defaultContent?.limitedText1
                  ? defaultContent?.limitedText1
                  : "Completely free..."}
              </span>{" "}
              {defaultContent?.limitedText2
                ? defaultContent?.limitedText2
                : "and forever will be"}
            </p>
          </div>

          <p className="text-center offer_description lg:px-10 sm:px-0 mt-[22px]  leading-[26px] font-[400] lg:mb-[27px] mb-[22px]">
            {defaultContent?.defaultOfferDescription ? (
              defaultContent?.defaultOfferDescription
            ) : (
              <>
                Register for your <span className="fw--700">free</span> kii ID
                and unlock hassle free key worker discounts from top UK brands.
              </>
            )}
          </p>
        </>
      )}

      {errorMessage != "" && (
        <p className="error_text lg:mb-[27px] mb-[22px]">{errorMessage}</p>
      )}

      {isGettingGlobalContent || isGettingSingleBrand ? (
        <SkeletonLoader />
      ) : (
        <SiteButton
          onClick={handleLogin}
          block
          pill
          className="lg:mb-[27px] mb-[22px]"
          brandButton={true}
        >
          {offers?.loginButtonText ? offers?.loginButtonText : "Login"}
        </SiteButton>
      )}
      {isGettingGlobalContent || isGettingSingleBrand ? (
        <SkeletonLoader />
      ) : (
        <SiteButton
          onClick={handleSignup}
          block
          pill
          outline
          className="lg:mb-[27px] mb-[22px]"
          brandButton={true}
        >
          {offers?.joinNowButtonText ? offers?.joinNowButtonText : "Join now"}
        </SiteButton>
      )}

      {isGettingSingleOffer && <SkeletonLoader height="" />}

      {!isGettingSingleOffer &&
        offerDetails?.id &&
        offerDetails?.offerTerms && (
          <p className="m-0 text-center">
            <a
              // href={offerDetails?.offerLink}
              onClick={offerTermsToggle}
              target="_blank"
              className="text-primary underline fw--500 cursor-pointer "
            >
              {offers?.offerTermsTextlink
                ? offers?.offerTermsTextlink
                : "Offer Terms"}
            </a>
          </p>
        )}

      <OfferTermsModal
        toggle={offerTermsToggle}
        isOpen={offerTermsModal}
        offerTerms={offerDetails?.offerTerms}
      />
    </div>
  );
};

export default SelectAuth;
